import { ICalibration } from "../../../../@types/ICalibration"
import { CalibrationAreas } from "../@types"

const DEFAULT_AREA = 100 //In metres squared
export const getCalibrationAreas = (calibrations: ICalibration[]) => {
  const areas: CalibrationAreas = {}
  calibrations.map(c => {
    try {
      const json = JSON.parse(c.metaDensity)
      const area = json.area || DEFAULT_AREA
      areas[c.calibrationId] = area
    } catch (error) {
      areas[c.calibrationId] = DEFAULT_AREA
    }
  })
  return areas
}
