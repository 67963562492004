import moment from "moment-timezone";
import { Schedule, Recurrence } from "../@types/CameraSchedule";

export const formatDayMonth = (d: Date) => {
    return d.toDateString();
}
export const calcDaysDifference = (d1: Date, d2: Date) => {
    return (d2.getTime() - d1.getTime()) / (1000 * 3600 * 24)
}
export const toDate = (datePart: string, timePart: string) => {
    // Split into numeric values
    const dateParts = datePart.split('-').map(s => Number(s));
    const timeParts = timePart.split(':').map(s => Number(s));
    return new Date(Date.UTC(dateParts[0], dateParts[1]-1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]));
}
export const toDateByDate = (date: Date, time: Date) => {
    let dateParts = getLocaleDateString(date).split('/');
    let timeParts = getLocaleTimeString(time).split(':');
    return new Date(+dateParts[2], +dateParts[1]-1, +dateParts[0], +timeParts[0], +timeParts[1]);
}
export const getDatesBetween = (startDate: Date, endDate: Date) => {
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}
export const getDatesBetweenRecurring = (startDate: Date, endDate: Date, type: string, frequency: string) => {
    if (type === 'WEEKLY') {
        const dateDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
        const days = frequency.split('-');
        const currentDate = new Date(startDate.getTime());
        const dates = [];
        while (currentDate <= endDate) {
            if (days.includes(dateDays[currentDate.getDay()])) dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }
}
export const getStartOfDay = (date: Date) => {
    let d = new Date(date.getTime());
    d.setHours(0, 0, 0, 0)
    return d;
}
export const getDateString = (date: Date) => {
    const splitString = date.toISOString().split("T");
    return splitString[0];
}
export const getTimeString = (date: Date) => {
    const splitT = date.toISOString().split("T");
    const splitPeriod = splitT[1].split(".");
    return splitPeriod[0];
}
export const getLocaleDateString = (date: Date) => {
    const splitString = date.toLocaleString('en-GB').split(', ');
    return splitString[0];
}
export const getLocaleTimeString = (date: Date) => {
    const splitString = date.toLocaleString('en-GB').split(', ');
    const splitPeriod = splitString[1].split(':');
    return `${splitPeriod[0]}:${splitPeriod[1]}`;
}

export const ENUM_DAYS = {
    'SUN': 0,
    'MON': 1,
    'TUE': 2,
    'WED': 3,
    'THU': 4,
    'FRI': 5,
    'SAT': 6,
}
export const getDayByValue = (value: number) => {
    return Object.keys(ENUM_DAYS).find(day => ENUM_DAYS[day] === value);
}
export const getOffsetDay = (day: string, offset: number, toLocale: boolean) => {
    const dayV = ENUM_DAYS[day];
    let offsetDay = toLocale ? dayV-offset : dayV+offset;
    // 0 (SUN) -1 = -1 (SAT)
    if (offsetDay === -1) return getDayByValue(6);
    // 6 (SAT) +1 = 7 (SUN)
    if (offsetDay === 7) return getDayByValue(0);
    return getDayByValue(offsetDay);
}
export const getUTCDayOffset = (datePart: string, timePart: string) => {
    const date = toDate(datePart, timePart);
    let offset = date.getUTCDay()-date.getDay();
    // Locale tz is before UTC
    // If offset is 6, then yesterday is saturday (6) and today is sunday (0) therefore set to -1 (yesterday)
    if (offset === 6) offset = -1;
    // Locale tz is after UTC
    // If offset is -6, then tomorrow is sunday (0) and today is saturday (6) therefore set to 1 (tomorrow)
    if (offset === -6) offset = 1;
    return offset;
}
export const frequencyStringToMap = (frequency: string) => {
    let options = {};
    let days = frequency.split('-');
    days.forEach(d => options[d] = true);
    return options;
}
export const getFrequencyString = (checkboxIdToSelectedMap: { [k: string]: boolean }) => {
    let options = [];
    if (checkboxIdToSelectedMap['MON']) options.push('MON');
    if (checkboxIdToSelectedMap['TUE']) options.push('TUE');
    if (checkboxIdToSelectedMap['WED']) options.push('WED');
    if (checkboxIdToSelectedMap['THU']) options.push('THU');
    if (checkboxIdToSelectedMap['FRI']) options.push('FRI');
    if (checkboxIdToSelectedMap['SAT']) options.push('SAT');
    if (checkboxIdToSelectedMap['SUN']) options.push('SUN');
    return options.join('-');
}
export const getFrequencyStringWithDayOffset = (frequency: string, dayOffset: number, toLocale: boolean) => {
    return frequency
        .split('-')
        .map(d => getOffsetDay(d, dayOffset, toLocale))
        .join('-');
}
export const getTzDstOffset = (date: string, timezone: string) => {
    if (!date || !timezone) return 0;
    const d1Offset = -(moment.tz(date.split('.')[0], timezone).utcOffset());
    const d2Offset = (new Date()).getTimezoneOffset();
    return (d2Offset-d1Offset)*60*1000; // convert minutes to milliseconds
}
export const calcScheduleDates = (schedules: { [id: string]: Schedule }, recurrences: { [id: string]: Recurrence }) => {
    const getRecurrence = (scheduleId: string) => {
        let scheduleRecurrences = Object.values(recurrences).filter(r => r.cs_id === scheduleId);
        if (scheduleRecurrences.length > 0) return scheduleRecurrences[0];
        return null;
    }
    const datesObj = {};
    Object.values(schedules).forEach(schedule => {
      const recurrence = getRecurrence(schedule.id);
      const dstOffsetMs = getTzDstOffset(schedule.configured_date, schedule.configured_timezone);
      if (!recurrence || !recurrence.frequency) {
        const start = new Date(toDate(schedule.start_date, schedule.start_time).getTime()-dstOffsetMs);
        const end = new Date(toDate(schedule.end_date, schedule.end_time).getTime()-dstOffsetMs);
        const datesBetween = getDatesBetween(start, end);
        datesObj[schedule.id] = datesBetween.map(d => moment(d).startOf('day').toISOString());
      } else {
        const dayOffset = getUTCDayOffset(schedule.start_date, schedule.start_time);
        const start = new Date(toDate(schedule.start_date, schedule.start_time).getTime()-dstOffsetMs);
        const end = new Date(toDate(schedule.end_date, schedule.start_time).getTime()-dstOffsetMs);
        const datesBetween = getDatesBetweenRecurring(
          start,
          end,
          recurrence.type,
          getFrequencyStringWithDayOffset(recurrence.frequency, dayOffset, true)
        );
        datesObj[schedule.id] = datesBetween.map(d => moment(d).startOf('day').toISOString());
      }
    })
    return datesObj;
}