import { ISite } from "../../../@types/ISite"
import { IZone } from "../../../@types/IZone"
import { IDeployment } from "../../../@types/IDeployment"
import { sortAlphaNum } from "../../../utils/sortAlphaNum"

// export function sortBySiteName(a: ISite, b: ISite) {
//   return sortAlphaNum(a.siteName, b.siteName)
// }
// export function sortByZoneName(a: IZone, b: IZone) {
//   return sortAlphaNum(a.zoneName, b.zoneName)
// }
// export function sortByDeploymentName(a: IDeployment, b: IDeployment) {
//   return sortAlphaNum(a.deploymentName, b.deploymentName)
// }

export function sortBySiteName(a: ISite, b: ISite) {
  return sortAlphabetically(a.siteName, b.siteName)
}
export function sortByZoneName(a: IZone, b: IZone) {
  return sortAlphabetically(a.zoneName, b.zoneName)
}
export function sortByDeploymentName(a: IDeployment, b: IDeployment) {
  return sortAlphabetically(a.deploymentName, b.deploymentName)
}

export function sortAlphabetically(a: string, b: string) {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}
