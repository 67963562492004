import axios from "axios";

export const vmsAPI = {
    getDevices: async (
        token: string,
        apiGatewayUrl: string,
        projectVmsMapId: string,
    ) => {
        const res = await axios.get(
            `${apiGatewayUrl}/vms/devices/${projectVmsMapId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }
        );
        if (!res.data) return {};
        return res.data;
    },
    getMappings: async (
        token: string,
        apiGatewayUrl: string,
        projectId: string,
    ) => {
        const res = await axios.get(
            `${apiGatewayUrl}/vms/mappings/${projectId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }
        );
        if (!res.data) return {};
        return res.data;
    },
    createMapping: async (
        token: string,
        apiGatewayUrl: string,
        vmsMapping: {
            project_id: string,
            vms_name: string,
            vms_type: string,
            vms_api_url: string,
            vms_api_username: string,
            vms_api_password: string,
            is_enabled?: boolean,
            is_deleted?: boolean,
        },
    ) => {
        const res = await axios.post(
            `${apiGatewayUrl}/vms/mappings/`,
            vmsMapping,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }
        );
        if (!res.data) return {};
        return res.data;
    },
    createEvent: async (
        token: string,
        notificationsUrl: string,
        eventParams: {
            projectVmsMapId: number,
            timestamp?: string,
            caption?: string,
            description?: string
        }
    ) => {
        const res = await axios.post(
            `${notificationsUrl}/vms/event/`,
            eventParams,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            }
        );
        if (!res.data) return {};
        return res.data;
    }
};