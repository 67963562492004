import { produce, applyPatches } from "immer"
import { createAction, getType } from "typesafe-actions"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export interface ConstantsState {
  reportsUrl: string
  reportsApiGatewayUrl: string
  notificationsUrl: string
  apiGatewayUrl: string
  apiGatewayAud: string
  mgmtEnv: "dev" | "test" | "prod"
  origin: string
  oauthIssuer: string
  oauthClientId: string
  oauthNamespace: string
  vpnSetupPath: string
  cameraSetupPath: string
  vpcSource: string
  region?: string

  //For undo-redo
  canUndo: boolean
  canRedo: boolean
}

export const initialConstantsState: ConstantsState = {
  reportsUrl: process.env.reportsUrl,
  reportsApiGatewayUrl: process.env.reportsApiGatewayUrl,
  notificationsUrl: process.env.notificationsUrl,
  apiGatewayUrl: process.env.apiGatewayUrl,
  apiGatewayAud: process.env.apiGatewayAud,
  mgmtEnv: process.env.mgmtEnv as "dev" | "test" | "prod",
  origin: process.env.origin,
  oauthIssuer: process.env.oauthIssuer,
  oauthClientId: process.env.oauthClientId,
  oauthNamespace: process.env.oauthNamespace,
  vpnSetupPath: process.env.vpnSetupPath,
  cameraSetupPath: process.env.cameraSetupPath,
  vpcSource: process.env.vpcSource,

  //For undo-redo
  canUndo: false,
  canRedo: false,
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const constantsActions = {
  set: createAction(
    "@constants/set",
    (constants: ConstantsState) => constants // payload creator
    //   (id: number, token: string) => token // meta creator
  )(),
  undo: createAction("@constants/undo")(),
  redo: createAction("@constants/redo")(),
}
type ValueOf<T> = T[keyof T]
export type ConstantsAction = ReturnType<ValueOf<typeof constantsActions>>

// export const setConstants = createAction(
//   "@constants/set",
//   (constants: ConstantsState) => constants // payload creator
//   //   (id: number, token: string) => token // meta creator
// )()

// export const undoChangeConstants = createAction("@constants/undo")()
// export const redoChangeConstants = createAction("@constants/redo")()

// export type ConstantsAction =
// | ReturnType<typeof setConstants>
// | ReturnType<typeof undoChangeConstants>
// | ReturnType<typeof redoChangeConstants>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */

// export const constantsReducer = produce(
//   (draft: Draft<ConstantsState>, action: ConstantsAction) => {
//     switch (action.type) {
//       case getType(setConstants):
//         draft = {
//           ...draft,
//           ...((typeof action.payload === "object" && action.payload) || {}),
//         }
//         console.log("SET CONSTANTS:", draft)
//         return
//     }
//   },
//   initialConstantsState
// )

//Undo/redo tracker
const noOfVersionsSupported = 10
const changes = {}
let currentVersion = -1
const undoableActions: string[] = [getType(constantsActions.set)]

export const constantsReducer = (
  state = initialConstantsState,
  action: ConstantsAction
) => {
  return produce(
    state,
    draft => {
      //To toggle undo-redo flags
      if (undoableActions.indexOf(action.type) !== -1) {
        draft.canUndo = true
        draft.canRedo = false
      }

      //Handling actions
      switch (action.type) {
        case getType(constantsActions.set):
          {
            if (typeof action.payload !== "object") return
            //Add/ or edit keys to the draft object
            Object.keys(action.payload).forEach(key => {
              draft[key] = action.payload[key]
            })
          }
          return
        case getType(constantsActions.undo):
          return produce(
            applyPatches(state, changes[currentVersion--].undo),
            (newDraft: ConstantsState) => {
              newDraft.canUndo = changes.hasOwnProperty(currentVersion)
              newDraft.canRedo = true
            }
          )
        case getType(constantsActions.redo):
          return produce(
            applyPatches(state, changes[++currentVersion].redo),
            (newDraft: ConstantsState) => {
              newDraft.canUndo = true
              newDraft.canRedo = changes.hasOwnProperty(currentVersion + 1)
            }
          )
      }
    },
    //Handle patches
    (patches, inversePatches) => {
      if (!patches.length && !inversePatches.length) return
      if (undoableActions.indexOf(action.type) === -1) return

      currentVersion++

      changes[currentVersion] = {
        redo: patches,
        undo: inversePatches,
      }

      //Handle storage limits
      delete changes[currentVersion + 1]
      delete changes[currentVersion - noOfVersionsSupported]

      console.log("changes", changes)
    }
  )
}

/**
 * ==============================================================
 * EPICS - move this elsewhere
 * ==============================================================
 */

/**
 * ==============================================================
 * SELECTORS - maybe move this elsewhere too
 * ==============================================================
 */
