import queue from "queue";


export type Job = {
    id: string
    fn: () => Promise<any>
    errFn?: (error: any) => Promise<void>
}

const JobQueue = (
    options: {
        concurrency: number,
        end?: any, // Callback function when all jobs complete
    }
) => {
    // Main Queue
    let q = new queue({
        concurrency: options.concurrency,
        // autostart: true,
        autostart: false,
        results: [],
    });
    // Queue for priority jobs
    let priorityQ = new queue({
        concurrency: options.concurrency,
        // autostart: true,
        autostart: false,
        results: [],
    });
    // Keeps track of jobs in progress
    let queueJobs: { [id: string]: any } = {};

    // Initialise
    // Mark jobs as cancelled if no longer in queueJobs
    // q.on("start", (job) => {
    //     // If id not found in set, the job has been cancelled
    //     if (!queueJobs[job.id]) job.cancelled = true;
    // });
    q.addEventListener("start", (e) => { // If id not found in set, the job has been cancelled
        if (!queueJobs[e.detail.job['id']]) e.detail.job['cancelled'] = true;
    });
    // When all jobs in priorityQ complete, stop it and start the main queue again
    // Adds end cellback when all jobs complete if provided
    // if (options?.end) q.on("end", options.end);
    if (options?.end) q.addEventListener("end", options.end);

    // TEST
    // q.on("success", (job) => {
    //     console.log(`MAIN QUEUE: ${job.id}`);
    // });
    // priorityQ.on("success", (job) => {
    //     console.log(`PRIORITY QUEUE: ${job.id}`);
    // });

    // FUNCTIONS

    /**
     * Adds a job to the queue
     * 
     * @param job Callback function to run
     * @param priority Flag to add job to priority queue
     */
    const addJob = (job: Job, priority?: boolean) => {
        if (queueJobs[job.id]) return; // Don't add if already in queue
        const queueJob = createQueueJob(job.id, job.fn, job.errFn);
        queueJobs[job.id] = queueJob;
        q.push(queueJob);
        if (priority) priorityQ.push(queueJob);
        // console.log("job added: ", job.id, priority ? "to priority" : "");
    }

    const createQueueJob = (id: string, fn: any, errFn: any) => {
        const queueJob = async () => {
            if (queueJob.cancelled) {
                // console.log("cancelled: ", id);
                return {result: "cancelled", id: id };
            }
            try {
                await fn();
                // console.log("job complete: ", id);
                delete queueJobs[id];
            } catch (error) {
                delete queueJobs[id];
                await errFn(error);
            }
            return { result: "success", id: id };
        }
        queueJob.cancelled = false;
        queueJob.id = id;

        return queueJob;
    }

    /**
     * Cancels jobs with ids that contain specified string
     * 
     * @param s 
     */
    const cancelJobsByZoneId = (s: string) => {
        // console.log("removing jobs", s);
        const idKeys = Object.keys(queueJobs);
        for (let i = idKeys.length-1; i >= 0; i--) {
            if (idKeys[i].includes(s)) {
                delete queueJobs[idKeys[i]];
            }
        }
    }

    /**
     * Prioritises jobs with ids that contain specified string
     * 
     * @param string
     */
    const prioritiseJobsByZoneId = (s: string) => {
        if (!s) return;
        // console.log("prioritising jobs", s);
        priorityQ.splice(0, priorityQ.length); // Clear current priorityQ

        // Add jobs with id containing s to priority queue
        Object.keys(queueJobs).forEach(id => {
            if (!id.includes(s)) return;
            priorityQ.push(queueJobs[id]);
        });
        startQueue();
    }

    /**
     * Removes all jobs from queues
     */
    const clear = () => {
        q.splice(0, q.length);
        priorityQ.splice(0, priorityQ.length);
        queueJobs = {};
    }

    /**
     * Removes all jobs from priority queue
     */
    const clearPriority = () => {
        priorityQ.splice(0, priorityQ.length);
    }

    /**
     * Starts queues
     * Starts priority queue first and starts main queue after all priority jobs complete
     */
    const startQueue = () => {
        // console.log("queue lengths", priorityQ.length, q.length);
        // Stop both queues to ensure that only one queue will be running
        priorityQ.stop();
        q.stop();
        if (priorityQ.length > 0) {
            // Start priority queue first, start main queue after
            priorityQ.start(() => {
                try {
                    q.start()
                } catch (e) {
                    console.log(e);
                }
            });
        } else {
            q.start();
        }
    }

    return {
        addJob,
        cancelJobsByZoneId,
        prioritiseJobsByZoneId,
        startQueue,
        clear,
        clearPriority,
    };
}

export default JobQueue;