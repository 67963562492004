import { IState, RootAction } from "../reducers"
import axios from "axios"
import { Epic } from "redux-observable"
import { switchMap, filter } from "rxjs/operators"
import { Observable } from "rxjs"
import { Dependencies } from "../ReduxProvider"
import { isActionOf, PayloadActionCreator } from "typesafe-actions"

export type ModelType =
  | "sites"
  | "zones"
  | "deployments"
  | "devices"
  | "calibrations"
  | "projects"
export const update = (apiGatewayUrl: string) => (
  modelType: ModelType
) => async (state: IState, action: any) => {
  const model = state.tenant[modelType]
  const client = state.auth0.auth0Client
  const token = client ? await client.getTokenSilently() : ""

  //Extract from action
  const id = action.payload.id
  const body = action.payload.body

  //Fetch API
  const res = await axios.put(
    `${apiGatewayUrl}/${modelType}/${id}`,
    {
      ...body,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )

  //Handle return
  const resObj = res.data
  if (resObj && Object.keys(resObj).length > 0) {
    return resObj
  } else {
    throw new Error(`${model} recieved is empty`)
  }
}

export const createUpdateEpic = (
  asyncAction: {
    request: PayloadActionCreator<any, { id: string; body: any }>
    success: PayloadActionCreator<any, any>
    failure: PayloadActionCreator<any, any>
  },
  model: ModelType
): Epic<RootAction, RootAction, IState, Dependencies> => (
  action$,
  state$,
  dependencies
) => {
  return action$.pipe(
    filter(isActionOf(asyncAction.request)),
    switchMap((action: RootAction) => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const apiGatewayUrl = state.constants.apiGatewayUrl
        update(apiGatewayUrl)(model)(state, action)
          .then(resObj =>
            observer.next(
              asyncAction.success({
                resObj,
              })
            )
          )
          .catch(error => observer.next(asyncAction.failure({ error })))
      })
    })
  )
}
