import axios from "axios"
// import { Epic, ofType } from "redux-observable"
// import { switchMap } from "rxjs/operators"
// import { Observable } from "rxjs"
import { IState } from "../reducers"
import { IProjectData } from "../../@types/IProjectData"

export type ModelType = keyof IProjectData | "projects"
export const deleteModel = (apiGatewayUrl: string) => (
  modelType: ModelType
) => async (state: IState, action: any) => {
  const model = state.tenant[modelType]
  const client = state.auth0.auth0Client
  const token = client ? await client.getTokenSilently() : ""

  //Extract from action
  const id = action.payload.id

  //Fetch API
  const res = await axios.delete(`${apiGatewayUrl}/${modelType}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })

  //Handle return
  // if (res && res.data > 0) {
  //   //Check if any rows deleted
  //   return id
  // } else {
  //   throw new Error(`${modelType} could not delete`)
  // }
  if (res?.data) {
    //Check if any rows deleted
    return id
  } else {
    throw new Error(`${modelType} could not delete`)
  }
}

// export const deleteEpic = (model: ModelType): Epic => (
//   action$,
//   state$,
//   dependencies
// ) => {
//   return action$.pipe(
//     ofType(`${model}_delete`),
//     switchMap(action => {
//       return new Observable(observer => {
//         const state = state$.value as IState
//         const apiGatewayUrl = state.constants.apiGatewayUrl
//         deleteModel(apiGatewayUrl)(model)(state, action)
//           .then(id =>
//             observer.next({
//               type: `${model}_delete_res`,
//               payload: { id },
//             })
//           )
//           .catch(error =>
//             observer.next({ type: `${model}_delete_err`, payload: { error } })
//           )
//       })
//     })
//   )
// }
