import {
  CalibrationValues,
  MeasurementsV2,
  MetricValues,
  PointerDates,
  Timestamps,
} from "../@types"

const DEFAULT_SINCE = -1000*60*5 // 5 minutes
const pickLatestDate = (input: Timestamps<any>) => {
  const date =
    Object.keys(input)
      //   .sort() //ASSUMED SORTED
      .pop() || null
  return date ? new Date(date) : new Date(new Date().getTime()+DEFAULT_SINCE)
}

/**
 * NOTE: must be used with sorted timestamps
 */
export const extractPointerDates = (
  sortedData: MeasurementsV2
): PointerDates => {
  //1.Data
  const data: CalibrationValues<MetricValues<Date>> = {}
  const dataValues = sortedData.data.calibrations
  Object.keys(dataValues).forEach(calibrationId => {
    const metricValues = dataValues[calibrationId]
    const newMetricValues: Partial<MetricValues<Date>> = {}
    Object.keys(metricValues).forEach(m => {
      const metric = m as keyof MetricValues<any>
      newMetricValues[metric] = pickLatestDate(metricValues[metric])
    })
    data[calibrationId] = newMetricValues as MetricValues<Date>
  })

  //2.Heatmaps
  const heatmaps: CalibrationValues<MetricValues<Date>> = {}
  const heatmapValues = sortedData.heatmapRisks.calibrations
  Object.keys(heatmapValues).forEach(calibrationId => {
    const metricValues = heatmapValues[calibrationId]
    const newMetricValues: Partial<MetricValues<Date>> = {}
    Object.keys(metricValues).forEach(m => {
      const metric = m as keyof MetricValues<any>
      newMetricValues[metric] = pickLatestDate(metricValues[metric])
    })
    heatmaps[calibrationId] = newMetricValues as MetricValues<Date>
  })

  //3. Images
  const images: CalibrationValues<Date> = {}
  const imageValues = sortedData.images.calibrations
  Object.keys(imageValues).forEach(calibrationId => {
    const values = imageValues[calibrationId]
    images[calibrationId] = pickLatestDate(values)
  })

  const compound: CalibrationValues<Date> = {}
  const compoundValues = sortedData.data.compound
  Object.keys(compoundValues).forEach(compoundId => {
    const values = compoundValues[compoundId]
    compound[compoundId] = pickLatestDate(values)
  })

  //3.Images
  return {
    data,
    heatmaps,
    images,
    compound,
  }
}
