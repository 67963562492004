import { produce } from "immer"
import { createSelector } from "reselect"
import { ActionCreator, getType } from "typesafe-actions"
import { IState, RootAction } from "."
import { appActionsAsync } from "./appReducer"
import { auth0ActionsAsync } from "./auth0Reducer"
import { dataV2ActionsAsync } from "./dataReducerV2"
import { networkSetupActionsAsync } from "./networkSetupReducerV2"
import { notificationActionsAsync } from "./notifications"
import { projectActionsAsync } from "./projectReducer"
import { reportActionsAsync } from "./reportsReducer"
import { tenantActionsAsync } from "./tenantReducer"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export interface FetchingState {
  fetching: {
    [key: string]: boolean
  }
}

export const initialFetchingState: FetchingState = {
  fetching: {},
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
//Own actions
export const fetchingActions = {}
type ValueOf<T> = T[keyof T]
export type FetchingAction = ReturnType<ValueOf<typeof fetchingActions>>

/**
 * Register all async actions here,
 * This will be used to generate automatically its:
 * - fetching state
 * - messages state
 */
const asyncActions = [
  ...Object.values(networkSetupActionsAsync),
  ...Object.values(auth0ActionsAsync),
  ...Object.values(dataV2ActionsAsync),
  ...Object.values(projectActionsAsync),
  ...Object.values(tenantActionsAsync),
  ...Object.values(appActionsAsync),
  ...Object.values(reportActionsAsync),
  ...Object.values(notificationActionsAsync),
]

const asyncActionTypes: {
  [type: string]: "request" | "success" | "failure"
} = {}
asyncActions
  .map(obj => obj.request)
  .map(action => {
    const type = getType(action)
    asyncActionTypes[type] = "request"
  })
asyncActions
  .map(obj => obj.success)
  .map(action => {
    const type = getType(action)
    asyncActionTypes[type] = "success"
  })
asyncActions
  .map(obj => obj.failure)
  .map(action => {
    const type = getType(action)
    asyncActionTypes[type] = "failure"
  })

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */

/**
 * This reducer will check against every async action,
 * and give it a fetching state where it can be accessed in state
 */
export const fetchingReducer = (
  state = initialFetchingState,
  action: RootAction
) => {
  return produce(state, draft => {
    //Handling actions
    const type = asyncActionTypes[action.type]
    if (!type) return
    if (type === "request") {
      draft.fetching[action.type.replace("/req", "")] = true
    }
    if (type === "success") {
      delete draft.fetching[action.type.replace("/res", "")]
    }
    if (type === "failure") {
      delete draft.fetching[action.type.replace("/err", "")]
    }
  })
}

/**
 * ==============================================================
 * EPICS - move this elsewhere
 * ==============================================================
 */

/**
 * ==============================================================
 * SELECTORS
 * ==============================================================
 */

/**
 * Helper for determing if action is fetching
 */
export const requestFetchingSelector = (action: ActionCreator<string>) =>
  createSelector(
    (state: IState) => state.fetching.fetching,
    fetching => {
      return fetching[getType(action).replace(/(\/req)|(\/res)|(\/err)/g, "")]
    }
  )
