import { IUser } from "../../../@types/IUser"

//NOTE: For auth0 to include user_metadata fields into the id_token,
// you need to include the following code as an auth0 rule:
//
// function (user, context, callback) {
//   var namespace = 'https://dev.dcm.crowdeagle.com.au/'; // note that you cannot use auth0.com, webtask.io and webtask.run as a namespace identifier
//   if (context.idToken && user.user_metadata) {
//      context.idToken[namespace + 'user_metadata'] = user.user_metadata;
//    }
//  callback(null, user, context);
// }

export const unscope = (oauthNamespace: string) => (user: IUser): IUser => {
  if (!user) return undefined
  const newUser: Partial<IUser> = {}

  Object.keys(user).forEach(key => {
    const value = user[key]
    const newKey = key.replace(oauthNamespace, "")
    newUser[newKey] = value
  })

  return newUser as IUser
}
