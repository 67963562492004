/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import ReduxProvider from "./src/redux/ReduxProvider"

// You can delete this file if you're not using it

export const wrapRootElement = ReduxProvider

export const onClientEntry = () => {
  console.log("We've started!")
}

export const onServiceWorkerUpdateReady = () => {
  //Note: dont think this works: makes service worker redundant
  //   const answer = window.confirm(
  //     `This application has been updated. ` +
  //       `Reload to display the latest version?`
  //   )
  //   if (answer === true) {
  //     window.location.reload()
  //   }
}
