import React from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import styled from "@emotion/styled"



export const getToastByErrCode = (id: string, err_code: string): {
    id: string,
    title: React.ReactNode
    color: any
    iconType?: any,
    toastLifeTimeMs?: number,
    text: React.ReactChild
} => {
    switch (err_code) {
        /**
         * API calibration/camera_driver_deployment
         */
        case "CS101": //atleast1measurement-at least one of the following should be true: flag density, flow mood
            return {
                id,
                title: "System validation error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p> System error. Try again later or contact DCM for help.</p>
                    <p>Error code CS101</p>
                </div>
            }
        case "CS102": //flagdisplayfrontend-only one True for image upload flag is allowed
            return {
                id,
                title: "System validation error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p> System error. Try again later or contact DCM for help.</p>
                    <p>Error code CS102</p>
                </div>
            }
        /**
         * API calibration/density_calibration
         */
        case "CS201": //missingfloorplan-try reupload floorplan
            return {
                id,
                title: "Floorplan not found",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Try to upload the floorplan again.</p>
                    <p>Error code CS201</p>
                </div>

            }
        case "CS202": //missingdensityview-try reupload densityview
            return {
                id,
                title: "Device view not found",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Try to upload the device view again.</p>
                    <p>Error code CS202</p>
                </div>
            }
        case "CS203": //areatoolarge-area (%d) too large, try 1. mask off more area on iamge and horizon; 2. use lower tilt angle/larger zoom; 3. try more accurate correspondences
            return {
                id,
                title: "Area captured scale too large",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Try:
                    <ul>
                        <li>Revert calibrate device location and reduce the scale setting</li>
                        <li>Masking less area in device view</li>
                        <li>Ensure all points are on the ground</li>
                        <li>Ensure all landmark pins are within masked area</li>
                        <li>Ensure defined areas does not include obstacle</li>
                        <li>Check landmark pin placements correspond with satellite or floorplan pins</li>
                    </ul>
                    <p>Error code CS203</p>
                </div>
            }
        case "CS204": //areatoosmall-area (%d) too small, try 1. mask less area; 2. higher tilt angle/smaller zoom; 3. try more accurate correspondences
            return {
                id,
                title: "Area captured scale too small",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Try:
                    <ul>
                        <li>Revert calibrate device location and increase the scale setting</li>
                        <li>Masking more area in device view</li>
                        <li>Ensure all points are on the ground</li>
                        <li>Ensure all landmark pins are within masked area</li>
                        <li>Ensure defined areas does not include obstacle</li>
                        <li>Check landmark pin placements correspond with satellite or floorplan pins</li>
                    </ul>
                    <p>Error code CS204</p>
                </div>
            }
        /**
         * API calibration/density_view
         */
        case "CS301": //cameraconnection-can not connect to camera
            return {
                id,
                title: "Device connection error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Check device deployment setting. Contact DCM if it still unavailable.</p>
                    <p>Error code CS301</p>
                </div>
            }
        case "CS302": //onviflib-onvif lib not supported
            return {
                id,
                title: "ONVIF PTZ unavailable on device",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Ensure device available for ONVIF PTZ, otherwise change device to (Other) option in deployment setting.</p>
                    <p>Error code CS302</p>
                </div>
            }
        case "CS303": //captureimage-failed to capture image wrong rtsp link construction/wrong device username password
            return {
                id,
                title: "Image capture error. Invalid device deployment details",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Ensure the following in deployment are correct:
                    <ul>
                        <li> RTSP link</li>
                        <li>Username and/or password</li>
                    </ul>
                    <p>Error code CS303</p>
                </div>
            }
        case "CS304": //networkinterpolation-times failed on testing
            return {
                id,
                title: "Network Setup error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Network Interpolation. Try:
                    <ul>
                        <li> Review device connection setting in deployment or contact DCM for help.</li>
                        <li>The network could be temporarily unavailable. Try again later.</li>
                    </ul>
                    <p>Error code CS304</p>
                </div>
            }
        case "CS305": //captureimage-
            return {
                id,
                title: "Image capture error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> The network could be temporarily unavailable. Try again later.</p>
                    <p>Error code CS305</p>
                </div>
            }
        case "CS306": //readvideo-failed to read video
            return {
                id,
                title: "Error loading video",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Try again later or contact DCM for help.</p>
                    <p>Error code CS306</p>
                </div>
            }
        case "CS307": //filesavelocal-
            return {
                id,
                title: "File save error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>System error. Contact DCM for support.</p>
                    <p>Error code CS307</p>
                </div>
            }
        /**
         * API calibration/device
         */
        case "CS401": //onviflib-onvif lib not supported
            return {
                id,
                title: "ONVIF PTZ unavailable on device",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p> Ensure device available for ONVIF PTZ, otherwise change camera to (Other) option in deployment. </p>
                    <p>Error code CS401</p>
                </div>
            }
        case "CS402": //captureimage-failed to capture image wrong rtsp link construction/wrong camera username password
            return {
                id,
                title: "Image capture error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Ensure the following in deployment are correct:
                    <ul>
                        <li> RTSP link</li>
                        <li>Username and/or password</li>
                    </ul>
                    <p>Error code CS402</p>
                </div>
            }
        case "CS403": //networkinterpolation-
            return {
                id,
                title: "Network Setup error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p> Review device connection setting in deployment or contact DCM for help. </p>
                    <p>Error code CS403</p>
                </div>

            }
        case "CS404": //captureimage
            return {
                id,
                title: "Image capture error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Network may be temporarily unavailable. Try again later. </p>
                    <p> Error code CS404</p>
                </div>
            }
        case "CS405": //keypem-keypem null
            return {
                id,
                title: "Key pem error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Contact DCM for support.</p>
                    <p>Error code CS405</p>
                </div>
            }
        case "CS406": //sshvpnserver-problem accessing vpn server with provided key and err
            return {
                id,
                title: "VPN server access unavailable",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Try:
                    <ul>
                        <li>Ensure the VPN network for the project is set up</li>
                        <li> Network may be temporarily unavailable. Try again later.</li>
                    </ul>
                    <p>Error code CS406</p>
                </div>
            }
        case "CS407": //deviceping-device ping failed
            return {
                id,
                title: "Device ping fail",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Unable to ping the device. Try:
                    <ul>
                        <li> Check ping configuration</li>
                        <li> Check network connection</li>
                        <li> Network may be temporarily unavailable. Try again later.</li>
                    </ul>
                    <p>Error code CS407</p>
                </div>
            }
        case "CS408": //cameracontrolserviceport-camera service closed
            return {
                id,
                title: "Device service access unavailable",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Ensure device service is activated. </p>
                    <p>Error code CS408</p>
                </div>
            }
        case "CS409": //cameravideoserviceport-camera service
            return {
                id,
                title: "Device video service error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p> Check video function availability on the device. </p>
                    <p>Error code CS409</p>
                </div>
            }
        case "CS410": //vpnserver-ping timeout
            return {
                id,
                title: "VPN server connection timed out",
                color: "danger",
                iconType: "alert",
                text: <div>
                    Try:
                    <ul>
                        <li>Ensure VPN network set up is available for connection</li>
                        <li> Network may be temporarily unavailable. Try again later. </li>
                    </ul>
                    <p>Error code CS410</p>
                </div>
            }
        /**
        * API calibration/map
        */
        case "CS501": //form is not vaild
            return {
                id,
                title: "Calibration form is not valid",
                color: "danger",
                iconType: "alert",
                text: <p>Ensure all inputs are correct</p>
            }
        /**
        * API calibration/mood_calibration
        */
        case "CS601": //flagmood-mood calibration is disabled because density calibration is not stable
            return {
                id,
                title: "Mood calibration setup unavailable",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Calibration result requires more accuracy. Recalibrate device view and location.</p>
                    <p>Error code CS601</p>
                </div>
            }
        /**
        * API calibration/mood_view
        */
        case "CS701": //onviflib-onvif lib not supported
            return {
                id,
                title: "ONVIF PTZ unavailable on device",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Ensure device supports ONVIF PTZ. </p>
                    <p>Error code CS701</p>
                </div>
            }
        case "CS702": //captureimage-failed to capture image wrong rtsp link construction/wrong camera username password
            return {
                id,
                title: "Image capture error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Try:
                    <ul>
                        <li>Ensure RTSP link format is correct</li>
                        <li>Ensure username and password is correct</li>
                    </ul>
                    <p>Error code CS702</p>
                </div>
            }
        case "CS703": //networkinterpolation
            return {
                id,
                title: "Network Setup error",
                color: "danger",
                iconType: "alert",
                text: <div>
                    <p>Review device connection setting in deployment or contact DCM for help. </p>
                    <p>Error code CS703</p>
                </div>
            }
        case "CS704": //moodview-moodview string invalid
            return {
                id,
                title: "Calibration server error",
                color: "danger",
                iconType: "alert",
                text: <p> Input error, contact DCM for help</p>
            }
        case "CS705": //captureimage
            return {
                id,
                title: "Image capture error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p>Network may be temporarily unavailable. Try again later. </p>
                    <p>Error code CS705</p>
                </div>
            }
        /**
         * Other validation errors
         */
        case "CS801": //input string should be one of the following; north, south, east, west
            return {
                id,
                title: "Device orientation error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p>Ensure orientation are facing north, east, south or west. </p>
                    <p>Error code CS801</p>
                </div>
            }
        //NOTE: skipped because no exact code
        //CS81x: str(input_name) + '-given ' + str(given) + ' ' + str(input) + ' should 
        //CS82x: str(input_name) + '-given ' + str(given) + ' ' + str(input) + ' should
        case "CS802": //corresponding points should have the same len
            return {
                id,
                title: "Calibration Input error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Ensure points are corresponding in location and device calibration. </p>
                    <p>Error code CS802</p>
                </div>
            }
        case "CS803": //device type ' + string + ' not support
            return {
                id,
                title: "Invalid device type",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Device type not support </p>
                    <p>Error code CS803</p>
                </div>
            }
        case "CS804": //remote host name ' + string + ' not support
            return {
                id,
                title: "Invalid host name",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Ensure host name is correct. </p>
                    <p>Error code CS804</p>
                </div>
            }
        case "CS805": //remote host ip ' + string + ' not public or not a ip address
            return {
                id,
                title: "Invalid host IP",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Ensure host IP is correct and public. </p>
                    <p>Error code CS805</p>
                </div>
            }
        case "CS806": //'remote host ip ' + string + ' not private or not a ip address'
            return {
                id,
                title: "Invalid host IP",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Ensure host IP is valid and private. </p>
                    <p>Error code CS806</p>
                </div>
            }
        case "CS807": //camera video stream port ' + str(string) + ' not support
            return {
                id,
                title: "Invalid stream port",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Ensure camera video steam port is valid. </p>
                    <p>Error code CS807</p>
                </div>
            }
        case "CS808": //camera control port ' + str(string) + ' not support
            return {
                id,
                title: "Invalid device control port",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> System Error. Try again later or contact DCM. </p>
                    <p>Error code CS808</p>
                </div>
            }
        case "CS809": //illegal character ':', '@' in username/password, please change camera username/password in your camera and try again
            return {
                id,
                title: "Invalid username/password",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <p> Ensure username/password does not contain ‘@’, ‘:’. Change username/password in device and try again. </p>
            }
        case "CS831": //illegal url pattern, should be
            return {
                id,
                title: "Invalid device deployment details",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Try:
                    <ul>
                        <li>Check RTSP link in deployment</li>
                        <li>Check username and password in deployment</li>
                        <li> Check IP Adress in deployment</li>
                    </ul>
                    <p>Error code CS831</p>
                </div>
            }
        case "CS832": //url provided username and password does not match user provided username and password
            return {
                id,
                title: "Invalid device deployment details",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    Try:
                    <ul>
                        <li>Check RTSP link in deployment</li>
                        <li>Check username and password in deployment</li>
                        <li> Check IP Adress in deployment</li>
                    </ul>
                    <p>Error code CS832</p>
                </div>
            }
        case "CS833": //user provided ip address does not match with video stream url user provided
            return {
                id,
                title: "Invalid device IP",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> IP address does not match with video stream url. Check IP Address in deployment setting.</p>
                    <p>Error code CS833</p>
                </div>
            }
        case "CS834": //url provided username and password does not match user provided username and password
            return {
                id,
                title: "Invalid username and/or password",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Ensure username and/or password in device deployment is correct.</p>
                    <p>Error code CS834</p>
                </div>
            }
        case "CS835": //input string should be ndim array x by 4 by 2, x < 11
            return {
                id,
                title: "Invalid area selection",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p>Error code CS835</p>
                </div>
            }
        case "CS836": //input string should be ndim array format [[x,y],[j,k], ...]
            return {
                id,
                title: "Invalid area selection",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Invalid area selection on calibrate device view. Try to create new area selection.</p>
                    <p>Error code CS836</p>
                </div>
            }
        case "CS837": //input string should be ndim array format [[x,y],[j,k], ...]
            return {
                id,
                title: "Invalid area selection",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Invalid area selection on calibrate device view. Try to create new area selection.</p>
                    <p>Error code CS837</p>
                </div>
            }
        case "CS838": //input string should be ndim array format [[x,y],[j,k]]
            return {
                id,
                title: "Invalid area selection",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Invalid area selection on calibrate device view. Try to create new area selection.</p>
                    <p>Error code CS838</p>
                </div>
            }
        case "CS839": //input string should be ndim array format [[x,y],[j,k],[a,b],[h,g]]
            return {
                id,
                title: "Invalid area selection",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Invalid area selection on calibrate device view. Try to create new area selection.</p>
                    <p>Error code CS839</p>
                </div>
            }
        case "CS840": //input string should be one of the following; north, south, east, west
            return {
                id,
                title: "Device orientation error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p>Ensure orientation are facing north, east, south or west. Contact DCM if problem still occurs. </p>
                    <p>Error code CS840</p>
                </div>
            }
        case "CS841": //input string should be one of the following; north, south, east, west
            return {
                id,
                title: "Device orientation error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p>Ensure orientation are facing north, east, south or west. Contact DCM if problem still occurs. </p>
                    <p>Error code CS841</p>
                </div>
            }
        case "CS842": //input camera centre;\n' + str(e)
            return {
                id,
                title: "Device input error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p>Reset device centre in deployment. </p>
                    <p>Error code CS842</p>
                </div>
            }
        case "CS843": //input correspondence coordinate system should be one of the following: WGS84, CameraCentre, Image (case sensitive)
            return {
                id,
                title: "Device input error",
                color: "danger",
                iconType: "alert",
                toastLifeTimeMs: 10000,
                text: <div>
                    <p> Reset device centre in deployment. </p>
                    <p>Error code CS843</p>
                </div>
            }


        /**
        * Default
        */
        default:
            return null;
        // return {
        //     id,
        //     title: "Calibration server error",
        //     color: "danger",
        //     iconType: "alert",
        //     text: <p>Please try again later or contact DCM for help</p>
        // }
    }
}