import axios from "axios"
import { IMeasurement } from "../../@types/IMeasurement"
import {
  CalibrationAreas,
  CalibrationConfigs,
  Cutoffs,
  GeoAdjustment,
  MeasurementsV2,
  PointerDates,
} from "../reducers/dataReducerV2/@types"

export const dataV2API = {
  getCountsByDay: async (input: {
    apiGatewayUrl: string
    selectedProjectId: string
    token: string
  }) => {
    const { apiGatewayUrl, selectedProjectId, token } = input
    const res = await axios.get(
      `${apiGatewayUrl}/countsByDay/${selectedProjectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as { [ts: string]: number }
  },
  getMeasurements: async (input: {
    apiGatewayUrl: string
    token: string
    hashKey: string
    projectId: string
    zoneId: string
    //Time range
    sinceTimestamps: PointerDates
    toTimestamp: Date
    //Config
    aggregationWindow: number
    complianceLimit: number
    // Made partial because cumulative counting was added to metrics and does not have cutoffs
    cutoffs: Partial<Cutoffs>
    geoAdjustment: GeoAdjustment
    calibrationAreas: CalibrationAreas
    calibrationConfigs: CalibrationConfigs
    groupLimit: number
    groupThreshhold: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      hashKey,
      projectId,
      zoneId,
      sinceTimestamps,
      toTimestamp,
      aggregationWindow,
      complianceLimit,
      cutoffs,
      geoAdjustment,
      calibrationAreas,
      calibrationConfigs,
      groupLimit,
      groupThreshhold,
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/measurementsV2/`,
      {
        hashKey,
        projectId,
        zoneId,
        sinceTimestamps,
        toTimestamp,
        aggregationWindow,
        complianceLimit,
        cutoffs,
        geoAdjustment,
        calibrationAreas,
        calibrationConfigs,
        groupLimit,
        groupThreshhold,
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as MeasurementsV2
  },
  getMeasurementValues: async (input: {
    apiGatewayUrl: string
    token: string
    hashKey: string
    projectId: string
    zoneId: string
    //Time range
    sinceTimestamps: PointerDates
    toTimestamp: Date
    //Config
    aggregationWindow: number
    complianceLimit: number
    // Made partial because cumulative counting was added to metrics and does not have cutoffs
    cutoffs: Partial<Cutoffs>
    geoAdjustment: GeoAdjustment
    calibrationAreas: CalibrationAreas
    calibrationConfigs: CalibrationConfigs
    groupLimit: number
    groupThreshhold: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      hashKey,
      projectId,
      zoneId,
      sinceTimestamps,
      toTimestamp,
      aggregationWindow,
      complianceLimit,
      cutoffs,
      geoAdjustment,
      calibrationAreas,
      calibrationConfigs,
      groupLimit,
      groupThreshhold,
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/measurementsV2/values`,
      {
        hashKey,
        projectId,
        zoneId,
        sinceTimestamps,
        toTimestamp,
        aggregationWindow,
        complianceLimit,
        cutoffs,
        geoAdjustment,
        calibrationAreas,
        calibrationConfigs,
        groupLimit,
        groupThreshhold,
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as MeasurementsV2
  },
  getMeasurementGroupings: async (input: {
    apiGatewayUrl: string
    token: string
    hashKey: string
    projectId: string
    zoneId: string
    //Time range
    sinceTimestamps: PointerDates
    toTimestamp: Date
    //Config
    aggregationWindow: number
    complianceLimit: number
    // Made partial because cumulative counting was added to metrics and does not have cutoffs
    cutoffs: Partial<Cutoffs>
    geoAdjustment: GeoAdjustment
    calibrationAreas: CalibrationAreas
    calibrationConfigs: CalibrationConfigs
    groupLimit: number
    groupThreshhold: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      hashKey,
      projectId,
      zoneId,
      sinceTimestamps,
      toTimestamp,
      aggregationWindow,
      complianceLimit,
      cutoffs,
      geoAdjustment,
      calibrationAreas,
      calibrationConfigs,
      groupLimit,
      groupThreshhold,
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/measurementsV2/groupings`,
      {
        hashKey,
        projectId,
        zoneId,
        sinceTimestamps,
        toTimestamp,
        aggregationWindow,
        complianceLimit,
        cutoffs,
        geoAdjustment,
        calibrationAreas,
        calibrationConfigs,
        groupLimit,
        groupThreshhold,
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as MeasurementsV2
  },
  getLatestMeasurements: async (input: {
    apiGatewayUrl: string
    token: string
    hashKey: string
    projectId: string
    zoneId: string
    //Time range
    sinceTimestamps: PointerDates
    toTimestamp: Date
    //Config
    aggregationWindow: number
    complianceLimit: number
    // Made partial because cumulative counting was added to metrics and does not have cutoffs
    cutoffs: Partial<Cutoffs>
    geoAdjustment: GeoAdjustment
    calibrationAreas: CalibrationAreas
    calibrationConfigs: CalibrationConfigs
    groupLimit: number
    groupThreshhold: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      hashKey,
      projectId,
      zoneId,
      sinceTimestamps,
      toTimestamp,
      aggregationWindow,
      complianceLimit,
      cutoffs,
      geoAdjustment,
      calibrationAreas,
      calibrationConfigs,
      groupLimit,
      groupThreshhold,
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/measurementsV2/latest`,
      {
        hashKey,
        projectId,
        zoneId,
        sinceTimestamps,
        toTimestamp,
        aggregationWindow,
        complianceLimit,
        cutoffs,
        geoAdjustment,
        calibrationAreas,
        calibrationConfigs,
        groupLimit,
        groupThreshhold,
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as MeasurementsV2
  },
  /**
   * Translate xy coordinates on camera view to geocoordinates on earth
   */
  translateCamToGeo: async (input: {
    apiGatewayUrl: string
    token: string
    lat: number
    lng: number
    projectionInverses: { x: number, y: number, z: number }[]
    pointsXY: { x: number, y: number }[]
    mode: string
    mask: number[][]
    detections: number[][][]
  }) => {
    const {
      apiGatewayUrl,
      token,
      lat,
      lng,
      projectionInverses,
      pointsXY,
      mode,
      mask,
      detections
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/calibrationHelpers/translateCamToGeo`,
      {
        latitude: lat,
        longitude: lng,
        projectionInverses: JSON.stringify(projectionInverses),
        pointsXY: JSON.stringify(pointsXY),
        mode,
        mask: JSON.stringify(mask),
        detections: JSON.stringify(detections)
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * Gets data for on prem dash
   */
  fetchDashV2: async (input: {
    apiGatewayUrl: string
    token: string
    projectId: string
    siteId: string
    startTimestamp: string
    endTimestamp: string
  }) => {
    const {
      apiGatewayUrl,
      token,
      projectId,
      siteId,
      startTimestamp,
      endTimestamp,
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/measurementsV2/v2`,
      {
        projectId,
        siteId,
        startTimestamp,
        endTimestamp
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
}
