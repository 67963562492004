import { networkAPIV2 } from "./networkAPIV2";
import { auth0API } from "./auth0API";
import { dataAPI } from "./dataAPI";
import { projectAPI } from "./projectAPI";
import { tenantAPI } from "./tenantAPI";
import { dataV2API } from "./dataV2API";
import { reportsAPI } from "./reportsAPI";
import { notificationsAPI } from "./notificationsAPI";
import { vmsAPI } from "./vmsAPI";


export const dependencies = {
    networkAPIV2,
    auth0API,
    dataAPI,
    projectAPI,
    tenantAPI,
    dataV2API,
    reportsAPI,
    notificationsAPI,
    vmsAPI,
};
export type Dependencies = typeof dependencies;