import { Feature, Polygon } from "@turf/turf"
/**
 * Given featureArray string, or feature string, returns first feature
 */
function parseFeature(area: string): Feature<Polygon> | undefined {
  try {
    const feature: Feature<Polygon>[] | Feature<Polygon> = JSON.parse(area) //Should be an array of 1 feature
    if (Array.isArray(feature)) {
      return feature[0]
    } else {
      return feature
    }
  } catch (error) {
    // console.log("parseFeatureError:", error)
  }
}
export default parseFeature
