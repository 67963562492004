import { ICalibration } from "../../../../@types/ICalibration"
import { PointerDates } from "../@types"

export const generateSinceTimestamps = (
  calibrations: ICalibration[],
  start: Date,
  ccStart: Date,
  compoundIds?: string[],
): PointerDates => {
  const calibrationIds = calibrations.map(c => c.calibrationId)
  const pointers: PointerDates = {
    data: {},
    heatmaps: {},
    images: {},
  }
  calibrationIds.forEach(calibrationId => {
    pointers.data[calibrationId] = {
      d: start,
      f: start,
      m: start,
      s: start,
      c: start,
      h: start,
      n: ccStart,
    }
    pointers.heatmaps[calibrationId] = {
      d: start,
      f: start,
      m: start,
      s: start,
      c: start,
      h: start,
      n: ccStart,
    }
    pointers.images[calibrationId] = start
  })
  if (compoundIds) {
    pointers.compound = {}
    compoundIds.forEach(compoundId => pointers.compound[compoundId] = start)
  }
  return pointers
}
