import { Epic } from "redux-observable"
import { Observable, interval } from "rxjs"
import { switchMap, filter, mergeMap } from "rxjs/operators"
import { produce, Draft } from "immer"
import current from "immer"
import { IState, RootAction } from ".."
import { DEFAULT_MAX_CUTOFFS } from "../../../constants"
import { DeploymentStatus } from "../../../@types/IMeasurement"
import { IDeployment } from "../../../@types/IDeployment"
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import { tenantActionsAsync } from "../tenantReducer"
import { MeasurementsV2, PointerDates, DashV2Data } from "./@types"
import { Dependencies } from "../../ReduxProvider"
import { generateSinceTimestamps } from "./functions/generateSinceTimestamps"
import { getCalibrationsByZoneId } from "./functions/getCalibrationsByZoneId"
import { getCalibrationAreas } from "./functions/getCalibrationAreas"
import { getCalibrationConfigs } from "./functions/getCalibrationConfigs"
import { sortMeasurements } from "./functions/sortMeasurements"
import { extractPointerDates } from "./functions/extractPointerDates"
import { mergePointerDates } from "./functions/mergePointerDates"
import { mergeMeasurements } from "./functions/mergeMeasurements"
import { mergeMeasurementsV2 } from "./functions/mergeMeasurementsV2"
import { patchPointerDates } from "./functions/patchPointerDates"
import { appActions } from "../appReducer"
import moment from "moment-timezone";
import { liveJobQueue } from "../../controllers/dataV2FetchLiveController"
import { pastJobQueue } from "../../controllers/dataV2FetchPastController"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */

export interface DataV2State {
  prevSelectedToDate: Date
  currentDate: Date
  pointerDates: { [zoneId: string]: PointerDates }
  zoneData: { [zoneId: string]: MeasurementsV2 }
  pastZoneData: { [zoneId: string]: MeasurementsV2 }
  //This stores if the cameras in the deployment are active or not
  zoneStatus: { [zoneId: string]: DeploymentStatus }
  projectAggregates: {
    countsByDay: { [ts: string]: number }
  }
  testHeatmapData: {}
  dashV2Data: DashV2Data
}

export const initialDataV2State: DataV2State = {
  prevSelectedToDate: new Date(),
  currentDate: new Date(),
  pointerDates: {},
  zoneData: {},
  pastZoneData: {},

  zoneStatus: {},
  projectAggregates: {
    countsByDay: {},
  },
  testHeatmapData: {},
  dashV2Data: {}
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const dataV2Actions = {
  setCountsByDay: createAction(
    "@dataV2/setCountsByDay",
    (countsByDay: { [ts: string]: number }) => countsByDay
  )(),
  recalculate: createAction(
    "@dataV2/recalculate",
    (input: { refreshData?: boolean }) => ({ refreshData: input.refreshData })
  )(),
  initV2Merge: createAction(
    "@dataV2/initV2Merge",
    (
      zoneId: string,
      measurements: MeasurementsV2
    ) => ({ zoneId, measurements })
  )(),
  pastInitMerge: createAction(
    "@dataV2/pastInitMerge",
    (
      zoneId: string,
      measurements: MeasurementsV2,
      cutoff: Date
    ) => ({ zoneId, measurements, cutoff })
  )(),
  initV2Fail: createAction(
    "@dataV2/initV2Fail",
    (
      error: Error,
      zoneId: string
    ) => ({ zoneId, error })
  )(),
  pastInitFail: createAction(
    "@dataV2/pastInitFail",
    (
      error: Error,
      zoneId: string,
    ) => ({ zoneId, error })
  )(),
}
export const dataV2ActionsAsync = {
  init: createAsyncAction(
    "@dataV2/init/req",
    "@dataV2/init/res",
    "@dataV2/init/err"
  )<
    {},
    {
      zoneIds: string[]
      zoneId: string
      measurements: MeasurementsV2
    },
    { error: Error }
  >(),
  initV2: createAsyncAction(
    "@dataV2/initV2/req",
    "@dataV2/initV2/res",
    "@dataV2/initV2/err"
  )<
    { zoneIds: string[] },
    {},
    { error: Error }
  >(),
  initLoad: createAsyncAction(
    "@dataV2/initLoad/req",
    "@dataV2/initLoad/res",
    "@dataV2/initLoad/err"
  )<
    {},
    {
      zoneIds: string[]
      zoneId: string
      measurements: MeasurementsV2
    },
    { error: Error }
  >(),
  refresh: createAsyncAction(
    "@dataV2/refresh/req",
    "@dataV2/refresh/res",
    "@dataV2/refresh/err"
  )<
    {},
    { zoneId: string; measurements: MeasurementsV2; currentDate: Date },
    { error: Error }
  >(),
  fetchPast: createAsyncAction(
    "@dataV2/fetchPast/req",
    "@dataV2/fetchPast/res",
    "@dataV2/fetchPast/err"
  )<
    { selectedDate: Date },
    {
      zoneId: string
      measurements: MeasurementsV2
      cutoff: Date
    },
    { error: Error }
  >(),
  fetchPastInit: createAsyncAction(
    "@dataV2/fetchPastInit/req",
    "@dataV2/fetchPastInit/res",
    "@dataV2/fetchPastInit/err"
  )<
    { selectedDate: Date },
    {},
    {
      error: Error,
    }
  >(),
  fetchPastLoad: createAsyncAction(
    "@dataV2/fetchPastLoad/req",
    "@dataV2/fetchPastLoad/res",
    "@dataV2/fetchPastLoad/err"
  )<
    { selectedDate: Date },
    {
      zoneId: string
      measurements: MeasurementsV2
      cutoff: Date
    },
    { error: Error }
  >(),
  translateCamToGeo: createAsyncAction(
    "@dataV2/translateCamToGeo/req",
    "@dataV2/translateCamToGeo/res",
    "@dataV2/translateCamToGeo/err"
  )<
    { 
      calibrationId: string,
      pointsXY: { x: number, y: number }[],
      lat: number,
      lng: number,
      projectionInverses: { x: number, y: number, z: number }[],
      mode: string,
      mask: number[][],
      detections: number[][][]
    },
    {
      data: any
    },
    { error: Error }
  >(),
  fetchV2: createAsyncAction(
    "@dataV2/fetchV2/req",
    "@dataV2/fetchV2/res",
    "@dataV2/fetchV2/err"
  )<
    {
      // siteId: string,
      // startTimestamp: Date,
      // endTimestamp: Date,
    },
    { data: DashV2Data },
    { error: Error }
  >(),
}

type ValueOf<T> = T[keyof T]
export type DataV2Action =
  | ReturnType<ValueOf<typeof dataV2Actions>>
  | ReturnType<ValueOf<ValueOf<typeof dataV2ActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */
// Sopa dash
const ROUND_INTERVAL = 1000 * 60; // 1 minute intervals for
const AVG_WINDOW = 5 * 1000 * 60; // 5 minute rolling AVG
// const ROUND_INTERVAL = 15000
const RANGE = 1000 * 60 * 60
const emptyMeasurements: MeasurementsV2 = {
  data: {
    combined: {
      d: {},
      f: {},
      m: {},
      s: {},
      c: {},
      h: {},
      n: {},
    },
    calibrations: {},
    compound: {},
  },
  grouping: {
    combined: {},
    calibrations: {},
  },
  heatmapRisks: {
    combined: {
      d: {},
      f: {},
      m: {},
      s: {},
      c: {},
      h: {},
      n: {},
    },
    calibrations: {},
  },
  images: {
    calibrations: {},
  },
  blurredImages: {
    calibrations: {},
  },
  fetchedNotes: {},
  status: {},
}
const emptyPointers: PointerDates = {
  data: {},
  heatmaps: {},
  images: {},
  compound: {},
}

export const dataV2Reducer = produce(
  (draft: Draft<DataV2State>, action: RootAction) => {
    switch (action.type) {
      case getType(dataV2ActionsAsync.init.success):
        {
          try {
            const { zoneIds, zoneId, measurements } = action.payload
            // const sorted = sortMeasurements(measurements)
            // const timestampPointers = extractPointerDates(sorted)
            // draft.pointerDates[zoneId] = timestampPointers
            // draft.zoneData[zoneId] = sorted
            // draft.zoneStatus[zoneId] = measurements.status

            const sorted = sortMeasurements(measurements)
            const timestampPointers = extractPointerDates(sorted)
            // Just initialize to sorted if data doesn't yet exist
            if (draft.zoneData[zoneId]) {
              const currentPointers = draft.pointerDates[zoneId] || emptyPointers
              const mergedPointers = mergePointerDates(
                currentPointers,
                timestampPointers
              )

              // const now = roundDate(ROUND_INTERVAL)(new Date())
              const now = new Date()
              const cutoff = new Date(now.getTime() - RANGE)
              const existingMeasurements =
                draft.zoneData[zoneId] || emptyMeasurements
              const mergedMeasurements = mergeMeasurements(
                existingMeasurements,
                sorted,
                cutoff
              )

              // Measurements need to be sorted
              draft.zoneData[zoneId] = sortMeasurements(mergedMeasurements)
              draft.pointerDates[zoneId] = mergedPointers
              draft.zoneStatus[zoneId] = measurements.status
            } else {
              draft.pointerDates[zoneId] = timestampPointers
              draft.zoneData[zoneId] = sorted
              draft.zoneStatus[zoneId] = measurements.status
            }

            //Remove zone data that doesnt belong to current selected Project
            Object.keys(draft.zoneData).forEach(zoneId => {
              if (!zoneIds.includes(zoneId)) delete draft.zoneData[zoneId]
            })
            Object.keys(draft.pastZoneData).forEach(zoneId => {
              if (!zoneIds.includes(zoneId)) delete draft.pastZoneData[zoneId]
            })
            Object.keys(draft.pointerDates).forEach(zoneId => {
              if (!zoneIds.includes(zoneId)) delete draft.pointerDates[zoneId]
            })
            Object.keys(draft.zoneStatus).forEach(zoneId => {
              if (!zoneIds.includes(zoneId)) delete draft.zoneStatus[zoneId]
            })
          } catch (error) {
            console.log("dataV2ActionsAsync.init:", error)
          }
        }
        return
      case getType(dataV2ActionsAsync.refresh.success):
        {
          try {
            const { zoneId, measurements, currentDate } = action.payload
            const sorted = sortMeasurements(measurements)
            const timestampPointers = extractPointerDates(sorted)
            const currentPointers = draft.pointerDates[zoneId] || emptyPointers
            const mergedPointers = mergePointerDates(
              currentPointers,
              timestampPointers
            )

            // const now = roundDate(ROUND_INTERVAL)(new Date())
            const now = new Date()
            const cutoff = new Date(now.getTime() - RANGE)
            const existingMeasurements =
              draft.zoneData[zoneId] || emptyMeasurements
            const mergedMeasurements = mergeMeasurements(
              existingMeasurements,
              sorted,
              cutoff
            )

            // Latest data can just be appended to sorted data; no need to re sort
            draft.zoneData[zoneId] = mergedMeasurements
            draft.pointerDates[zoneId] = mergedPointers
            draft.currentDate = currentDate
            draft.zoneStatus[zoneId] = measurements.status
          } catch (error) {
            console.log("dataV2ActionsAsync.refresh:", error)
          }
        }
        return
      case getType(dataV2ActionsAsync.initV2.request): {
        const { zoneIds } = action.payload

        //Remove zone data that doesnt belong to current selected Project
        Object.keys(draft.zoneData).forEach(zoneId => {
          if (!zoneIds.includes(zoneId)) delete draft.zoneData[zoneId]
        })
        Object.keys(draft.pastZoneData).forEach(zoneId => {
          if (!zoneIds.includes(zoneId)) delete draft.pastZoneData[zoneId]
        })
        Object.keys(draft.pointerDates).forEach(zoneId => {
          if (!zoneIds.includes(zoneId)) delete draft.pointerDates[zoneId]
        })
        Object.keys(draft.zoneStatus).forEach(zoneId => {
          if (!zoneIds.includes(zoneId)) delete draft.zoneStatus[zoneId]
        })
        return
      }
      case getType(dataV2Actions.initV2Merge): {
        try {
          const { zoneId, measurements } = action.payload
          if (!zoneId) return
          if (Object.keys(measurements.data.calibrations).length < 1) {
            // No data
            liveJobQueue.cancelJobsByZoneId(zoneId)
          }

          // No need for sorting - only one record
          // draft.pointerDates[zoneId] = extractPointerDates(measurements)
          // draft.zoneData[zoneId] = measurements
          // draft.zoneStatus[zoneId] = measurements.status

          const sorted = sortMeasurements(measurements)
          if (draft.zoneData[zoneId]) {
            const now = new Date()
            const cutoff = new Date(now.getTime() - RANGE)
            const existingMeasurements =
              draft.zoneData[zoneId] || emptyMeasurements
            const mergedMeasurements = mergeMeasurementsV2(
              existingMeasurements,
              sorted,
              cutoff
            )

            // Measurements need to be sorted
            draft.zoneData[zoneId] = sortMeasurements(mergedMeasurements)
            draft.pointerDates[zoneId] = extractPointerDates(sorted)
            draft.zoneStatus[zoneId] = measurements.status
          } else {
            draft.zoneData[zoneId] = measurements
            draft.pointerDates[zoneId] = extractPointerDates(measurements)
            draft.zoneStatus[zoneId] = measurements.status
          }
        } catch (error) {
          console.log("dataV2ActionsAsync.fetchLiveInitV2:", error)
        }
        return
      }
      case getType(dataV2Actions.initV2Fail): {
        try {
          const { zoneId } = action.payload
          liveJobQueue.cancelJobsByZoneId(zoneId)
        } catch (error) {
          console.log("dataV2ActionsAsync.fetchLiveInitV2:", error)
        }
        return
      }
      case getType(dataV2ActionsAsync.initLoad.success):
        {
          try {
            const { zoneId, measurements } = action.payload

            const sorted = sortMeasurements(measurements)
            // Just initialize to sorted if data doesn't yet exist
            if (draft.zoneData[zoneId]) {
              const now = new Date()
              const cutoff = new Date(now.getTime() - RANGE)
              const existingMeasurements =
                draft.zoneData[zoneId] || emptyMeasurements
              const mergedMeasurements = mergeMeasurementsV2(
                existingMeasurements,
                sorted,
                cutoff
              )

              // Measurements need to be sorted
              draft.zoneData[zoneId] = sortMeasurements(mergedMeasurements)
              draft.zoneStatus[zoneId] = measurements.status
            } else {
              draft.zoneData[zoneId] = sorted
              draft.zoneStatus[zoneId] = measurements.status
            }
          } catch (error) {
            console.log("dataV2ActionsAsync.initLoad:", error)
          }
        }
        return
      case getType(dataV2ActionsAsync.fetchPast.request): {
        draft.prevSelectedToDate = action.payload.selectedDate
        pastJobQueue.clear() // Clear current past jobs in past queue
        return
      }
      case getType(dataV2ActionsAsync.fetchPast.success): {
        try {
          const { zoneId, measurements, cutoff } = action.payload
          if (!zoneId) return

          //TODO: dont need pointer dates to be stored here ?
          // const timestampPointers = extractPointerDates(sorted)
          // draft.pointerDates[zoneId] = timestampPointers
          // Initialise to sorted data if data doesn't yet exist
          if (draft.pastZoneData[zoneId]) {
            const existingMeasurements = draft.pastZoneData[zoneId] || emptyMeasurements
            const mergedMeasurements = mergeMeasurements(
              existingMeasurements,
              measurements,
              cutoff
            )
            // Measurements need to be sorted
            draft.pastZoneData[zoneId] = sortMeasurements(mergedMeasurements)
          } else {
            draft.pastZoneData[zoneId] = sortMeasurements(measurements)
          }

          draft.zoneStatus[zoneId] = measurements.status
        } catch (error) {
          console.log("dataV2ActionsAsync.fetchPast:", error)
        }
        return
      }
      case getType(dataV2ActionsAsync.fetchPastInit.request):
        draft.prevSelectedToDate = action.payload.selectedDate
        pastJobQueue.clear() // Clear current past jobs in past queue
        return
      case getType(dataV2Actions.pastInitMerge): {
        try {
          const { zoneId, measurements, cutoff } = action.payload
          if (!zoneId) return
          if (Object.keys(measurements.data.calibrations).length < 1) {
            // No data
            pastJobQueue.cancelJobsByZoneId(zoneId)
            return;
          }

          // draft.pastZoneData[zoneId] = measurements
          // draft.zoneStatus[zoneId] = measurements.status

          if (draft.pastZoneData[zoneId]) {
            const existingMeasurements = draft.pastZoneData[zoneId] || emptyMeasurements
            const mergedMeasurements = mergeMeasurementsV2(
              existingMeasurements,
              measurements,
              cutoff
            )
            // Measurements need to be sorted
            draft.pastZoneData[zoneId] = sortMeasurements(mergedMeasurements)
          } else {
            draft.pastZoneData[zoneId] = measurements
          }

          draft.zoneStatus[zoneId] = measurements.status
        } catch (error) {
          console.log("dataV2ActionsAsync.fetchPastInit:", error)
        }
        return
      }
      case getType(dataV2Actions.pastInitFail): {
        try {
          const { zoneId } = action.payload
          if (!zoneId) return
          pastJobQueue.cancelJobsByZoneId(zoneId)
        } catch (error) {
          console.log("dataV2ActionsAsync.fetchPastInit:", error)
        }
        return
      }
      case getType(dataV2ActionsAsync.fetchPastLoad.success): {
        try {
          const { zoneId, measurements, cutoff } = action.payload
          if (!zoneId) return
          // if (true) return

          //TODO: dont need pointer dates to be stored here ?
          // const timestampPointers = extractPointerDates(sorted)
          // draft.pointerDates[zoneId] = timestampPointers
          // Initialise to sorted data if data doesn't yet exist
          if (draft.pastZoneData[zoneId]) {
            const existingMeasurements = draft.pastZoneData[zoneId] || emptyMeasurements
            const mergedMeasurements = mergeMeasurementsV2(
              existingMeasurements,
              measurements,
              cutoff
            )
            // Measurements need to be sorted
            draft.pastZoneData[zoneId] = sortMeasurements(mergedMeasurements)
          } else {
            draft.pastZoneData[zoneId] = sortMeasurements(measurements)
          }

          draft.zoneStatus[zoneId] = measurements.status
        } catch (error) {
          console.log("dataV2ActionsAsync.fetchPastLoad:", error)
        }
        return
      }
      case getType(tenantActionsAsync.updateDeployment.success):
        {
          //@ts-ignore
          const res = action.payload.resObj as IDeployment
          if (!res) return //Safety check
          const { zoneId, flagPtzPause, deploymentId } = res
          draft.zoneStatus[zoneId]
          if (draft.zoneStatus[zoneId]) {
            draft.zoneStatus[zoneId][deploymentId] = `${flagPtzPause}`
          }
        }
        return
      case getType(dataV2Actions.setCountsByDay):
        {
          draft.projectAggregates.countsByDay = action.payload
        }
        return
      case getType(dataV2ActionsAsync.translateCamToGeo.success):
        {
          const { data } = action.payload
          let formatted = {
            0: {}
          }
          data.forEach(geoHash => {
            formatted[0][geoHash] = { v: 1 }
          })
          draft.testHeatmapData = formatted
        }
        return
      case getType(dataV2ActionsAsync.fetchV2.success): {
        {
          const { data } = action.payload;
          draft.dashV2Data = data;
        }
        return
      }
    }
  },
  initialDataV2State
)

export default dataV2Reducer


export const dataV2RecalculateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(dataV2Actions.recalculate)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const zoneIds = Object.keys(state.tenant.zones)
        const selectedDate = state.dataV2.prevSelectedToDate
        const refreshData = action.payload.refreshData //This is passed in from zone config save

        async function fetchData() {
          if (!refreshData) return //Skip if not from zone config update

          observer.next(dataV2ActionsAsync.fetchPast.request({ selectedDate }))

          observer.next(dataV2ActionsAsync.initV2.request({ zoneIds }))
        }
        fetchData().catch(error => console.log(error))
      })
    })
  )
}

export const dataV2TranslateCamToGeoEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(dataV2ActionsAsync.translateCamToGeo.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants 
        const { lat, lng, projectionInverses, pointsXY, mode, mask, detections } = action.payload
        async function fetchData() {
          const token = client ? await client.getTokenSilently() : ""
          const res = await dependencies.dataV2API.translateCamToGeo({
            token,
            apiGatewayUrl,
            lat,
            lng,
            projectionInverses,
            pointsXY,
            mode,
            mask,
            detections
          })

          if (res && res.length > 0) {
            observer.next(dataV2ActionsAsync.translateCamToGeo.success({ data: res }))
          }
        }
        fetchData().catch(error => {
          console.log(error)
          observer.next(dataV2ActionsAsync.translateCamToGeo.failure({ error }))
        })
      })
    })
  )
}


export const fetchV2Epic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf([
      dataV2ActionsAsync.fetchV2.request,
    ])),
    switchMap(action => {
      // return interval(ROUND_INTERVAL).pipe(
      //   mergeMap(() => {
          return new Observable<RootAction>(observer => {
            // console.log("POLLING V2");
            const state = state$.value as IState
            const client = state.auth0.auth0Client
            const { apiGatewayUrl } = state.constants 
            const { selectedProjectId, dashV2SiteId } = state.tenant;
            const { tabActive, dashActive, refreshEnabled } = state.app
            const endTimestamp = new Date();
            const startTimestamp = new Date(endTimestamp.getTime() - AVG_WINDOW);
            // const siteId = "e541d6fb-ee22-4b10-b540-6fb2cd2638f5"; // temp
            // const { siteId, startTimestamp, endTimestamp } = action.payload
            if (!tabActive || !dashActive || !refreshEnabled) return
            if (!selectedProjectId) return;
            async function fetchData() {
              const token = client ? await client.getTokenSilently() : ""
              const res = await dependencies.dataV2API.fetchDashV2({
                token,
                apiGatewayUrl,
                projectId: selectedProjectId,
                siteId: dashV2SiteId,
                startTimestamp: startTimestamp.toISOString(),
                endTimestamp: endTimestamp.toISOString(),
              })

              if (res) {
                observer.next(dataV2ActionsAsync.fetchV2.success({ data: res }))
              }
            }
            fetchData().catch(error => {
              console.log(error)
              observer.next(dataV2ActionsAsync.fetchV2.failure({ error }))
            })
          })
      //   })
      // )
    })
  )
}

export const fetchV2RefreshEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf([
      dataV2ActionsAsync.init.success,
      dataV2ActionsAsync.initV2.success
    ])),
    switchMap(action => {
      return interval(ROUND_INTERVAL).pipe(
        mergeMap(() => {
          return new Observable<RootAction>(observer => {
            observer.next(dataV2ActionsAsync.fetchV2.request({}));
          })
        })
      )
    })
  )
}