import { ReportData } from "../redux/reducers/reportsReducer/@types"

export const isReportDataEmpty = (data: any): boolean => {
    if (!data || !data.data) return false;
    const reportData: ReportData = data.data;
    if (
        reportData.pedestriandetection
        && Object.keys(reportData.pedestriandetection.table).length < 1
        && reportData.headcount
        && Object.keys(reportData.headcount.lineChart).length < 1
        && reportData.headcount.table.length < 1
        && reportData.density
        && Object.keys(reportData.density.lineChart).length < 1
        && reportData.density.table.length < 1
        && reportData.flow
        && Object.keys(reportData.flow.lineChart).length < 1
        && Object.keys(reportData.flow.directionAreaChart).length < 1
        && Object.keys(reportData.flow.directionRadarChart).length < 1
        && Object.keys(reportData.flow.speedAreaChart).length < 1
        && reportData.flow.speedTable.length < 1
        && reportData.mood
        && Object.keys(reportData.mood.pieChart).length < 1
        && Object.keys(reportData.mood.stackedAreaChart).length < 1
        && reportData.socialdistance
        && Object.keys(reportData.socialdistance.stackedBarChart).length < 1
        && Object.keys(reportData.socialdistance.lineChart).length < 1
        && reportData.notification
        && Object.keys(reportData.notification.notifications).length < 1
    ) return true;
    return false;
}