import { Epic } from "redux-observable"
import { Observable } from "rxjs"
import { switchMap, filter } from "rxjs/operators"
import { isActionOf } from "typesafe-actions"
import { Dependencies } from "./../ReduxProvider"
import { IState, RootAction } from "../reducers"
import { tenantActionsAsync } from "../reducers/tenantReducer"
import { Socket, io as IO } from "socket.io-client"
import { auth0ActionsAsync } from "../reducers/auth0Reducer"
import { NotificationsAttribute } from "../reducers/notifications/@types"
import {
  notificationActions,
  notificationActionsAsync,
} from "../reducers/notifications"
import { io } from "socket.io-client"

let socket: Socket;

//When tenant loads, create a connection to the notifications server socketio
export const socketInitEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(auth0ActionsAsync.login.success)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        async function setupSocket() {
          const state = state$.value
          const url = state.constants.notificationsUrl
          const path = url
            .split("/")
            .slice(-1)
            .pop()
          const token =
            (await state.auth0.auth0Client?.getTokenSilently()) || ""
          const projectId = state.tenant.selectedProjectId

          //Setup a new socket
          console.log("SETTING UP SOCKET")
          socket = io(url, {
            path: `/${path}/socket.io`,
            withCredentials: false,
            auth: {
              token,
            },
            transports: ["websocket"], //To fix multi-node problem without sticky balancing
          })
          //   socket.connect()

          //Register socket event listeners
          // client-side
          socket.on("connect", () => {
            if (projectId) {
              socket.emit("notifications:projectChange", {
                projectId,
              })
            }

            console.log("connect", socket.id) // x8WIv7-mJelg7on_ALbx
          })
          socket.on("reconnect", () => {
            if (projectId) {
              socket.emit("notifications:projectChange", {
                projectId,
              })
            }

            console.log("reconnect", socket.id) // x8WIv7-mJelg7on_ALbx
          })

          socket.on("disconnect", () => {
            console.log("disconnect", socket.id) // undefined
          })

          // Getting Alerts
          socket.on("notifications:notification", e => {
            console.log("SOCKET DATA:", e)
            const projectId = e.projectId
            const notification: NotificationsAttribute = e.payload
            if (projectId !== state$.value.tenant.selectedProjectId) return
            observer.next(notificationActions.addAlert(notification))
          })

          // Actioning an alert
          socket.on("notifications:update", e => {
            const payload = e.payload
            observer.next(notificationActions.updateNotifications(payload.notificationIds, payload.body))
          })

          socket.on("message", e => {
            console.log("socket message event:", e)
          })
        }
        setupSocket()
      })
    })
  )
}

//When tenant loads, create a connection to the notifications server socketio
export const socketProjectChangeEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.selectProject.success)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        if (!socket) return
        const projectId = action.payload.projectId
        socket.emit("notifications:projectChange", {
          projectId,
        })
      })
    })
  )
}

export const notificationUpdateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.editNotificationsState.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        if (!socket) return
        const state = state$.value as IState
        const projectId = state.tenant.selectedProjectId
        socket.emit("notifications:update", {
          payload: {
            ...action.payload,
            projectId
          }
        })
      })
    })
  )
}