import { CalibrationValues } from "../@types"

export const forCalibrations = <T>(
  input: CalibrationValues<T>,
  fn: (input: T) => T
) => {
  const output: CalibrationValues<T> = {}
  Object.keys(input).forEach(calibrationId => {
    output[calibrationId] = fn(input[calibrationId])
  })
  return output
}
