import { Epic } from "redux-observable"
import { Observable, interval } from "rxjs"
import { switchMap, filter } from "rxjs/operators"
import { produce, Draft } from "immer"
import { IState, RootAction } from ".."
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import {
  ReportsData,
  SummarySettingsData,
  DayReportsData,
  WeekReportsData,
  MonthReportsData,
  CrowdCapacityData,
  CrowdCapacityOptions,
  SectionOptions,
  Sections,
  CumulativeCountsData,
} from "./@types"
import { Dependencies } from "../../ReduxProvider"
import { tenantActionsAsync } from "../tenantReducer"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */

export type ZoneOption = { label: string; key: string; i: number }

export interface SummaryReportsState {
  data: {
    day: {
      // [projectId: string]: {
      //   [startdate: string]: DayReportsData
      // }
      [projectId: string]: DayReportsData
    },
    week: {
      // [projectId: string]: {
      //   [startdate: string]: WeekReportsData
      // }
      [projectId: string]: WeekReportsData
    },
    month: {
      // [projectId: string]: {
      //   [startdate: string]: MonthReportsData
      // }
      [projectId: string]: MonthReportsData
    },
    metric: Sections
  },
  summaryOptions: SummarySettingsData,
}
export interface CrowdCapacityReportsState {
  data: CrowdCapacityData
  crowdCapacityOptions: CrowdCapacityOptions
  selectedReportId: string
}
export interface CumulativeCountsReportsState {
  data: CumulativeCountsData[]
}

export interface ReportsState {
  year: number
  month: number
  data: {
    [projectId: string]: { [period: string]: { [zoneId: string]: ReportsData } }
  }
  zoneOptions: ZoneOption[]
  selectedTime: number
  summaryReports: SummaryReportsState
  crowdCapacityReports: CrowdCapacityReportsState
  cumulativeCountsReports: CumulativeCountsReportsState
  optionsOpen: boolean
}

export const initialReportsState: ReportsState = {
  year: 2020, //Default
  month: 1, //Default
  data: {},
  zoneOptions: [],
  selectedTime: 0,
  summaryReports: {
    data: {
      day: {},
      week: {},
      month: {},
      metric: {
        p: true,
        h: true,
        d: true,
        f: true,
        m: true,
        s: false,
      },
    },
    summaryOptions: {
      timescale: "d",
      startDate: new Date(new Date().setHours(-24,0,0,0)), // Start of yesterday
      hourStart: 0,
      hourEnd: 24,
      projectIds: {},
      sections: {
        p: true,
        h: true,
        d: true,
        f: true,
        m: true,
        s: false,
      },
      notifications: false,
      alerts: false,
      graphs: true,
      graphOptions: {
        average: false,
        max: true,
      },
      tables: false,
    },
  },
  crowdCapacityReports: {
    data: {},
    crowdCapacityOptions: {
      date: new Date(new Date().setHours(-24,0,0,0)), // Start of yesterday
      interval: 15
    },
    selectedReportId: "",
  },
  cumulativeCountsReports: {
    data: []
  },
  optionsOpen: true
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const reportActions = {
  setMonth: createAction("@report/setMonth", (month: number) => month)(),
  setYear: createAction("@report/setYear", (year: number) => year)(),
  setZoneOptions: createAction(
    "@report/setZoneOptions",
    (options: ZoneOption[]) => options
  )(),
  setSelectedTime: createAction(
    "@report/setSelectedTime",
    (timestamp: number) => timestamp
  )(),
  toggleOptions: createAction(
    "@report/toggleOptions",
    (optionsOpen: boolean) => optionsOpen
  )(),
  saveSummaryOptions: createAction(
    "report/saveSummaryOptions",
    (summarySettingsData: SummarySettingsData) => summarySettingsData
  )(),
  setCrowdCapacityOptions: createAction(
    "report/setCrowdCapacityOptions",
    (crowdCapacityOptions: CrowdCapacityOptions) => crowdCapacityOptions
  )(),
  setSelectedCrowdCapacityReportId: createAction(
    "report/setSelectedCrowdCapacityReportId",
    (reportId: string) => reportId
  )(),
  clearSummaryReportData: createAction("report/clearSummaryReportData")(),
  clearCumulativeCountsReportData: createAction("report/clearCumulativeCountsReportData")(),
  resetSummaryReportOptions: createAction(
    "report/resetSummaryReportOptions",
    (startDate?: Date) => startDate
  )(),
  clearCrowdCapacityReportData: createAction("report/clearCrowdCapacityReportData")(),
  resetCrowdCapacityReportOptions: createAction("report/resetCrowdCapacityReportOptions")(),
}
export const reportActionsAsync = {
  fetchReport: createAsyncAction(
    "@report/fetchReport/req",
    "@report/fetchReport/res",
    "@report/fetchReport/err"
  )<
    {
      month: string
      year: string
    },
    {
      month: string
      year: string
      projectId: string
      data: { [zoneId: string]: ReportsData }
    },
    { error: Error }
  >(),
  getDayReport: createAsyncAction(
    "@report/getDayReport/req",
    "@report/getDayReport/res",
    "@report/getDayReport/err"
  )<
    {
      startdate: string,
      enddate?: string,
      offset: string,
      zoneIds: string[],
      calibrationIds: string[]
      hourStart: number,
      hourEnd: number,
      sections: SectionOptions,
    },
    {
      data: DayReportsData,
      startdate: string,
      projectId: string,
    },
    { error: Error }
  >(),
  getWeekReport: createAsyncAction(
    "@report/getWeekReport/req",
    "@report/getWeekReport/res",
    "@report/getWeekReport/err"
  )<
    {
      startdate: string,
      offset: string,
      zoneIds: string[],
      calibrationIds: string[]
      hourStart: number,
      hourEnd: number,
      sections: SectionOptions,
    },
    {
      data: WeekReportsData
      startdate: string,
      projectId: string,
    },
    { error: Error }
  >(),
  getMonthReport: createAsyncAction(
    "@report/getMonthReport/req",
    "@report/getMonthReport/res",
    "@report/getMonthReport/err"
  )<
    {
      startdate: string,
      offset: string,
      zoneIds: string[],
      calibrationIds: string[]
      hourStart: number,
      hourEnd: number,
      sections: SectionOptions,
    },
    {
      data: MonthReportsData
      startdate: string,
      projectId: string,
    },
    { error: Error }
  >(),
  hasMetricData: createAsyncAction(
    "@report/hasMetricData/req",
    "@report/hasMetricData/res",
    "@report/hasMetricData/err"
  )<
    {},
    {
      data: {
        density: boolean,
        flow: boolean,
        mood: boolean,
        socialdistance: boolean
      },
    },
    { error: Error }
  >(),
  getCrowdCapacityData: createAsyncAction(
    "@report/getCrowdCapacityData/req",
    "@report/getCrowdCapacityData/res",
    "@report/getCrowdCapacityData/err"
  )<
    {
      startdate: string,
      enddate: string,
      interval: number
    },
    {
      data: CrowdCapacityData
    },
    { error: Error }
  >(),
  createCrowdCapacityData: createAsyncAction(
    "@report/createCrowdCapacityData/req",
    "@report/createCrowdCapacityData/res",
    "@report/createCrowdCapacityData/err"
  )<
    {
      startdate: string,
      enddate: string
    },
    {
      data: CrowdCapacityData
    },
    { error: Error }
  >(),
  getCumulativeCountsData: createAsyncAction(
    "@report/getCumulativeCountsData/req",
    "@report/getCumulativeCountsData/res",
    "@report/getCumulativeCountsData/err"
  )<
    {
      startdate: string,
      enddate: string,
      interval: number,
      calibrationIds: string[]
    },
    {
      data: CumulativeCountsData[]
    },
    { error: Error }
  >(),
}

type ValueOf<T> = T[keyof T]
export type ReportAction =
  | ReturnType<ValueOf<typeof reportActions>>
  | ReturnType<ValueOf<ValueOf<typeof reportActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */

export const reportsReducer = produce(
  (draft: Draft<ReportsState>, action: RootAction) => {
    switch (action.type) {
      case getType(reportActions.setYear):
        {
          draft.year = action.payload
          draft.selectedTime = 0 //Reset on change
        }
        return
      case getType(reportActions.setMonth):
        {
          draft.month = action.payload
          draft.selectedTime = 0 //Reset on change
        }
        return
      case getType(reportActions.setZoneOptions):
        {
          draft.zoneOptions = action.payload
        }
        return
      case getType(reportActions.setSelectedTime):
        {
          draft.selectedTime = action.payload
        }
        return
      case getType(reportActions.toggleOptions):
        {
          draft.optionsOpen = action.payload
        }
        return
      case getType(reportActions.saveSummaryOptions):
        {
          draft.summaryReports.summaryOptions = action.payload
        }
        return
      case getType(reportActions.setCrowdCapacityOptions):
        {
          draft.crowdCapacityReports.crowdCapacityOptions = action.payload
        }
        return
      case getType(reportActions.setSelectedCrowdCapacityReportId):
        {
          draft.crowdCapacityReports.selectedReportId = action.payload
        }
        return
      case getType(reportActions.clearSummaryReportData):
        {
          draft.summaryReports.data.day = {}
          draft.summaryReports.data.week = {}
          draft.summaryReports.data.month = {}
        }
        return
      case getType(reportActions.resetSummaryReportOptions):
        {
          const startDate = action.payload;
          draft.summaryReports.summaryOptions = {
            ...initialReportsState.summaryReports.summaryOptions,
            ...(startDate && { startDate })
          }
        }
        return
      case getType(reportActions.clearCrowdCapacityReportData):
        {
          draft.crowdCapacityReports.data = {}
        }
        return
      case getType(reportActions.clearCumulativeCountsReportData):
        {
          draft.cumulativeCountsReports.data = []
        }
        return
      case getType(reportActions.resetCrowdCapacityReportOptions):
        {
          draft.crowdCapacityReports.crowdCapacityOptions =
            initialReportsState.crowdCapacityReports.crowdCapacityOptions
        }
        return
      case getType(reportActionsAsync.fetchReport.success):
        {
          const { projectId, data, year, month } = action.payload
          //Set data
          if (!draft.data[projectId]) draft.data[projectId] = {}
          draft.data[projectId][`${year}-${month}`] = data
        }
        return
      case getType(reportActionsAsync.getDayReport.success):
        {
          const { data, startdate, projectId } = action.payload
          // if (!draft.summaryReports.data.day[projectId])
          //   draft.summaryReports.data.day[projectId] = {}
          // let startDate = new Date(startdate);
          // startDate.setHours(0, 0, 0, 0);
          draft.summaryReports.data.day[projectId] = data
          // draft.summaryReports.data.day[projectId][startdate] = data
        }
        return
      case getType(reportActionsAsync.getWeekReport.success):
        {
          const { data, startdate, projectId } = action.payload
          // if (!draft.summaryReports.data.week[projectId])
          //   draft.summaryReports.data.week[projectId] = {}
          draft.summaryReports.data.week[projectId] = data
        }
        return
      case getType(reportActionsAsync.getMonthReport.success):
        {
          const { data, startdate, projectId } = action.payload
          // if (!draft.summaryReports.data.month[projectId])
          //   draft.summaryReports.data.month[projectId] = {}
          draft.summaryReports.data.month[projectId] = data
        }
        return
      case getType(reportActionsAsync.hasMetricData.success):
        {
          const { data } = action.payload
          draft.summaryReports.data.metric = {
            p: data?.density,
            h: data?.density,
            d: data?.density,
            f: data?.flow,
            m: data?.mood,
            // s: data?.socialdistance,
            s: false,
          }
        }
        return
      case getType(reportActionsAsync.hasMetricData.failure):
        {
          draft.summaryReports.data.metric = {
            p: true,
            h: true,
            d: true,
            f: true,
            m: true,
            s: false,
          }
        }
        return
      case getType(reportActionsAsync.getCrowdCapacityData.request):
        {
          draft.crowdCapacityReports.data = {}
        }
        return
      case getType(reportActionsAsync.getCrowdCapacityData.success):
        {
          const { data } = action.payload
          draft.crowdCapacityReports.data = data
        }
        return
      case getType(reportActionsAsync.createCrowdCapacityData.request):
        {
          draft.crowdCapacityReports.data = {}
        }
        return
      case getType(reportActionsAsync.createCrowdCapacityData.success):
        {
          const { data } = action.payload
          draft.crowdCapacityReports.data = data
        }
        return
      case getType(reportActionsAsync.getCumulativeCountsData.success):
        {
          const { data } = action.payload
          draft.cumulativeCountsReports.data = data
        }
        return
      case getType(tenantActionsAsync.selectProject.success):
        {
          //Reset reports data on project change
          const { projectId } = action.payload
          Object.keys(draft.data).forEach(id => {
            if (id !== projectId) {
              delete draft.data[id]
            }
          })
          draft.zoneOptions = []
          draft.crowdCapacityReports.data = {}; // Clear crowd capacity report data
        }
        return
    }
  },
  initialReportsState
)

export default reportsReducer

export const fetchReportEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(reportActionsAsync.fetchReport.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsUrl } = state.constants
        const { month, year } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function fetchReport() {
          const token = client ? await client.getTokenSilently() : ""
          const data = await dependencies.reportsAPI.fetchReports({
            projectId,
            month,
            year,
            reportsUrl,
            token,
          })

          observer.next(
            reportActionsAsync.fetchReport.success({
              projectId,
              year,
              month,
              data,
            })
          )
        }
        fetchReport().catch(error =>
          observer.next(reportActionsAsync.fetchReport.failure({ error }))
        )
      })
    })
  )
}

export const getDayReportEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(reportActionsAsync.getDayReport.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsApiGatewayUrl } = state.constants
        const { startdate, enddate, offset, zoneIds, calibrationIds, hourStart, hourEnd, sections } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function getDayReport() {
          const token = client ? await client.getTokenSilently() : ""
          const { data } = !enddate ? await dependencies.reportsAPI.getDayReports({
            reportsApiGatewayUrl,
            startdate,
            offset,
            hourStart,
            hourEnd,
            projectId,
            zoneIds,
            calibrationIds,
            sections,
            token,
          }) : await dependencies.reportsAPI.getDayWindowReports({
            reportsApiGatewayUrl,
            startdate,
            enddate,
            offset,
            hourStart,
            hourEnd,
            projectId,
            zoneIds,
            calibrationIds,
            sections,
            token,
          })  

          observer.next(
            reportActionsAsync.getDayReport.success({
              data,
              startdate,
              projectId,
            })
          )
        }
        getDayReport().catch(error =>
          observer.next(reportActionsAsync.getDayReport.failure({ error }))
        )
      })
    })
  )
}

export const getWeekReportEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(reportActionsAsync.getWeekReport.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsApiGatewayUrl } = state.constants
        const { startdate, offset, zoneIds,calibrationIds, hourStart, hourEnd, sections } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function getWeekReport() {
          const token = client ? await client.getTokenSilently() : ""
          const { data } = await dependencies.reportsAPI.getWeekReports({
            reportsApiGatewayUrl,
            startdate,
            offset,
            hourStart,
            hourEnd,
            projectId,
            zoneIds,
            calibrationIds,
            sections,
            token,
          })

          observer.next(
            reportActionsAsync.getWeekReport.success({
              data,
              startdate,
              projectId,
            })
          )
        }
        getWeekReport().catch(error =>
          observer.next(reportActionsAsync.getWeekReport.failure({ error }))
        )
      })
    })
  )
}

export const getMonthReportEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(reportActionsAsync.getMonthReport.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsApiGatewayUrl } = state.constants
        const { startdate, offset, zoneIds, calibrationIds, hourStart, hourEnd, sections } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function getMonthReport() {
          const token = client ? await client.getTokenSilently() : ""
          const { data } = await dependencies.reportsAPI.getMonthReports({
            reportsApiGatewayUrl,
            startdate,
            offset,
            hourStart,
            hourEnd,
            projectId,
            zoneIds,
            calibrationIds,
            sections,
            token,
          })

          observer.next(
            reportActionsAsync.getMonthReport.success({
              data,
              startdate,
              projectId,
            })
          )
        }
        getMonthReport().catch(error =>
          observer.next(reportActionsAsync.getMonthReport.failure({ error }))
        )
      })
    })
  )
}

export const getCrowdCapacityEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(reportActionsAsync.getCrowdCapacityData.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsApiGatewayUrl } = state.constants
        const { startdate, enddate, interval } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const reportId = state.reports.crowdCapacityReports.selectedReportId

        async function getCrowdCapacityData() {
          const token = client ? await client.getTokenSilently() : ""
          const { data } = await dependencies.reportsAPI.getCrowdCapacityData({
            reportsApiGatewayUrl,
            startdate,
            enddate,
            projectId,
            reportId,
            interval,
            token,
          })

          observer.next(
            reportActionsAsync.getCrowdCapacityData.success({
              data,
            })
          )
        }
        getCrowdCapacityData().catch(error =>
          observer.next(reportActionsAsync.getCrowdCapacityData.failure({ error }))
        )
      })
    })
  )
}

export const createCrowdCapacityEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(reportActionsAsync.createCrowdCapacityData.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsApiGatewayUrl } = state.constants
        const { startdate, enddate } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const reportId = state.reports.crowdCapacityReports.selectedReportId

        async function createCrowdCapacityData() {
          const token = client ? await client.getTokenSilently() : ""
          const { data } = await dependencies.reportsAPI.createCrowdCapacityData({
            reportsApiGatewayUrl,
            startdate,
            enddate,
            projectId,
            reportId,
            token,
          })

          observer.next(
            reportActionsAsync.createCrowdCapacityData.success({
              data,
            })
          )
        }
        createCrowdCapacityData().catch(error =>
          observer.next(reportActionsAsync.createCrowdCapacityData.failure({ error }))
        )
      })
    })
  )
}

export const hasMetricDataEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.selectProject.success)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsApiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function createCrowdCapacityData() {
          const token = client ? await client.getTokenSilently() : ""
          const { data } = await dependencies.reportsAPI.hasMetricData({
            reportsApiGatewayUrl,
            projectId,
            token,
          })

          observer.next(
            reportActionsAsync.hasMetricData.success({
              data,
            })
          )
        }
        createCrowdCapacityData().catch(error =>
          observer.next(reportActionsAsync.hasMetricData.failure({ error }))
        )
      })
    })
  )
}

export const getCumulativeCountsEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(reportActionsAsync.getCumulativeCountsData.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { reportsApiGatewayUrl } = state.constants
        const { startdate, enddate, interval, calibrationIds } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function getCumulativeCountsData() {
          const token = client ? await client.getTokenSilently() : ""
          const { data } = await dependencies.reportsAPI.getCumulativeCountsData({
            reportsApiGatewayUrl,
            startdate,
            enddate,
            projectId,
            calibrationIds,
            interval,
            token,
          })

          // console.log("DATA", data, JSON.stringify(data));
          observer.next(
            reportActionsAsync.getCumulativeCountsData.success({
              data,
            })
          )
        }
        getCumulativeCountsData().catch(error =>
          observer.next(reportActionsAsync.getCumulativeCountsData.failure({ error }))
        )
      })
    })
  )
}