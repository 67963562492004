import axios from "axios"
import { IMeasurement } from "../../@types/IMeasurement"

export const dataAPI = {
  getCountsByDay: async (input: {
    apiGatewayUrl: string
    selectedProjectId: string
    token: string
  }) => {
    const { apiGatewayUrl, selectedProjectId, token } = input
    const res = await axios.get(
      `${apiGatewayUrl}/countsByDay/${selectedProjectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as { [ts: string]: number }
  },
  getMeasurements: async (input: {
    token: string
    apiGatewayUrl: string
    interpolate: boolean
    complianceCutoff: number
    start: Date
    end: Date
    projectId: string
    deploymentIds: number[]
  }) => {
    const {
      token,
      apiGatewayUrl,
      interpolate,
      complianceCutoff,
      start,
      end,
      deploymentIds,
      projectId,
    } = input
    const res = await axios.get(
      `${apiGatewayUrl}/measurements/?${
        interpolate ? "interpolate=true&" : ""
      }limit=${complianceCutoff}&start=${start.toISOString()}&end=${end.toISOString()}&projectId=${projectId}&deploymentIds=${JSON.stringify(
        deploymentIds
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as IMeasurement
  },
}
