import { produce, Draft } from "immer"
import { Epic, ofType } from "redux-observable"
import { IState, RootAction } from "."
import { Observable, timer } from "rxjs"
import { switchMap, takeUntil, tap, filter } from "rxjs/operators"
import { navigate } from "@reach/router"
import { EnvState } from "./networkSetupReducerV2"
import sleep from "sleep-promise"
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import { tenantActionsAsync } from "./tenantReducer"
import { Dependencies } from "../ReduxProvider"
import { getToastByErrCode } from "../../components/Alerts/getToastByErrCode"
import { getToastByType } from "../../components/Alerts/getToastByType"

//This reducer is for storing the states related to the Settings - Project page

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export type UnitType = "imperial" | "metric"
export type ProjectSelectMode = "" | "edit" | "members" | "network"
export interface ProjectState {
  flyoutOpen: boolean
  deleteOpen: boolean //For the delete menu in flyout
  advancedOpen: boolean //For advanced config inside forms
  networkOpen: boolean
  networkDeleteOpen: boolean //For delete menu in network form
  scheduleModalOpen: boolean // For schedule modal in operation
  createScheduleModalOpen: boolean // For create schedule modal in operation
  revertOpen: "" | "floorplan" | "camera" | "calibrate" | "detailed_calibrate" //For the revert changes menu in flyout, also specifying type
  searchTerm: string
  mode: "create" | "edit"
  type: "s" | "z" | "d" | "c"
  selectedSite: string //siteId
  selectedZone: string //zoneId
  selectedDeployment: string //deploymentId
  selectedCalibration: string //calibrationId
  mapCenter: [number, number] //Lon,lat of chosen map center
  devicePinging: boolean //For testing connection to device
  devicePingStatus: "" | "success" | "fail"
  devicePingMessageTitle: string
  devicePingMessageText: React.ReactChild
  deploymentVerification: "" | "success" | "fail"
  infoTypeOpen: string //For moreInfo modals
  //For organisation users setup logic
  userSortField: string
  userSortDirection: "asc" | "desc"
  userDetailsTab: "details" | "projects"
  userSelectMode: "" | "edit"
  userDeleteOpen: boolean
  userResetPasswordOpen: boolean
  userActionOpen: boolean
  userPasswordChange: string
  userPasswordChangeConfirm: string
  //For organisation project setup logic
  projectSortField: string
  projectSortDirection: "asc" | "desc"
  projectDetailsTab: "details" | "members" | "network" | "operation" | "vms"
  projectDeleteOpen: boolean
  projectActionOpen: boolean
  //For checking deployment statuses
  deploymentStatuses: {
    [deploymentId: string]: {
      cameraDriverStatus: EnvState
      networkCheckStatus: boolean
    }
  }
}

export const initialProjectState: ProjectState = {
  flyoutOpen: false,
  deleteOpen: false,
  advancedOpen: false,
  networkOpen: false,
  networkDeleteOpen: false,
  scheduleModalOpen: false,
  createScheduleModalOpen: false,
  revertOpen: "",
  searchTerm: "",
  mode: "create",
  type: "s",
  selectedSite: "",
  selectedZone: "",
  selectedDeployment: "",
  selectedCalibration: "",
  mapCenter: null,
  devicePinging: false,
  devicePingStatus: "",
  devicePingMessageTitle: "",
  devicePingMessageText: "",
  deploymentVerification: "",
  infoTypeOpen: "",
  //For organisation users setup logic
  userSortField: "",
  userSortDirection: "asc",
  userDetailsTab: "details",
  userSelectMode: "",
  userDeleteOpen: false,
  userResetPasswordOpen: false,
  userActionOpen: false,
  userPasswordChange: "",
  userPasswordChangeConfirm: "",
  //For organisation project setup logic
  projectSortField: "",
  projectSortDirection: "asc",
  projectDetailsTab: "details",
  projectDeleteOpen: false,
  projectActionOpen: false,
  deploymentStatuses: {},
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const projectActions = {
  handleChange: createAction(
    "@project/handleChange",
    (field: string, value: ValueOf<ProjectState>) => ({ field, value })
  )(),
  toggleFlyout: createAction(
    "@project/toggleFlyout",
    (flyoutOpen: boolean) => flyoutOpen
  )(),
  toggleDelete: createAction(
    "@project/toggleDelete",
    (deleteOpen: boolean) => deleteOpen
  )(),
  toggleAdvanced: createAction(
    "@project/toggleAdvanced",
    (advancedOpen: boolean) => advancedOpen
  )(),
  toggleNetwork: createAction(
    "@project/toggleNetwork",
    (networkOpen: boolean) => networkOpen
  )(),
  toggleNetworkDelete: createAction(
    "@project/toggleNetworkDelete",
    (networkDeleteOpen: boolean) => networkDeleteOpen
  )(),
  toggleScheduleModal: createAction(
    "@project/toggleScheduleModal",
    (scheduleModalOpen: boolean) => scheduleModalOpen
  )(),
  toggleCreateScheduleModal: createAction(
    "@project/toggleCreateScheduleModal",
    (createScheduleModalOpen: boolean) => createScheduleModalOpen
  )(),
  setRevert: createAction(
    "@project/setRevert",
    (
      revertOpen:
        | ""
        | "floorplan"
        | "camera"
        | "calibrate"
        | "detailed_calibrate"
    ) => revertOpen
  )(),
  setSearch: createAction(
    "@project/setSearch",
    (searchTerm: string) => searchTerm
  )(),
  setInfoType: createAction(
    "@project/setInfoType",
    (infoTypeOpen: string) => infoTypeOpen
  )(),
  toggleProjectsTab: createAction(
    "@project/toggleProjectsTab",
    (projectDetailsTab: "details" | "members" | "network" | "operation") => projectDetailsTab
  )(),
  toggleUsersTab: createAction(
    "@project/toggleUsersTab",
    (userDetailsTab: "details" | "projects") => userDetailsTab
  )(),
  //? These four actions below are currently not used
  setProjectSortField: createAction(
    "@project/setProjectSortField",
    (projectSortField: string) => projectSortField
  )(),
  setProjectSortDirection: createAction(
    "@project/setProjectSortDirection",
    (projectSortDirection: "asc" | "desc") => projectSortDirection
  )(),
  setUserSortField: createAction(
    "@project/setUserSortField",
    (userSortField: string) => userSortField
  )(),
  setUserSortDirection: createAction(
    "@project/setUserSortDirection",
    (userSortDirection: "asc" | "desc") => userSortDirection
  )(),
  toggleUserDelete: createAction(
    "@project/toggleUserDelete",
    (userDeleteOpen: boolean) => userDeleteOpen
  )(),
  toggleUserReset: createAction(
    "@project/toggleUserReset",
    (userResetPasswordOpen: boolean) => userResetPasswordOpen
  )(),
  toggleUserAction: createAction(
    "@project/toggleUserAction",
    (userActionOpen: boolean) => userActionOpen
  )(),
  toggleProjectDeleteOpen: createAction(
    "@project/toggleProjectDeleteOpen",
    (projectDeleteOpen: boolean) => projectDeleteOpen
  )(),
  toggleProjectActionOpen: createAction(
    "@project/toggleProjectActionOpen",
    (projectActionOpen: boolean) => projectActionOpen
  )(),
  selectSite: createAction(
    "@project/selectSite",
    (i: { selectedSite: string; center?: [number, number] }) => ({
      selectedSite: i.selectedSite,
      center: i.center,
    })
  )(),
  selectZone: createAction(
    "@project/selectZone",
    (i: { selectedZone: string; center?: [number, number] }) => ({
      selectedZone: i.selectedZone,
      center: i.center,
    })
  )(),
  selectDeployment: createAction(
    "@project/selectDeployment",
    (i: {
      selectedSite?: string
      selectedZone?: string
      selectedDeployment: string
      center?: [number, number]
    }) => ({
      selectedSite: i.selectedSite,
      selectedZone: i.selectedZone,
      selectedDeployment: i.selectedDeployment,
      center: i.center,
    })
  )(),
  selectedCalibration: createAction(
    "@project/selectedCalibration",
    (i: { selectedCalibration: string; center?: [number, number] }) => ({
      selectedCalibration: i.selectedCalibration,
      center: i.center,
    })
  )(),
  createMode: createAction(
    "@project/createMode",
    (i: { type: "s" | "z" | "d" | "c" }) => ({
      type: i.type,
    })
  )(),
  editMode: createAction(
    "@project/editMode",
    (i: { type: "s" | "z" | "d" | "c" }) => ({
      type: i.type,
    })
  )(),
  endPollStatuses: createAction("@project/endPollStatuses")(),
  endPollStatus: createAction("@project/endPollStatus")(),
}
export const projectActionsAsync = {
  pollStatuses: createAsyncAction(
    "@project/pollStatuses/req",
    "@project/pollStatuses/res",
    "@project/pollStatuses/err"
  )<
    {},
    {
      deploymentStatuses: {
        [deploymentId: string]: {
          cameraDriverStatus: EnvState
          networkCheckStatus: boolean
        }
      }
    },
    { error: Error; message: string; code: string }
  >(),
  pollStatus: createAsyncAction(
    "@project/pollStatus/req",
    "@project/pollStatus/res",
    "@project/pollStatus/err"
  )<
    {},
    {
      status: {
        cameraDriverStatus: any
        networkCheckStatus: boolean
      }
      deploymentId: string
    },
    { error: Error; message: string; code: string }
  >(),
  pingDevice: createAsyncAction(
    "@project/pingDevice/req",
    "@project/pingDevice/res",
    "@project/pingDevice/err"
  )<
    {
      host: string
      port: string
      username: string
      password: string
      devicetype: string
      rtsplink: string
      portrtsp: string
      portforwarded: string
      portforwardedrtsp: string
    },
    {},
    { error: Error; message: string; code: string }
  >(),
  createCameraSetup: createAsyncAction(
    "@project/createCameraSetup/req",
    "@project/createCameraSetup/res",
    "@project/createCameraSetup/err"
  )<{ projectId: string; deploymentId: string }, {}, { error: Error }>(),
  deleteCameraSetup: createAsyncAction(
    "@project/deleteCameraSetup/req",
    "@project/deleteCameraSetup/res",
    "@project/deleteCameraSetup/err"
  )<{ projectId: string; deploymentId: string }, {}, { error: Error }>(),
}

type ValueOf<T> = T[keyof T]
export type ProjectAction =
  | ReturnType<ValueOf<typeof projectActions>>
  | ReturnType<ValueOf<ValueOf<typeof projectActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */
export const projectReducer = produce(
  (draft: Draft<ProjectState>, action: RootAction) => {
    switch (action.type) {
      //For changing state fields
      case getType(projectActions.handleChange): {
        const { field, value } = action.payload
        draft[field] = value
        return
      }
      case getType(projectActions.toggleFlyout):
        draft.flyoutOpen = !!action.payload
        draft.advancedOpen = false
        draft.devicePinging = false //Reset
        draft.devicePingStatus = "" //Reset
        draft.deploymentVerification = "" //Reset
        return
      case getType(projectActions.toggleDelete):
        draft.deleteOpen = !!action.payload
        return
      case getType(projectActions.toggleAdvanced):
        draft.advancedOpen = !!action.payload
        return
      case getType(projectActions.toggleNetwork):
        draft.networkOpen = !!action.payload
        return
      case getType(projectActions.toggleNetworkDelete):
        draft.networkDeleteOpen = !!action.payload
        return
      case getType(projectActions.toggleScheduleModal):
        draft.scheduleModalOpen = !!action.payload
        return
      case getType(projectActions.toggleCreateScheduleModal):
        draft.createScheduleModalOpen = !!action.payload
        return
      case getType(projectActions.setRevert):
        draft.revertOpen = action.payload
        return
      case getType(projectActions.setSearch):
        draft.searchTerm = action.payload
        return
      case getType(projectActions.setInfoType):
        draft.infoTypeOpen = action.payload
        return
      case getType(projectActions.selectSite):
        draft.selectedSite = action.payload.selectedSite
        draft.selectedZone = ""
        draft.selectedDeployment = ""
        draft.selectedCalibration = ""
        draft.mapCenter = action.payload.center
        draft.flyoutOpen = false
        draft.advancedOpen = false
        draft.deploymentVerification = ""

        return
      case getType(projectActions.selectZone):
        draft.selectedZone = action.payload.selectedZone
        draft.selectedDeployment = ""
        draft.selectedCalibration = ""
        draft.mapCenter = action.payload.center
        draft.flyoutOpen = false
        draft.advancedOpen = false
        draft.deploymentVerification = ""
        return
      case getType(projectActions.selectDeployment):
        {
          const s = action.payload.selectedSite
          const z = action.payload.selectedZone
          const d = action.payload.selectedDeployment
          if (s) draft.selectedSite = s
          if (z) draft.selectedZone = z
          if (d) draft.selectedDeployment = d
          draft.selectedCalibration = ""
          draft.mapCenter = action.payload.center
          draft.flyoutOpen = false
          draft.advancedOpen = false
          draft.devicePingStatus = "" // Clear ping status
          draft.deploymentVerification = ""
        }
        return
      case getType(projectActions.selectedCalibration):
        draft.selectedCalibration = action.payload.selectedCalibration
        draft.flyoutOpen = false
        draft.advancedOpen = false
        draft.deploymentVerification = ""
        return
      case getType(projectActions.toggleProjectsTab):
        draft.projectDetailsTab = action.payload
        return
      case getType(projectActions.toggleUsersTab):
        draft.userDetailsTab = action.payload
        return
      //?NOTE: CURRENTLY SORTING IS NOT USED
      case getType(projectActions.setProjectSortField):
        draft.projectSortField = action.payload
        return
      case getType(projectActions.setProjectSortDirection):
        draft.projectSortDirection = action.payload
        return
      case getType(projectActions.setUserSortField):
        draft.userSortField = action.payload
        return
      case getType(projectActions.setUserSortDirection):
        draft.userSortDirection = action.payload
        return
      case getType(projectActions.toggleUserDelete):
        draft.userDeleteOpen = action.payload
        return
      case getType(projectActions.toggleUserReset):
        draft.userResetPasswordOpen = action.payload
        return
      case getType(projectActions.toggleUserAction):
        draft.userActionOpen = action.payload
        return
      case getType(projectActions.toggleProjectDeleteOpen):
        draft.projectDeleteOpen = action.payload
        return
      case getType(projectActions.toggleProjectActionOpen):
        draft.projectActionOpen = action.payload
        return
      case getType(projectActions.createMode):
        {
          const type = action.payload.type
          draft.flyoutOpen = true
          draft.mode = "create"
          draft.type = type || "s" //For safety
          if (type === "s") {
            draft.selectedSite = ""
            draft.selectedZone = ""
            draft.selectedDeployment = ""
          }
          if (type === "z") {
            draft.selectedZone = ""
            draft.selectedDeployment = ""
          }
          if (type === "d") {
            draft.selectedDeployment = ""
          }
        }
        return
      case getType(projectActions.editMode):
        draft.flyoutOpen = true
        draft.mode = "edit"
        draft.type = action.payload.type || "s" //For safety
        return
      case getType(tenantActionsAsync.selectProject.success):
        //Reset selected on project change
        draft.selectedSite = ""
        draft.selectedZone = ""
        draft.selectedDeployment = ""
        draft.selectedCalibration = ""
        draft.flyoutOpen = false
        draft.revertOpen = ""
        draft.deleteOpen = false
        draft.mapCenter = null
        draft.deploymentStatuses = {}
        return
      case getType(tenantActionsAsync.createSiteOverlay.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on site create save
        return
      case getType(tenantActionsAsync.updateSiteOverlay.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on site edit save
        return
      case getType(tenantActionsAsync.updateSite.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on site edit save
        return
      case getType(tenantActionsAsync.deleteSite.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on site edit delete
        draft.deleteOpen = false
        return
      case getType(tenantActionsAsync.createZone.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on zone edit save
        return
      case getType(tenantActionsAsync.updateZone.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on zone edit save
        return
      case getType(tenantActionsAsync.deleteZone.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on zone edit save
        draft.deleteOpen = false
        return
      case getType(tenantActionsAsync.updateDeployment.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on deployment edit save
        return
      case getType(tenantActionsAsync.createDeploymentCombined.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on deployment edit save
        return
      case getType(tenantActionsAsync.updateDeploymentCombined.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on deployment edit save
        return
      case getType(tenantActionsAsync.deleteDeploymentCombined.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on deployment edit save
        draft.deleteOpen = false
        return
      case getType(tenantActionsAsync.deleteDeployment.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on deployment edit save
        draft.deleteOpen = false
        return
      case getType(tenantActionsAsync.verifyDeployment.success):
        draft.deploymentVerification = "success"
        return
      case getType(tenantActionsAsync.verifyDeployment.failure):
        draft.deploymentVerification = "fail"
        return
      case getType(projectActionsAsync.pollStatuses.success):
        draft.deploymentStatuses = action.payload.deploymentStatuses
        return
      case getType(projectActionsAsync.pollStatus.success):
        {
          const { status, deploymentId } = action.payload
          draft.deploymentStatuses[deploymentId] = status
        }
        return
      case getType(tenantActionsAsync.createCalibration.success):
        //After creating a calibration, directly go into edit calibration
        {
          const res = action.payload.resObj
          if (!res) {
            draft.flyoutOpen = false
            draft.advancedOpen = false
          }
          draft.mode = "edit"
          draft.type = "c"
          draft.flyoutOpen = true
          // draft.selectedSite = "" //? Note: this isnt needed since its already selected
          // draft.selectedZone = "" //? Note: this isnt needed since its already selected
          draft.selectedDeployment = `${res.deploymentId}`
          draft.selectedCalibration = `${res.calibrationId}`
        }
        return
      case getType(tenantActionsAsync.updateCalibration.success):
        draft.revertOpen = "" //Close revert menu
        return
      case getType(tenantActionsAsync.deleteCalibration.success):
        draft.flyoutOpen = false
        draft.advancedOpen = false //Close on calibration delete
        draft.deleteOpen = false
        return
      case getType(projectActionsAsync.pingDevice.request):
        draft.devicePinging = true
        draft.devicePingStatus = ""
        draft.devicePingMessageTitle = ""
        draft.devicePingMessageText = ""
        return
      case getType(projectActionsAsync.pingDevice.success):
        draft.devicePinging = false
        draft.devicePingStatus = "success"
        draft.devicePingMessageTitle = ""
        draft.devicePingMessageText = ""
        return
      case getType(projectActionsAsync.pingDevice.failure):
        {
          const type = getType(projectActionsAsync.pingDevice.failure)
          const { code } = action.payload
          //Grab error message/title and set them for callout display DCM-347
          const toast = getToastByErrCode("", code) || getToastByType("", type)
          const title = (toast.title as string) || ""
          const text = toast.text || ""

          draft.devicePinging = false
          draft.devicePingStatus = "fail"
          draft.devicePingMessageTitle = title
          draft.devicePingMessageText = text
        }
        return

      case getType(tenantActionsAsync.createUser.success):
      case getType(tenantActionsAsync.inviteUser.success):
        {
          //@ts-ignore #0f0 - need to change on tenant side
          const userId = action.payload.userId || ""
          if (typeof window === "undefined") return
          navigate(`/app/settings/members/${userId}`)
        }
        return
      case getType(tenantActionsAsync.updateUser.success):
        {
          const userId = action.payload.userId || ""
          if (typeof window === "undefined") return
          navigate(`/app/settings/members/${userId}`)
          draft.userResetPasswordOpen = false
        }
        return
      case getType(tenantActionsAsync.deleteUser.success):
        typeof window !== "undefined" && navigate("/app/settings/members/")
        draft.userDeleteOpen = false
        return
      case getType(tenantActionsAsync.createProject.success):
        {
          //After creating: navigate to its detailed view
          const project = action.payload.project
          const projectId = (project && project.projectId) || ""
          typeof window !== "undefined" &&
            navigate(`/app/settings/projects/${projectId}`)
        }
        return
      case getType(tenantActionsAsync.cloneProject.success):
        {
          //After cloning: navigate to its detailed view
          const project = action.payload.project
          const projectId = (project && project.projectId) || ""
          typeof window !== "undefined" &&
            navigate(`/app/settings/projects/${projectId}`)
        }
        return
      case getType(tenantActionsAsync.updateProject.success):
        {
          if (typeof window === "undefined") return
          //Remove hash from window location
          navigate(window.location.href.split("#")[0])
        }
        return
      case getType(tenantActionsAsync.deleteProject.success):
        {
          //Removed nagivation for now -> should still be in the project page after marking for deletion
          // typeof window !== "undefined" && navigate("/app/settings/projects/")
          draft.projectDeleteOpen = false
        }
        return
    }
  },
  initialProjectState
)

export default projectReducer

//EPICS BELOW
/**
 */
export const appPingDeviceEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(projectActionsAsync.pingDevice.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function pingDevice() {
          //1.Extract details from state and payload
          const client = state.auth0.auth0Client
          const projectId = state.tenant.selectedProjectId
          const projects = state.tenant.projects || {}
          const project = projects[projectId]
          const deployments = state.tenant.deployments
          const deployment = deployments[state.project.selectedDeployment]
          const remotehostip = deployment?.remoteHostIp || project.remoteHostIp || ""
          const remotehostname = deployment?.remoteHostName || project.remoteHostName|| "ubuntu"
          const token = client ? await client.getTokenSilently() : ""
          const {
            host,
            port,
            username,
            password,
            devicetype,
            rtsplink,
            portrtsp,
            portforwarded,
            portforwardedrtsp,
          } = action.payload

          const { apiGatewayUrl } = state.constants

          await dependencies.projectAPI.pingDevice({
            token,
            apiGatewayUrl,
            projectId,
            host,
            port,
            username,
            password,
            devicetype,
            rtsplink,
            portrtsp,
            portforwarded,
            portforwardedrtsp,
            remotehostname,
            remotehostip,
          })

          //3.Pass new values to reducer
          observer.next(projectActionsAsync.pingDevice.success({}))
        }
        pingDevice().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            projectActionsAsync.pingDevice.failure({ error, message, code })
          )
        })
      })
    })
  )
}

export const calibrationCaptureMoodImageEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.captureMoodCalibrationImage.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function captureMoodImage() {
          //1.Extract details from state and payload
          const client = state.auth0.auth0Client
          const projectId = state.tenant.selectedProjectId
          const projects = state.tenant.projects || {}
          const project = projects[projectId]
          const token = client ? await client.getTokenSilently() : ""
          const { calibrationId, moodview } = action.payload

          const { apiGatewayUrl } = state.constants

          const resObj = await dependencies.projectAPI.pingCalibration({
            token,
            apiGatewayUrl,
            calibrationId,
            moodview,
            projectId,
          })

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.captureMoodCalibrationImage.success({
              resObj,
            })
          )
        }
        captureMoodImage().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.captureMoodCalibrationImage.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

//Read this for cancellation: https://redux-observable.js.org/docs/recipes/Cancellation.html
const POLL_INTERVAL = 10000 //10s

/**
 * DISABLED -- Too many API calls
 */
export const deploymentStatusesPollEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(projectActionsAsync.pollStatuses.request)),
    switchMap(action => {
      return timer(0, POLL_INTERVAL).pipe(
        switchMap(() => {
          const state = state$.value as IState
          return new Observable<RootAction>(observer => {
            async function poll() {
              const client = state.auth0.auth0Client
              const token = client ? await client.getTokenSilently() : ""

              const { apiGatewayUrl, cameraSetupPath } = state.constants

              //1.For each existing deployment grab statuses
              const deploymentStatuses: {
                [deploymentId: string]: {
                  cameraDriverStatus: EnvState
                  networkCheckStatus: boolean
                }
              } = {}
              const deployments = state.tenant.deployments
              const promises = Object.keys(deployments).map(
                async deploymentId => {
                  const d = deployments[deploymentId]
                  const { projectId } = d

                  const cameraStatus = await dependencies.projectAPI.getCameraDriverStatus({
                    token,
                    apiGatewayUrl,
                    projectId,
                    deploymentId,
                  })

                  const status = {
                    cameraDriverStatus:
                      cameraStatus?.data?.camera_driver_status || "",
                    networkCheckStatus: true, //Just kept as always true
                    // networkCheckStatus: !!networkStatus?.data,
                  }
                  deploymentStatuses[deploymentId] = status
                }
              )
              //wait for all calls
              //Even if one fails, ignore and do the rest
              await Promise.all(
                promises
                // .map(p => p.catch(err => console.log(err)))
              )

              //Return to reducers
              observer.next(
                projectActionsAsync.pollStatuses.success({ deploymentStatuses })
              )
            }
            poll().catch(error => {
              //Check for response && error field
              const message = error?.response?.data?.err
              const code = error?.response?.data?.err_code
              observer.next(
                projectActionsAsync.pollStatuses.failure({
                  error,
                  message,
                  code,
                })
              )
            })
          })
        }),
        takeUntil(action$.pipe(ofType(getType(projectActions.endPollStatuses))))
      )
    })
  )
}

const SINGLE_POLL_INTERVAL = 5000 //5s
export const deploymentStatusPollEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(projectActionsAsync.pollStatus.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        timer(0, SINGLE_POLL_INTERVAL)
          .pipe(
            tap(() => {
              const state = state$.value as IState
              // return new Observable(observer => {
              async function poll() {
                const client = state.auth0.auth0Client
                const token = client ? await client.getTokenSilently() : ""

                const { apiGatewayUrl, cameraSetupPath } = state.constants

                //Grab information from payload
                const deployments = state.tenant.deployments
                const deploymentId = `${action.payload.deploymentId}` as string
                const d = deployments[deploymentId]
                const projectId = d?.projectId || ""

                const cameraStatus = await dependencies.projectAPI.getCameraDriverStatus({
                  token,
                  apiGatewayUrl,
                  projectId,
                  deploymentId,
                })

                const status = {
                  cameraDriverStatus:
                    cameraStatus?.data?.camera_driver_status || "",
                  // networkCheckStatus: !!networkStatus?.data,
                  networkCheckStatus: true,
                }

                //2.Return results to reducer
                observer.next(
                  projectActionsAsync.pollStatus.success({
                    status,
                    deploymentId,
                  })
                )
              }
              poll().catch(error => {
                //Check for response && error field
                const message = error?.response?.data?.err
                const code = error?.response?.data?.err_code
                observer.next(
                  projectActionsAsync.pollStatus.failure({
                    error,
                    message,
                    code,
                  })
                )
              })
              // })
            }),
            takeUntil(
              action$.pipe(ofType(getType(projectActions.endPollStatus)))
            )
          )
          .subscribe()
      })
    })
  )
}

export const cameraSetupCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(projectActionsAsync.createCameraSetup.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function cameraSetupCreate() {
          //1.Extract details from state and payload
          const client = state.auth0.auth0Client
          const token = client ? await client.getTokenSilently() : ""
          const projectId = action.payload.projectId as string
          const deploymentId = `${action.payload.deploymentId || ""}`

          const { apiGatewayUrl } = state.constants

          //2.Call ping api from vpnsetup server
          const res = await dependencies.projectAPI.createCameraSetup({
            token,
            apiGatewayUrl,
            projectId,
            deploymentId,
          })
          // const timer = sleep(15000) //15s wait
          // await Promise.all([res, timer])

          //3.Pass new values to reducer
          observer.next(projectActionsAsync.createCameraSetup.success({}))
        }
        cameraSetupCreate().catch(error =>
          observer.next(
            projectActionsAsync.createCameraSetup.failure({ error })
          )
        )
      })
    })
  )
}

export const cameraSetupDeleteEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(projectActionsAsync.deleteCameraSetup.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function cameraSetupDelete() {
          //1.Extract details from state and payload
          const client = state.auth0.auth0Client
          const token = client ? await client.getTokenSilently() : ""
          const projectId = action.payload.projectId as string
          const deploymentId = `${action.payload.deploymentId || ""}`

          const { apiGatewayUrl } = state.constants

          //2.Call ping api from vpnsetup server
          const res = await dependencies.projectAPI.deleteCameraSetup({
            token,
            apiGatewayUrl,
            projectId,
            deploymentId,
          })
          // const timer = sleep(15000) //15s wait
          // await Promise.all([res, timer])

          //3.Pass new values to reducer
          observer.next(projectActionsAsync.deleteCameraSetup.success({}))
        }
        cameraSetupDelete().catch(error =>
          observer.next(
            projectActionsAsync.deleteCameraSetup.failure({ error })
          )
        )
      })
    })
  )
}
