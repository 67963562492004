import axios from "axios"
import { ICalibration } from "../../@types/ICalibration"

const POLL_INTERVAL = 10000 //10s
const TIMEOUT = 10000 //15s

export const projectAPI = {
  /**
   * Call ping api from vpnsetup server
   */
  pingDevice: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    host: string
    port: string
    username: string
    password: string
    devicetype: string
    rtsplink: string
    portrtsp: string
    portforwarded: string
    portforwardedrtsp: string
    remotehostname: string
    remotehostip: string
  }) => {
    const {
      token,
      apiGatewayUrl,
      projectId,
      host,
      port,
      username,
      password,
      devicetype,
      rtsplink,
      portrtsp,
      portforwarded,
      portforwardedrtsp,
      remotehostname,
      remotehostip,
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/calibrationHelpers/_testconnection`,
      {
        projectId,
        host,
        port,
        username,
        password,
        devicetype,
        rtsplink,
        portrtsp,
        portforwarded,
        portforwardedrtsp,
        remotehostname,
        remotehostip,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        timeout: 10 * 60000,
      }
    )

    if (!res.data) throw new Error("ping failed")
  },
  /**
   *  Call ping api from vpnsetup server
   */
  pingCalibration: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
    moodview: string
  }) => {
    const { token, apiGatewayUrl, projectId, calibrationId, moodview } = input
    const res = await axios.put(
      `${apiGatewayUrl}/calibrationHelpers/calibrationCaptureMoodImage`,
      {
        projectId,
        calibrationId,
        moodview,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        timeout: 10 * 60000,
      }
    )

    if (!res.data) throw new Error("capture failed")
    return res.data as ICalibration
  },
  /**
   * gets the camera driver status
   */

  getCameraDriverStatus: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    deploymentId: string
  }) => {
    const { token, apiGatewayUrl, projectId, deploymentId } = input
    return axios.post(
      `${apiGatewayUrl}/cameraSetupHelpers/status/`,
      {
        projectId,
        deploymentId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  },
  /**
   * Creates a camera setup
   */
  createCameraSetup: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    deploymentId: string
  }) => {
    const { token, apiGatewayUrl, projectId, deploymentId } = input
    return axios.post(
      `${apiGatewayUrl}/cameraSetupHelpers/`,
      {
        projectId,
        deploymentId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  },
  /**
   * deletes a camera setup
   */
  deleteCameraSetup: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    deploymentId: string
  }) => {
    const { token, apiGatewayUrl, projectId, deploymentId } = input
    return axios.delete(`${apiGatewayUrl}/cameraSetupHelpers/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        projectId,
        deploymentId,
      },
    })
  },
}
