import { CalibrationValues, MetricValues, PointerDates } from "../@types"

/**
 * Merge two sets of pointerDates
 * If a pointer date is null or undefined,
 * then it means = pick earliest date possible
 */
export const mergePointerDates = (
  existingPointerDates: PointerDates,
  newPointerDates: PointerDates
): PointerDates => {
  const newData: CalibrationValues<MetricValues<Date>> = {}
  const newHeatmaps: CalibrationValues<MetricValues<Date>> = {}
  const newImages: CalibrationValues<Date> = {}
  const newCompound: CalibrationValues<Date> = {}

  Object.keys(existingPointerDates.data).forEach(calibrationId => {
    const metricValues = existingPointerDates.data[calibrationId]
    const newMetricValues: Partial<MetricValues<Date>> = {}
    Object.keys(metricValues || {}).forEach(m => {
      const metric = m as keyof MetricValues<any>
      const date = metricValues[metric]
      const calibrationDates = newPointerDates.data[calibrationId] || {}
      const newDate = calibrationDates[metric]
      newMetricValues[metric] = newDate ? newDate : date
    })
    newData[calibrationId] = newMetricValues as MetricValues<Date>
  })

  Object.keys(existingPointerDates.heatmaps).forEach(calibrationId => {
    const metricValues = existingPointerDates.heatmaps[calibrationId]
    const newMetricValues: Partial<MetricValues<Date>> = {}
    Object.keys(metricValues || {}).forEach(m => {
      const metric = m as keyof MetricValues<any>
      const date = metricValues[metric]
      const calibrationDates = newPointerDates.heatmaps[calibrationId] || {}
      const newDate = calibrationDates[metric]
      newMetricValues[metric] = newDate ? newDate : date
    })
    newHeatmaps[calibrationId] = newMetricValues as MetricValues<Date>
  })

  Object.keys(existingPointerDates.images).forEach(calibrationId => {
    const date = existingPointerDates.images[calibrationId]
    const newDate = newPointerDates.images[calibrationId]
    newImages[calibrationId] = newDate ? newDate : date
  })
  
  Object.keys(existingPointerDates.compound).forEach(compoundId => {
    const date = existingPointerDates.compound[compoundId]
    const newDate = newPointerDates.compound[compoundId]
    newCompound[compoundId] = newDate ? newDate : date
  })

  return {
    data: newData,
    heatmaps: newHeatmaps,
    images: newImages,
    compound: newCompound,
  }
}
