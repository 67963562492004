import { ICalibration } from "../../../../@types/ICalibration"
import { IDeployment } from "../../../../@types/IDeployment"
import { CalibrationConfigs } from "../@types"

export const getCalibrationConfigs = (
  deployments: { [id: string]: IDeployment },
  calibrations: ICalibration[]
) => {
  const configs: CalibrationConfigs = {}
  calibrations.map(c => {
    const deployment = deployments[c.deploymentId]
    const config = deployment?.config
    const rotation = config?.rotation || 0
    const lat = deployment?.latCameraCentre || 0
    const lng = deployment?.longCameraCentre || 0
    configs[c.calibrationId] = {
      rotation,
      lat,
      lng,
    }
  })
  return configs
}
