import axios from "axios"
import { ICalibration } from "../../@types/ICalibration"
import { DeploymentConfig, IDeployment } from "../../@types/IDeployment"
import { IDevice } from "../../@types/IDevice"
import { IProject } from "../../@types/IProject"
import { IProjectData } from "../../@types/IProjectData"
import { ISite } from "../../@types/ISite"
import { IUser } from "../../@types/IUser"
import {
  IUserProfileV2,
  UserOrgPermissionsAttribute,
  UserPermissionsAttribute,
  UserSettings,
} from "../../@types/IUserProfileV2"
import { IZone } from "../../@types/IZone"
import { Permissions } from "../reducers/tenantReducer"

export const tenantAPI = {
  /**
   * gets user profile
   */
  getUserProfile: async (input: { token: string; apiGatewayUrl: string }) => {
    const { token, apiGatewayUrl } = input
    const res = await axios.get(`${apiGatewayUrl}/userProfileV2/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data as IUserProfileV2
  },
  /**
   * gets user project data for given projectId
   */
  getProjectData: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
  }) => {
    const { token, apiGatewayUrl, projectId } = input
    const resObj = await axios.get(
      `${apiGatewayUrl}/projectData/${projectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return resObj.data as IProjectData
  },
  /**
   * Create project
   */
  createProject: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    projectObj: Partial<IProject>
  }) => {
    const { apiGatewayUrl, organisationId, projectObj, token } = input
    const res = await axios.post(
      `${apiGatewayUrl}/projects/${organisationId}`,
      projectObj,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if (!res || (res && !res.data)) throw new Error("Create failed")
    const projectPermissions = res.data
      .projectPermissions as UserPermissionsAttribute[]
    const project = res.data.project as IProject
    return { projectPermissions, project }
  },
  /**
   * Clones a project
   */
  cloneProject: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    projectId: string
  }) => {
    const { apiGatewayUrl, organisationId, projectId, token } = input
    const res = await axios.post(
      `${apiGatewayUrl}/projects/${organisationId}/${projectId}/_clone`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if (!res || (res && !res.data)) throw new Error("Clone failed")
    const project = res.data.project as IProject
    const projectPermissions = res.data
      .projectPermissions as UserPermissionsAttribute[]
    return { project, projectPermissions }
  },
  /**
   * Deletes a project
   */
  deleteProject: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    projectId: string
  }) => {
    const { apiGatewayUrl, organisationId, projectId, token } = input
    const res = await axios.delete(
      `${apiGatewayUrl}/projects/${organisationId}/${projectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if (!res.data) throw new Error("Delete failed")
  },
  /**
   * Create device
   */
  createDevice: async (input: {
    token: string
    apiGatewayUrl: string
    device: Partial<IDevice>
  }) => {
    const { apiGatewayUrl, token, device } = input
    const url = `${apiGatewayUrl}/devices`
    const res = await axios.post(url, device, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const newObj = res.data
    if (!newObj) throw new Error("Could not create device")
    return newObj as IDevice
  },
  /**
   * Create deployment
   */
  createDeployment: async (input: {
    token: string
    apiGatewayUrl: string
    deployment: Partial<IDeployment>
  }) => {
    const { apiGatewayUrl, token, deployment } = input
    const url = `${apiGatewayUrl}/deployments`
    const res = await axios.post(url, deployment, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const newObj = res.data
    if (!newObj) throw new Error("Could not create deployment")
    return newObj as IDeployment
  },
  /**
   * Deletes device
   */
  deleteDevice: async (input: {
    token: string
    apiGatewayUrl: string
    deviceId: string
  }) => {
    const { apiGatewayUrl, token, deviceId } = input
    return await axios.delete(`${apiGatewayUrl}/devices/${deviceId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  },
  /**
   * Updates device
   */
  updateDevice: async (input: {
    token: string
    apiGatewayUrl: string
    device: Partial<IDevice>
    deviceId: string
  }) => {
    const { apiGatewayUrl, token, device, deviceId } = input
    const url = `${apiGatewayUrl}/devices/${deviceId}`
    const res = await axios.put(url, device, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const newObj = res.data
    if (!newObj) throw new Error("Could not update device")
    return newObj as IDevice
  },
  /**
   * Updates deployment
   */
  updateDeployment: async (input: {
    token: string
    apiGatewayUrl: string
    deployment: Partial<IDeployment>
    deploymentId: string
  }) => {
    const { apiGatewayUrl, token, deployment, deploymentId } = input
    const url = `${apiGatewayUrl}/deployments/${deploymentId}`
    const res = await axios.put(url, deployment, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const newObj = res.data
    if (!newObj) throw new Error("Could not update deployment")
    return newObj as IDeployment
  },
  /**
   * create site
   */
  createSite: async (input: {
    token: string
    apiGatewayUrl: string
    site: Partial<ISite>
  }) => {
    const { apiGatewayUrl, token, site } = input
    const res = await axios.post(`${apiGatewayUrl}/sites`, site, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data as ISite
  },
  /**
   * uploads site overlay
   */
  uploadSiteOverlay: async (input: {
    token: string
    apiGatewayUrl: string
    siteId: string
    projectId: string
    imageFile: File
  }) => {
    const { apiGatewayUrl, token, siteId, projectId, imageFile } = input
    const url = `${apiGatewayUrl}/siteOverlays/_upload`
    const formData = new FormData()
    formData.append("siteId", siteId)
    formData.append("projectId", projectId)
    formData.append("image", imageFile)
    const res = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data as ISite
  },
  /**
   * Deletes site overlay
   */
  deleteSiteOverlay: async (input: {
    token: string
    apiGatewayUrl: string
    siteId: string
  }) => {
    const { apiGatewayUrl, token, siteId } = input
    const url = `${apiGatewayUrl}/siteOverlays/${siteId}`
    const res = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data as ISite
  },
  /**
   * Update site
   */
  updateSite: async (input: {
    token: string
    apiGatewayUrl: string
    site: Partial<ISite>
    siteId: string
  }) => {
    const { apiGatewayUrl, token, site, siteId } = input
    const res = await axios.put(`${apiGatewayUrl}/sites/${siteId}`, site, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data as ISite
  },
  /**
   * upload floorplan
   */
  uploadFloorplan: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
    deploymentId: number
    image: File
    floorplanorientation: string
    metrepixelratio: string
    lat: number
    lng: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      projectId,
      calibrationId,
      deploymentId,
      floorplanorientation,
      image,
      lat,
      lng,
      metrepixelratio,
    } = input
    const url = `${apiGatewayUrl}/calibrationHelpers/floorplan/_upload`
    const formData = new FormData()
    formData.append("projectId", projectId)
    formData.append("calibrationId", calibrationId)
    formData.append("deploymentId", `${deploymentId}`)
    formData.append("image", image)
    formData.append("floorplanorientation", floorplanorientation)
    formData.append("metrepixelratio", metrepixelratio)
    formData.append("lat", `${lat}`)
    formData.append("lng", `${lng}`)
    const res = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data as ICalibration
  },
  /**
   * Capture camera view
   */
  captureCameraView: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
    deploymentId: number
    densityvieworientation: string
  }) => {
    const {
      apiGatewayUrl,
      token,
      projectId,
      calibrationId,
      deploymentId,
      densityvieworientation,
    } = input
    const url = `${apiGatewayUrl}/calibrationHelpers/_capture`
    const data = {
      projectId,
      calibrationId,
      deploymentId,
      densityvieworientation,
    }
    const res = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 60000,
    })

    return res.data as ICalibration
  },
  /**
   * Upload camera view
   */
  uploadCameraView: async (input: {
    token: string
    apiGatewayUrl: string
    deploymentId: number
    calibrationId: string
    projectId: string
    imageFile: File
    densityvieworientation: string
  }) => {
    const {
      apiGatewayUrl,
      token,
      deploymentId,
      calibrationId,
      projectId,
      imageFile,
      densityvieworientation
    } = input
    const url = `${apiGatewayUrl}/calibrationHelpers/_cameraUpload`
    const formData = new FormData()
    formData.append("deploymentId", `${deploymentId}`)
    formData.append("calibrationId", calibrationId)
    formData.append("projectId", projectId)
    formData.append("image", imageFile)
    formData.append("densityvieworientation", densityvieworientation)
    const res = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data as ICalibration
  },
  /**
   * Upload satellite view
   */
  uploadSatellite: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
    deploymentId: number
    provider: string
    zoom: number
    floorplanorientation: string
    lat: number
    lng: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      projectId,
      calibrationId,
      deploymentId,
      floorplanorientation,
      provider,
      zoom,
      lat,
      lng,
    } = input
    const url = `${apiGatewayUrl}/calibrationHelpers/floorplan/_satellite`
    const data = {
      projectId,
      calibrationId,
      deploymentId,
      floorplanOrientation: floorplanorientation,
      provider,
      zoom,
      lat,
      lng,
    }
    const res = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data as ICalibration
  },
  /**
   * upload grid
   */
  uploadGrid: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
    deploymentId: number
    floorplanorientation: string
    gridCount: number
    gridWidth: number
    gridRotation: string
    opacity: number
    lat: number
    lng: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      projectId,
      calibrationId,
      deploymentId,
      floorplanorientation,
      gridCount,
      gridWidth,
      gridRotation,
      opacity,
      lat,
      lng,
    } = input
    const url = `${apiGatewayUrl}/calibrationHelpers/floorplan/_grid`
    const data = {
      projectId,
      calibrationId,
      deploymentId,
      floorplanorientation,
      gridCount,
      gridWidth,
      gridRotation,
      opacity,
      zoom: undefined,
      lat,
      lng,
    }
    const res = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data as ICalibration
  },
  /**
   * upload grid only
   */
  uploadGridOnly: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
    deploymentId: number
    floorplanorientation: string
    metrepixelratio: number
    gridCount: number
    gridRotation: string
    lat: number
    lng: number
  }) => {
    const {
      apiGatewayUrl,
      token,
      projectId,
      calibrationId,
      deploymentId,
      floorplanorientation,
      metrepixelratio,
      gridCount,
      gridRotation,
      lat,
      lng,
    } = input
    const url = `${apiGatewayUrl}/calibrationHelpers/floorplan/_gridOnly`
    const data = {
      projectId,
      calibrationId,
      deploymentId,
      floorplanorientation,
      metrepixelratio,
      gridCount,
      gridRotation,
      lat,
      lng,
    }
    const res = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data as ICalibration
  },
  /**
   * Create user
   */
  createUser: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    user: Partial<IUser>
    role: string
  }) => {
    const { token, apiGatewayUrl, organisationId, user, role } = input
    const res = await axios.post(
      `${apiGatewayUrl}/users/${organisationId}`,
      {
        user,
        role,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if (!res || (res && !res.data)) throw new Error("Create failed")
    const newUser = res.data.user as IUser
    const permission = res.data.permission as UserOrgPermissionsAttribute
    const organisationPermissions = res.data.organisationPermissions as
      | { [id: string]: UserPermissionsAttribute }
      | undefined

    return {
      newUser,
      permission,
      organisationPermissions,
    }
  },
  /**
   * Invite user
   */
  inviteUser: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    user: Partial<IUser> & { password?: string }
    role: string
  }) => {
    const { token, apiGatewayUrl, organisationId, user, role } = input
    const res = await axios.post(
      `${apiGatewayUrl}/users/${organisationId}/_invite`,
      {
        user: {
          email: user.email,
          password: user.password,
        },
        role,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if (!res || (res && !res.data)) throw new Error("Create failed")
    const newUser = res.data.user as IUser
    const permission = res.data.permission as UserOrgPermissionsAttribute
    const organisationPermissions = res.data.organisationPermissions as
      | { [id: string]: UserPermissionsAttribute }
      | undefined
    return {
      newUser,
      permission,
      organisationPermissions,
    }
  },
  /**
   * Update user
   */
  updateUser: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    user: Partial<IUser> & { password?: string }
    userId: string
    role: string
    userSettings?: Partial<UserSettings>
  }) => {
    const { token, apiGatewayUrl, organisationId, user, userId, role, userSettings } = input
    const res = await axios.put(
      `${apiGatewayUrl}/users/${organisationId}/${userId}`,
      {
        user: {
          ...user,
        },
        role,
        ...({userSettings} || {}),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const newUser = res.data.user as IUser
    const permission = res.data.permission as
      | UserOrgPermissionsAttribute
      | undefined
    const organisationPermissions = res.data.organisationPermissions as
      | { [id: string]: UserPermissionsAttribute }
      | undefined
    const newUserSettings = res.data.userSettings
    return {
      newUser,
      permission,
      organisationPermissions,
      newUserSettings
    }
  },
  /**
   * Delete user
   */
  deleteUser: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    userId: string
  }) => {
    const { token, apiGatewayUrl, organisationId, userId } = input
    const res = await axios.delete(
      `${apiGatewayUrl}/users/${organisationId}/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  },
  /**
   * Syncs projects users list
   *
   */
  syncProjectUsers: async (input: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    projectId: string
    toCreate: Permissions
    toUpdate: Permissions
    toDelete: Permissions
  }) => {
    const {
      token,
      apiGatewayUrl,
      organisationId,
      projectId,
      toCreate,
      toUpdate,
      toDelete,
    } = input
    const res = await axios.post(
      `${apiGatewayUrl}/projects/${organisationId}/${projectId}/_syncUsers`,
      {
        toCreate,
        toUpdate,
        toDelete,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const projectPermissions = (res.data.projectPermissions || {}) as {
      [id: string]: UserPermissionsAttribute
    }
    return { projectPermissions }
  },
  /**
   * start calibration
   *
   */
  startCalibration: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
    pointsMapView: string
    pointsDensityView: string
  }) => {
    const {
      token,
      apiGatewayUrl,
      projectId,
      calibrationId,
      pointsMapView,
      pointsDensityView,
    } = input
    try {
      const url = `${apiGatewayUrl}/calibrationHelpers/_calibrate`
      const data = {
        projectId,
        calibrationId,
        pointsMapView,
        pointsDensityView,
      }
      const res = await axios.post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      return res.data as ICalibration
    } catch (e) {
      console.error(e);
      return;
    }
  },
  /**
   * start mood calibration
   *
   */
  startMoodCalibration: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    calibrationId: string
  }) => {
    const { token, apiGatewayUrl, projectId, calibrationId } = input
    const url = `${apiGatewayUrl}/calibrationHelpers/_calibrateMood`
    const data = {
      projectId,
      calibrationId,
    }
    const res = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data as ICalibration
  },
  /**
   * verify deployment
   *
   */
  verifyDeployment: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    deploymentId: string
  }) => {
    const { token, apiGatewayUrl, projectId, deploymentId } = input
    const url = `${apiGatewayUrl}/calibration/camera_driver_deployment/${projectId}/${deploymentId}/`
    const res = await axios.get(url, {
      // Note: Temporarily disabled since it was giving errors
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`,
      // },
    })
    return res.data as any
  },
  /**
   * save deployment config
   *
   */
  saveDeploymentConfig: async (input: {
    token: string
    apiGatewayUrl: string
    deploymentId: string
    config: DeploymentConfig
  }) => {
    const { token, apiGatewayUrl, deploymentId, config } = input
    const res = await axios.put(
      `${apiGatewayUrl}/deployments/${deploymentId}`,
      {
        config,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data as IDeployment
  },
  /**
   * save zone config
   *
   */
  saveZoneConfig: async (input: {
    token: string
    apiGatewayUrl: string
    zoneId: string
    zoneData: Partial<IZone>
  }) => {
    const { token, apiGatewayUrl, zoneId, zoneData } = input
    const zoneRes = await axios.put(
      `${apiGatewayUrl}/zones/${zoneId}`,
      {
        ...zoneData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return zoneRes.data as IZone
  },
  /**
   * get camera schedules
   *
   */
  getCameraSchedules: async (input: {
    token: string
    apiGatewayUrl: string
    projectId: string
    isEnabled: boolean
    isDeleted: boolean
  }) => {
    const { token, apiGatewayUrl, projectId, isEnabled, isDeleted } = input
    const res = await axios.get(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules?projectId=${projectId}&isEnabled=${isEnabled}&isDeleted=${isDeleted}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * get camera schedules
   *
   */
  getCameraScheduleRecurrence: async (input: {
    token: string
    apiGatewayUrl: string
    scheduleId: string
    isEnabled: boolean
    isDeleted: boolean
  }) => {
    const { token, apiGatewayUrl, scheduleId, isEnabled, isDeleted } = input
    const res = await axios.get(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules/recurrences?scheduleId=${scheduleId}&isEnabled=${isEnabled}&isDeleted=${isDeleted}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * create camera schedule
   *
   */
  createCameraSchedule: async (input: {
    token: string
    apiGatewayUrl: string
    schedule: {
      project_id: string,
      start_date: string,
      end_date: string,
      start_time: string,
      end_time: string,
      is_enabled: boolean,
      is_deleted: boolean,
      configured_date: string,
      configured_timezone: string,
    }
  }) => {
    const { token, apiGatewayUrl, schedule } = input
    const res = await axios.post(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules`,
      {
        schedule
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * create camera schedule recurrence
   *
   */
  createCameraScheduleRecurrence: async (input: {
    token: string
    apiGatewayUrl: string
    recurrence: {
      cs_id: string,
      type: string,
      frequency: string,
      is_enabled: boolean,
      is_deleted: boolean,
    }
  }) => {
    const { token, apiGatewayUrl, recurrence } = input
    const res = await axios.post(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules/recurrences`,
      {
        recurrence
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * update camera schedule
   *
   */
  updateCameraSchedule: async (input: {
    token: string
    apiGatewayUrl: string
    scheduleId: string
    schedule: {
      project_id: string,
      start_date: string,
      end_date: string,
      start_time: string,
      end_time: string,
      is_enabled: boolean,
      is_deleted: boolean,
      configured_date: string,
      configured_timezone: string,
    }
  }) => {
    const { token, apiGatewayUrl, scheduleId, schedule } = input
    const res = await axios.put(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules`,
      {
        scheduleId,
        schedule
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * delete camera schedule
   *
   */
  deleteCameraSchedule: async (input: {
    token: string
    apiGatewayUrl: string
    scheduleId: string
  }) => {
    const { token, apiGatewayUrl, scheduleId } = input
    const res = await axios.delete(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules/${scheduleId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * update camera schedule recurrence
   *
   */
  updateCameraScheduleRecurrence: async (input: {
    token: string
    apiGatewayUrl: string
    scheduleRecurrenceId: string
    recurrence: {
      cs_id: string,
      type: string,
      frequency: string,
      is_enabled: boolean,
      is_deleted: boolean,
    }
  }) => {
    const { token, apiGatewayUrl, scheduleRecurrenceId, recurrence } = input
    const res = await axios.put(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules/recurrences`,
      {
        scheduleRecurrenceId,
        recurrence
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
  /**
   * delete camera schedule recurrence
   *
   */
  deleteCameraScheduleRecurrence: async (input: {
    token: string
    apiGatewayUrl: string
    scheduleRecurrenceId: string
  }) => {
    const { token, apiGatewayUrl, scheduleRecurrenceId } = input
    const res = await axios.delete(
      `${apiGatewayUrl}/cameraSetupHelpers/schedules/recurrences/${scheduleRecurrenceId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res.data
  },
}
