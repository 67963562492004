import { createSelector } from "reselect"
import { IState } from "../../../redux/reducers"

export const graphEndTimeSelector = createSelector(
  (s: IState) => s.app.pastDataMode,
  (s: IState) => s.dataV2.currentDate,
  (s: IState) => s.dataV2.prevSelectedToDate,
  (pastDataMode, currentDate, prevSelectedToDate) => {
    return pastDataMode ? prevSelectedToDate : currentDate
  }
)
