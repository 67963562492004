exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-no-permissions-tsx": () => import("./../../../src/pages/noPermissions.tsx" /* webpackChunkName: "component---src-pages-no-permissions-tsx" */),
  "component---src-pages-updatepassword-tsx": () => import("./../../../src/pages/updatepassword.tsx" /* webpackChunkName: "component---src-pages-updatepassword-tsx" */),
  "component---src-pages-version-tsx": () => import("./../../../src/pages/version.tsx" /* webpackChunkName: "component---src-pages-version-tsx" */)
}

