import createAuth0Client, { Auth0ClientOptions } from "@auth0/auth0-spa-js"
// import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import { Auth0Client } from "@auth0/auth0-spa-js"
import { Epic } from "redux-observable"
import { Observable } from "rxjs"
import { switchMap, map, catchError, filter } from "rxjs/operators"
import {
  LOGIN_REDIRECT,
  LOGOUT_REDIRECT,
  CLAIM_FORCE_PW_UPDATE,
} from "../../constants"
import { produce, Draft } from "immer"
import { navigate } from "@reach/router"
// import { navigate } from "gatsby"
import { IState, RootAction } from "."
import { IUser } from "../../@types/IUser"
import { UserSettings } from "../../@types/IUserProfileV2"
import { unscope } from "../functions/auth0/unscope"
import { appActions } from "./appReducer"
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import { Dependencies } from "../ReduxProvider"
import { tenantActions } from "./tenantReducer"
import qs from "qs"

const AD_ID_PREFIX = "waad"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export interface Auth0State {
  connecting: boolean
  auth0Client?: Auth0Client
  auth0ClientId: string
  auth0User: Partial<IUser & Auth0User>
  //For user profile page
  userProfileMode: "" | "edit"
}

export const initialAuth0State: Auth0State = {
  connecting: false,
  auth0Client: undefined,
  auth0ClientId: "",
  auth0User: {
    user_id: "",
    nickname: "",
    name: "",
    picture: "",
    email: "",
    email_verified: false,
  },
  //For user profile page
  userProfileMode: "",
}

export interface Auth0User {
  nickname: string
  name: string
  picture: string
  updated_at: string | Date
  email: string
  email_verified: boolean
  sub: string
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const auth0Actions = {
  init: createAction(
    "@auth0/init",
    (auth0Client: Auth0Client) => auth0Client
  )(),
  profileMode: createAction(
    "@auth0/profileToggle",
    (userProfileMode: "" | "edit") => userProfileMode
  )(),
}
export const auth0ActionsAsync = {
  login: createAsyncAction(
    "@auth0/login/req",
    "@auth0/login/res",
    "@auth0/login/err"
  )<
    { returnPathName?: string },
    { user: Partial<IUser & Auth0User>; clientId: string },
    { error: Error }
  >(),
  logout: createAsyncAction(
    "@auth0/logout/req",
    "@auth0/logout/res",
    "@auth0/logout/err"
  )<{}, {}, { error: Error }>(),
  profileEdit: createAsyncAction(
    "@auth0/profileEdit/req",
    "@auth0/profileEdit/res",
    "@auth0/profileEdit/err"
  )<
    { firstName: string; lastName: string; phone: string, userSettings?: UserSettings },
    { user: Partial<IUser & Auth0User> },
    { error: Error; message?: string }
  >(),
  changePassword: createAsyncAction(
    "@auth0/changePassword/req",
    "@auth0/changePassword/res",
    "@auth0/changePassword/err"
  )<{}, {}, { error: Error }>(),
  firstTimeADLogin: createAsyncAction(
    "@auth0/firstTimeADLogin/req",
    "@auth0/firstTimeADLogin/res",
    "@auth0/firstTimeADLogin/err"
  )<{ userId: string }, {}, { error: Error }>(),
}

type ValueOf<T> = T[keyof T]
export type Auth0Action =
  | ReturnType<ValueOf<typeof auth0Actions>>
  | ReturnType<ValueOf<ValueOf<typeof auth0ActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */
export const auth0Reducer = produce(
  (draft: Draft<Auth0State>, action: RootAction) => {
    switch (action.type) {
      case getType(auth0Actions.init):
        draft.auth0Client = action.payload
        draft.connecting = true
        return
      case getType(auth0ActionsAsync.logout.success):
        draft.auth0ClientId = ""
        return
      case getType(auth0ActionsAsync.login.success):
        if (action.payload.user) draft.auth0User = action.payload.user
        draft.auth0ClientId = action.payload.clientId
        return
      //User profile page
      case getType(auth0Actions.profileMode):
        draft.userProfileMode = action.payload
        return
      case getType(auth0ActionsAsync.profileEdit.request):
        return
      case getType(auth0ActionsAsync.profileEdit.success):
        if (action.payload.user)
          draft.auth0User = { ...draft.auth0User, ...action.payload.user }
        draft.userProfileMode = ""
        return
      case getType(auth0ActionsAsync.profileEdit.failure):
        return
    }
  },
  initialAuth0State
)

export default auth0Reducer

export const auth0initEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  const init = new Observable<RootAction>(observer => {
    const initialise = async () => {
      const state = state$.value as IState
      const {
        oauthIssuer,
        oauthClientId,
        origin,
        apiGatewayAud,
        oauthNamespace,
      } = state.constants
      //Check for window (does not exist on SSR)
      if (typeof window === "undefined") return

      const INIT_CONFIG: Auth0ClientOptions = {
        domain: oauthIssuer,
        // client_id: oauthClientId,
        clientId: oauthClientId,
        authorizationParams: {
          redirect_uri:
            typeof window !== "undefined" ? window.location.origin : origin,
          audience: apiGatewayAud,
        },
        // useRefreshTokens: true,
        cacheLocation: "localstorage",
      }

      //DCM-893:
      //On app init, grab its URL and if it has link parameters with projectId
      //Then store them into a localcache object for later redirect use
      if (window.location.search.includes("projectId=")) {
        const search = location.search
        console.log("saving url...")
        const queryObj = qs.parse(search.replace("?", ""))
        localStorage.setItem("directLink", JSON.stringify(queryObj))

        //Clear URL query
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        )
      }

      //1.Start auth0 init
      // const auth0Client = await createAuth0Client(INIT_CONFIG)
      const auth0Client = new Auth0Client(INIT_CONFIG)
      observer.next(auth0Actions.init(auth0Client))

      //Check if page load is a redirect
      if (!!window && window.location.search.includes("code=")) {
        //Response contains code and app state?
        //Appstate should contain a `returnPathName`, go back to this path
        const { appState } = await auth0Client.handleRedirectCallback()
        const returnPathName = appState?.returnPathName || "/" // or just go to home
        //Replace window history to remove redirect code
        //& also redirect to returnPathName
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        )

        // console.log("ran redirect callback", { returnPathName })
        // navigate("/", {
        //   state: {
        //     returnPathName,
        //   },
        // })
      }

      //Check for authentication
      const success = await auth0Client.isAuthenticated()
      if (success) {
        //Just need to get user's Id
        const claims = await auth0Client.getIdTokenClaims();
        if (claims[`${window.location.origin}/${CLAIM_FORCE_PW_UPDATE}`]) {
          observer.next(appActions.setVariable("forcePasswordUpdate", true))
        }
        const user: IUser | undefined = unscope(oauthNamespace)(
          (await auth0Client.getUser()) || undefined
        )
        const clientId = (user && user.email) || ""
        const userId = (user && user.sub) || ""

        if (userId && userId.includes(AD_ID_PREFIX)) {
          const orgId = user && user.app_metadata && user.app_metadata.organisationId
          // First time login if no organisation id set
          if (!orgId) observer.next(auth0ActionsAsync.firstTimeADLogin.request({ userId }))
        }

        observer.next(auth0ActionsAsync.login.success({ clientId, user }))
      }
    }
    initialise().catch(error =>
      observer.next(auth0ActionsAsync.login.failure({ error }))
    )
  })

  return init
}

export const auth0LoginEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(auth0ActionsAsync.login.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        async function login() {
          const state = state$.value as IState
          const { origin, oauthNamespace } = state.constants
          const returnPathName = action.payload.returnPathName || LOGIN_REDIRECT //redirect to url, or return to default url

          const client = state.auth0.auth0Client
          // console.log("attempting to login...", client)
          if (client) {
            // await client.loginWithPopup({
            await client.loginWithRedirect({
              authorizationParams: {
                redirect_uri:
                  typeof window !== "undefined" ? window.location.origin : origin,
              },
              // redirect_uri:
              //   typeof window !== "undefined" ? window.location.origin : origin,
              appState: { returnPathName },
            })

            const user: IUser | undefined = unscope(oauthNamespace)(
              (await client.getUser()) || undefined
            )
            const clientId = (user && user.email) || ""
            observer.next(auth0ActionsAsync.login.success({ clientId, user }))
            // typeof window !== "undefined" && navigate(LOGIN_REDIRECT)
          }
        }
        login().catch(error =>
          observer.next(auth0ActionsAsync.login.failure({ error }))
        )
      })
    })
  )
}

export const auth0LogoutEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(auth0ActionsAsync.logout.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { origin } = state.constants
        const client = state.auth0.auth0Client
        if (client)
          client.logout({
            logoutParams: {
              returnTo:
                typeof window !== "undefined" ? window.location.origin : origin,
            }
          })
        observer.next(auth0ActionsAsync.logout.success({}))

        typeof window !== "undefined" && navigate(LOGOUT_REDIRECT)
      })
    }),
    catchError((error, caught) => {
      return caught.pipe(map(e => auth0ActionsAsync.logout.failure({ error })))
    })
  )
}

export const auth0ChangePasswordEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(auth0ActionsAsync.changePassword.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        async function changePassword() {
          const state = state$.value as IState
          const { oauthClientId, oauthIssuer } = state.constants
          const client = state.auth0.auth0Client
          const token = client && (await client.getTokenSilently())
          const user = (await client.getUser()) as Auth0User
          const email = (user && user.email) || ""
          if (!token) throw new Error("no token")

          await dependencies.auth0API.changePassword({
            oauthClientId,
            oauthIssuer,
            email,
            token,
          })

          observer.next(auth0ActionsAsync.changePassword.success({}))
        }
        changePassword().catch(error => {
          observer.next(auth0ActionsAsync.changePassword.failure({ error }))
        })
      })
    })
  )
}

/**
 * Epics
 */
export const userProfileEditEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(auth0ActionsAsync.profileEdit.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        async function userProfileEdit() {
          const token = client ? await client.getTokenSilently() : ""
          const { apiGatewayUrl, oauthNamespace } = state.constants

          //1.Extract details from payload and state
          const { firstName, lastName, phone, userSettings } = action.payload
          const res = await dependencies.auth0API.editProfile({
            apiGatewayUrl,
            firstName,
            lastName,
            phone,
            userSettings,
            token,
          })
          const user = unscope(oauthNamespace)(res.data.user) as IUser
          const newUserSettings = unscope(oauthNamespace)(res.data.userSettings) as UserSettings

          //3.Pass new values to reducer
          observer.next(auth0ActionsAsync.profileEdit.success({ user }))
          observer.next(tenantActions.updateUserSettings(newUserSettings))
        }
        userProfileEdit().catch(error => {
          const e = (error.response && error.response.data) || {}
          const message = e.message || ""
          observer.next(
            auth0ActionsAsync.profileEdit.failure({ error, message })
          )
        })
      })
    })
  )
}

export const firstTimeADLoginEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(auth0ActionsAsync.firstTimeADLogin.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        async function firstTimeADLogin() {
          const token = client ? await client.getTokenSilently() : ""
          const { apiGatewayUrl } = state.constants
          const { userId } = action.payload

          //1.Extract details from payload and state
          const res = await dependencies.auth0API.domains({
            apiGatewayUrl,
            userId,
            token,
          })

          //3.Pass new values to reducer
          observer.next(auth0ActionsAsync.firstTimeADLogin.success({ }))
        }
        firstTimeADLogin().catch(error => {
          observer.next(
            auth0ActionsAsync.firstTimeADLogin.failure({ error })
          )
        })
      })
    })
  )
}

/**
 * Selectors
 */
export const auth0ClientSelector = (state: IState) => state.auth0.auth0Client
