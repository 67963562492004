import { createSelector } from "reselect"
import parseFeature from "../../components/Map/parseFeature"
import { IState } from "../reducers"
import * as turf from "@turf/turf"
import { IZone } from "../../@types/IZone"
import { IDeployment } from "../../@types/IDeployment"
import { IOrganisation } from "../../@types/IOrganisation"
import { sortAlphaNum } from "../../utils/sortAlphaNum"
import { sortAlphabetically } from "../../page/settings/SettingsTree/sorts"

/**
 * get current selected project
 */
export const selectedProjectSelector = createSelector(
  (state: IState) => state.tenant.selectedProjectId,
  (state: IState) => state.tenant.projects,
  (projectId, projects) => {
    return projects[projectId]
  }
)

export function zoneSelector(zoneId: string) {
  return (state: IState) => state.tenant.zones[zoneId]
}

//Gets the bbox of all the site features
//Mainly used for determining what the centre of the map should be
//If bbox cannot be determined then returns null
export const sitesBboxSelector = createSelector(
  (state: IState) => state.tenant.sites,
  sites => {
    try {
      const features = Object.values(sites)
        .map(s => parseFeature(s.area))
        .filter(Boolean)
      if (!(features.length > 0)) return null
      const bbox = turf.bbox(turf.featureCollection(features))
      return bbox && (bbox.slice(0, 4) as [number, number, number, number])
    } catch (error) {}
    return null
  }
)

// const sortZonesAlphaNumerically = (a: IZone, b: IZone) =>
//   sortAlphaNum(a.zoneName, b.zoneName)
const sortZonesAlphaNumerically = (a: IZone, b: IZone) =>
  sortAlphabetically(a.zoneName, b.zoneName)

//This one is for the map displays;
//Depends on filteredLevel (so layers /geodata dont overlap)
export function zonesToShowSelector(state: IState) {
  const { filteredSite, filteredLevel, filteredZone } = state.app
  let zones: { [zoneId: string]: IZone } = {}
  Object.values(state.tenant.zones)
    .sort(sortZonesAlphaNumerically)
    .map((zone, i) => {
      const zoneId = zone.zoneId
      const site = state.tenant.sites[zone.siteId]
      if (!zone || !site) return
      if (typeof site.level === "number" && filteredLevel !== site.level) return
      if (!filteredSite || (filteredSite && zone.siteId === filteredSite)) {
        zones[zoneId] = { ...zone, i } //Assign index value
      }
    })

  return zones
}
//This one is for the zone drawers/ risk display indicator,
//It does not depend on filteredLevel
export function zonesToDisplaySelector(state: IState) {
  let zonesObj: { [zoneId: string]: IZone } = {}
  const zones = state.tenant.zones
  const filteredSite = state.app.filteredSite
  const zoneList = Object.values(zones)
    .sort(sortZonesAlphaNumerically)
    .map((zone, i) => {
      if (filteredSite && zone.siteId !== filteredSite) return null
      return { ...zone, i } //Assign index value
    })
    .filter(Boolean)
  zoneList.forEach(z => {
    zonesObj[z.zoneId] = z
  })
  return zonesObj
}

//Handy function for getting what organisation the user belongs to
export const userOrganisationSelector = createSelector(
  (state: IState) => state.tenant.organisations,
  organisations => {
    //Return the first organisation
    return Object.values(organisations)[0] as IOrganisation
  }
)

//Selector for determining if the user is an organisation admin
//or just an organisation user
//FOR NOW: which organisation the user is in doesn't matter
//It is assumed a user only belongs to 1 organisation also
export const userOrganisationRoleSelector = createSelector(
  (state: IState) => state.tenant.userPermissions,
  permissions => {
    const orgPermissions = (permissions && permissions.org) || {}
    const organisationId = Object.keys(orgPermissions)[0] || ""
    const role = orgPermissions[organisationId]
    return role === "admin" ? "admin" : "user"
  }
)

//Selector for determining if the user is a project admin or user
//Given the current selected project
export const userProjectRoleSelector = createSelector(
  (state: IState) => state.tenant.userPermissions,
  (state: IState) => state.tenant.selectedProjectId,
  (permissions, selectedProjectId) => {
    const projectPermissions = (permissions && permissions.project) || {}
    const role = projectPermissions[selectedProjectId]
    return role === "admin" ? "admin" : "user"
  }
)

//Selector for getting projectPermissions by projectId
export const projectPermissionsSelector = (projectId: string) =>
  createSelector(
    (state: IState) => state.tenant.projectPermissions,
    (state: IState) => state.tenant.users,
    (permissions, users) => {
      const filteredPermissions = Object.values(permissions)
        .filter(p => p.projectId === projectId)
        //Also merge permission fields with user fields
        .map(p => ({ ...users[p.userId], ...p }))
      return filteredPermissions
    }
  )

//Selector for getting organisation permissions
export const organisationPermissionsSelector = createSelector(
  (state: IState) => state.tenant.organisationPermissions,
  (state: IState) => state.tenant.users,
  (permissions, users) => {
    const merged = Object.values(permissions)
      .map(p => ({
        ...users[p.userId],
        ...p,
      }))
      .filter(p => !!p.user_id)
      //Sort alphabetically by name
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
    return merged
  }
)

export const calibrationsByZoneSelector = (zoneId: string) => createSelector(
  (state: IState) => state.tenant.deployments,
  (state: IState) => state.tenant.calibrations,
  (deployments, calibrations) => {
    const filteredDeployments = Object.values(deployments)
      .filter(d => d.zoneId === zoneId)
      .map(d => d.deploymentId)
    const filteredCalibrations = Object.values(calibrations)
      .filter(c => filteredDeployments.includes(c.deploymentId))
    return filteredCalibrations
  }
)