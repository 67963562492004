import { Epic } from "redux-observable"
import { Observable, interval, timer } from "rxjs"
import { switchMap, map, takeUntil, tap, take, filter } from "rxjs/operators"
import { produce, Draft } from "immer"
import { IState, RootAction } from "."
import { roundDate } from "../functions/roundDate"
import { DEFAULT_INTERVAL, DEFAULT_CENTER, DASHBOARD_PATH } from "../../constants"
import { navigate } from "@reach/router"
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import { projectActions } from "./projectReducer"
import { tenantActionsAsync } from "./tenantReducer"
import { Dependencies } from "../ReduxProvider"
import { dataV2ActionsAsync } from "./dataReducerV2"
import moment, { Moment } from "moment"
import { liveJobQueue } from "../controllers/dataV2FetchLiveController"
import { pastJobQueue } from "../controllers/dataV2FetchPastController"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export interface Message {
  message?: string
  title?: string
  color?: string
  err_code?: string //Only from calibration server
}

export interface AppState {
  tabActive: boolean //Determine if fetching is needed
  dashActive: boolean //Determine if fetching is needed
  debugMode: boolean
  messages: {
    [id: string]: Message
  }
  show: boolean //This is for ShowLater components, reason is for offloading some components
  selectedTime: Date //Just for display
  controlBarMinute: number
  controlBarTime: moment.Moment
  mapControlsOpen: boolean
  mapCenterMain: [number, number]
  userLocation: [number, number]
  heatmapType: "point" | "grid" | "heatmap" | "cluster"
  heatmapSource: "d" | "f" | "m" | "s" | "c" | "h" | "g"
  mode: "" | "social" | "grouping" | "train" //data display mode
  // socialMode: boolean
  modeMenuOpen: boolean
  satelliteMode: boolean
  pastDataMode: boolean
  showMapZones: boolean
  showMapOverlays: boolean
  drawerOpen: boolean
  alertDrawerOpen: boolean
  projectSelectorOpen: boolean
  alertsOpen: boolean
  configOpen: boolean
  addNoteOpen: boolean
  addNoteMessage: string
  fullscreen: boolean
  switchImages: boolean
  filteredSite: string
  filteredZone: string
  filteredCalibration: string
  filteredImage: number // index of which calibrationId to display
  filteredLevel: number // B1 = -1 , G = 0 , Level 1 = 1 etc.
  filteredRisk: number | null //null if no filter to apply
  refreshEnabled: boolean //For auto refresh of recent data
  startTime: Date
  endTime: Date
  play: boolean
  speed: number
  selectedTimestamp: Date
  currentTime: Date
  modelFetching: boolean //If fetching for deployment/zone/site/project/
  fetching: { [type: string]: boolean }
  moodCornerLoading: "" | "tl" | "tr" | "bl" | "br"
  forcePasswordUpdate: boolean // If the user needs to update their password
}

const HOUR = 1000 * 60 * 60
export const initialAppState: AppState = {
  tabActive:
    typeof window !== "undefined"
      ? document.visibilityState === "visible"
      : true,
  dashActive: true,
  debugMode: false,
  messages: {},
  show: false,
  selectedTime: new Date(),
  controlBarMinute: 60,
  controlBarTime: moment(new Date()),
  mapControlsOpen: true,
  mapCenterMain: DEFAULT_CENTER,
  userLocation: DEFAULT_CENTER,
  heatmapType: "grid",
  heatmapSource: "d",
  mode: "",
  // socialMode: false,
  modeMenuOpen: false,
  satelliteMode: false,
  pastDataMode: false,
  showMapZones: true,
  showMapOverlays: true,
  drawerOpen: true,
  alertDrawerOpen: true,
  projectSelectorOpen: false,
  alertsOpen: false,
  configOpen: false,
  addNoteOpen: false,
  addNoteMessage: "",
  fullscreen: false,
  switchImages: true,
  filteredSite: "",
  filteredZone: "",
  filteredCalibration: "",
  filteredImage: 0,
  filteredLevel: 0,
  filteredRisk: null,
  refreshEnabled: true,
  startTime: new Date(new Date().getTime() - HOUR),
  endTime: new Date(),
  play: false, //Disable playing data at the start
  speed: 1,
  selectedTimestamp: new Date(),
  currentTime: roundDate(DEFAULT_INTERVAL)(new Date()),
  modelFetching: false,
  fetching: {},
  moodCornerLoading: "",
  forcePasswordUpdate: false,
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const appActions = {
  setVariable: createAction(
    "@app/setVariable",
    (key: keyof AppState, value: typeof initialAppState[keyof AppState]) => ({
      key,
      value,
    })
  )(),
  toggleDebug: createAction(
    "@app/toggleDebug",
    (debugMode: boolean) => debugMode
  )(),
  show: createAction("@app/show", (show: boolean) => show)(),
  resize: createAction("@app/resize", (resize: any) => resize)(),
  toggleMapControls: createAction(
    "@app/toggleMapControls",
    (mapControlsOpen: boolean) => mapControlsOpen
  )(),
  setTabActive: createAction(
    "@app/setTabActive",
    (tabActive: boolean) => tabActive
  )(),
  setDashActive: createAction(
    "@app/setDashActive",
    (dashActive: boolean) => dashActive
  )(),
  setLevel: createAction(
    "@app/setLevel",
    (filteredLevel: number) => filteredLevel
  )(),
  setSelectedCalibration: createAction(
    "@app/setSelectedCalibration",
    (calibrationId: number | string) => calibrationId
  )(),
  setRisk: createAction(
    "@app/setRisk",
    (filteredRisk: number) => filteredRisk
  )(),
  setHeatmapType: createAction(
    "@app/setHeatmapType",
    (heatmapType: "point" | "grid" | "heatmap" | "cluster") => heatmapType
  )(),
  setHeatmapSource: createAction(
    "@app/setHeatmapSource",
    (heatmapSource: "s" | "d" | "c" | "f" | "m" | "h" | "g") => heatmapSource
  )(),
  setMode: createAction(
    "@app/setMode",
    (mode: "" | "social" | "grouping" | "train") => mode
  )(),
  setControlBarMinute: createAction(
    "@app/setControlBarMinute",
    (minute: number) => minute
  )(),
  setControlBarTime: createAction(
    "@app/setControlBarTime",
    (time: moment.Moment) => time
  )(),
  toggleMenuMode: createAction(
    "@app/toggleMenuMode",
    (modeMenuOpen: boolean) => modeMenuOpen
  )(),
  toggleSatellite: createAction(
    "@app/toggleSatellite",
    (satelliteMode: boolean) => satelliteMode
  )(),
  toggleMapZones: createAction(
    "@app/toggleMapZones",
    (showMapZones: boolean) => showMapZones
  )(),
  toggleMapOverlays: createAction(
    "@app/toggleMapOverlays",
    (showMapOverlays: boolean) => showMapOverlays
  )(),
  toggleDrawer: createAction(
    "@app/toggleDrawer",
    (drawerOpen: boolean) => drawerOpen
  )(),
  toggleAlertDrawer: createAction(
    "@app/toggleAlertDrawer",
    (drawerOpen: boolean) => drawerOpen
  )(),
  toggleProjectSelector: createAction(
    "@app/toggleProjectSelector",
    (projectSelectorOpen: boolean) => projectSelectorOpen
  )(),
  toggleAlertsOpen: createAction(
    "@app/toggleAlertsOpen",
    (alertsOpen: boolean) => alertsOpen
  )(),
  toggleConfig: createAction(
    "@app/toggleConfig",
    (configOpen: boolean) => configOpen
  )(),
  toggleImageSwitching: createAction(
    "@app/toggleImageSwitching",
    (switchImages: boolean) => switchImages
  )(),
  setUserLocation: createAction(
    "@app/setUserLocation",
    (userLocation: [number, number]) => userLocation
  )(),
  setFilteredSite: createAction(
    "@app/setFilteredSite",
    (i: { filteredSite: string; center?: [number, number] }) => ({ ...i })
  )(),
  setFilteredZone: createAction(
    "@app/setFilteredZone",
    (filteredZone: string) => filteredZone
  )(),
  setFilteredCalibration: createAction(
    "@app/setFilteredCalibration",
    (filteredCalibration: string | number) => filteredCalibration
  )(),
  setFilteredImage: createAction(
    "@app/setFilteredImage",
    (filteredImage: number) => filteredImage
  )(),
  selectDashboard: createAction(
    "@app/selectDashboard",
    (dashboardId: number) => dashboardId
  )(),
  setTime: createAction(
    "@app/setTime",
    (i: { startTime: Date; endTime: Date }) => ({ ...i })
  )(),
  setSpeed: createAction("@app/setSpeed", (speed: number) => speed)(),
  togglePlay: createAction("@app/togglePlay", (play: boolean) => play)(),
  incrementTimestamp: createAction("@app/incrementTimestamp")(),
  setSelectedTimestamp: createAction(
    "@app/setSelectedTimestamp",
    (ts: Date) => ts
  )(),
  setPastDataMode: createAction(
    "@app/setPastDataMode",
    (pastDataMode: boolean) => pastDataMode
  )(),
}
export const appActionsAsync = {
  toggleFullscreen: createAsyncAction(
    "@app/toggleFullscreen/req",
    "@app/toggleFullscreen/res",
    "@app/toggleFullscreen/err"
  )<{ fullscreen: boolean }, { fullscreen: boolean }, { error: Event }>(),
}

type ValueOf<T> = T[keyof T]
export type AppAction =
  | ReturnType<ValueOf<typeof appActions>>
  | ReturnType<ValueOf<ValueOf<typeof appActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */
export const appReducer = produce(
  (draft: Draft<AppState>, action: RootAction) => {
    switch (action.type) {
      case getType(appActions.setVariable):
        {
          const { key, value } = action.payload
          //@ts-ignore
          draft[key] = value
        }
        return
      case getType(appActions.toggleDebug):
        draft.debugMode = action.payload
        return
      case getType(appActions.show):
        draft.show = true
        return
      case getType(appActions.toggleMapControls):
        draft.mapControlsOpen = action.payload
        return
      case getType(appActions.setTabActive):
        draft.tabActive = action.payload
        return
      case getType(appActions.setDashActive):
        draft.dashActive = action.payload
        return
      case getType(appActions.setLevel):
        draft.filteredLevel = action.payload
        return
      case getType(appActions.setRisk):
        draft.filteredRisk = action.payload
        return
      case getType(appActions.setHeatmapType):
        draft.heatmapType = action.payload
        return
      case getType(appActions.setHeatmapSource):
        draft.heatmapSource = action.payload
        return
      // case getType(appActions.toggleSocialMode):
      //   draft.socialMode = action.payload
      //   return
      case getType(appActions.setMode):
        draft.mode = action.payload
        return
      case getType(appActions.setControlBarMinute):
        draft.controlBarMinute = action.payload
        return
      case getType(appActions.setControlBarTime):
        draft.controlBarTime = action.payload
        return
      case getType(appActions.toggleMenuMode):
        draft.modeMenuOpen = action.payload
        return
      case getType(appActions.toggleSatellite):
        draft.satelliteMode = action.payload
        return
      case getType(appActions.toggleMapZones):
        draft.showMapZones = action.payload
        return
      case getType(appActions.toggleMapOverlays):
        draft.showMapOverlays = action.payload
        return
      case getType(appActions.toggleDrawer):
        draft.drawerOpen = action.payload
        return
      case getType(appActions.toggleAlertDrawer):
        draft.alertDrawerOpen = action.payload
        return
      case getType(appActions.toggleProjectSelector):
        draft.projectSelectorOpen = action.payload
        return
      case getType(appActions.toggleAlertsOpen):
        draft.alertsOpen = action.payload
        return
      case getType(appActions.toggleConfig):
        draft.configOpen = action.payload
        return
      case getType(appActionsAsync.toggleFullscreen.success):
        draft.fullscreen = action.payload.fullscreen
        return
      case getType(appActions.toggleImageSwitching):
        draft.switchImages = action.payload
        return
      case getType(appActions.setUserLocation):
        draft.userLocation = action.payload
        return
      case getType(appActions.setFilteredSite):
        draft.filteredSite = action.payload.filteredSite
        draft.filteredZone = ""
        draft.filteredCalibration = ""
        draft.configOpen = false //Also have to close the drawer
        draft.mapCenterMain = action.payload.center
        // Clear Priority queue
        if (!draft.pastDataMode) liveJobQueue.clearPriority()
        else pastJobQueue.clearPriority()
        return
      case getType(appActions.setFilteredZone):
        draft.filteredZone = action.payload
        draft.filteredCalibration = ""
        // Prioritise zone jobs
        if (!draft.pastDataMode) {
          liveJobQueue.clearPriority()
          liveJobQueue.prioritiseJobsByZoneId(action.payload)
        } else {
          pastJobQueue.clearPriority()
          pastJobQueue.prioritiseJobsByZoneId(action.payload)
        }
        return
      case getType(appActions.setFilteredCalibration):
        draft.filteredCalibration = `${action.payload}`
        return
      case getType(appActions.setFilteredImage):
        draft.filteredImage = action.payload
        return
      case getType(appActions.selectDashboard):
        if (typeof action.payload === "number" && action.payload < DASHBOARD_PATH.length && DASHBOARD_PATH[action.payload])
          navigate(DASHBOARD_PATH[action.payload])
        return
      case getType(appActions.setTime):
        draft.startTime = action.payload.startTime
        draft.endTime = action.payload.endTime
        return
      case getType(appActions.setSpeed):
        draft.speed = action.payload
        return
      case getType(appActions.setSelectedTimestamp):
        draft.selectedTimestamp = action.payload
        return
      case getType(dataV2ActionsAsync.refresh.request):
        draft.currentTime = roundDate(DEFAULT_INTERVAL)(new Date()) //Sets current rounded time
        return
      case getType(appActions.setPastDataMode):
        draft.pastDataMode = action.payload
        return
      case getType(appActions.togglePlay):
        draft.play = action.payload
        return
      case getType(appActions.incrementTimestamp):
        if (!draft.play) return
        const incrementor = (0.25 / 15) * draft.speed
        const addedMs = incrementor * 60000
        const newTimestamp = new Date(
          Math.min(+draft.selectedTimestamp + addedMs, +new Date())
        )
        draft.selectedTimestamp = newTimestamp
        return
      case getType(projectActions.toggleFlyout):
        draft.modelFetching = false //If closing flyout, set fetching to false
        return
      //These actions below should toggle if data is fetching or not
      //Will control when to open/close flyout/ loading spinners
      //Toggle fetching state
      case getType(tenantActionsAsync.deleteZone.success):
      case getType(tenantActionsAsync.deleteDeployment.success): //#0f0 dont think this is necessary
      case getType(tenantActionsAsync.deleteCalibration.success): //#0f0 dont think this is necessary
      case getType(tenantActionsAsync.deleteSite.success):
        //On delete should clear filtered site,zone selection
        draft.filteredSite = ""
        draft.filteredZone = ""
        // Deselects zone therefore, remove any priority loading on zones
        liveJobQueue.clearPriority()
        pastJobQueue.clearPriority()
        return
      case getType(tenantActionsAsync.updateZone.success):
        draft.configOpen = false //Close this after zone config successfully saves
        return
      //Also close on errors
      //Project api
      case getType(tenantActionsAsync.selectProject.request):
        //Clear all selections
        draft.filteredSite = ""
        draft.filteredZone = ""
        draft.mapCenterMain = action.payload.center
        // Clear any existing jobs in queue when project change
        liveJobQueue.clear()
        pastJobQueue.clear()
        return
      case getType(tenantActionsAsync.captureMoodCalibrationImage.request):
        draft.moodCornerLoading = action.payload.moodCornerLoading as any
        return
      case getType(tenantActionsAsync.captureMoodCalibrationImage.success):
        draft.moodCornerLoading = ""
        return
      case getType(tenantActionsAsync.captureMoodCalibrationImage.failure):
        draft.moodCornerLoading = ""
        return
      case getType(tenantActionsAsync.startMoodCalibration.request):
        // draft.moodCornerLoading = action.payload.moodCornerLoading as any
        return
      case getType(tenantActionsAsync.startMoodCalibration.success):
        //Clear fetching states
        draft.moodCornerLoading = ""
        return
      case getType(tenantActionsAsync.startMoodCalibration.failure):
        //Clear fetching states
        draft.moodCornerLoading = ""
        return
    }
  },
  initialAppState
)

export default appReducer

//Delays showing some components until period after tenant loads
export const showEpic: Epic<RootAction, RootAction, IState, Dependencies> = (
  action$,
  state$,
  dependencies
) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.init.success)),
    switchMap(action => {
      return timer(5000).pipe(
        take(1),
        map(v => appActions.show(true))
      )
    })
  )
}

export const fullscreenEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(appActionsAsync.toggleFullscreen.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        //Wrap to prevent during gatsby build
        const fullscreen = action.payload.fullscreen
        if (typeof window !== "undefined" && document.fullscreenEnabled) {
          if (fullscreen) {
            document.documentElement.requestFullscreen()
          } else {
            document.exitFullscreen()
          }
        }
      })
    })
  )
}

export const fullscreenListenerEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  //Listen to fullscreen events
  return new Observable<RootAction>(observer => {
    if (typeof window !== "undefined" && document.fullscreenEnabled) {
      document.addEventListener("fullscreenerror", error => {
        observer.next(appActionsAsync.toggleFullscreen.failure({ error }))
      })

      document.addEventListener("fullscreenchange", e => {
        observer.next(
          appActionsAsync.toggleFullscreen.success({
            fullscreen: !!document.fullscreenElement,
          })
        )
        console.log("fse:reached")
      })
    }
  })
}

//window.dispatchEvent(new Event('resize'));

export const toggleDrawerEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  //Fire resize events while drawer is toggling
  return action$.pipe(
    filter(isActionOf([appActions.toggleDrawer, appActions.toggleAlertDrawer])),
    switchMap(action => {
      interval(16)
        .pipe(
          tap(() => {
            typeof window !== "undefined" &&
              window.dispatchEvent(new Event("resize"))
          }),
          takeUntil(timer(700))
        )
        .subscribe()
      return new Observable<RootAction>()
    })
  )
}

//Fire some window resize events on card click
//To fix map rendering issues
export const resizeEpic: Epic<RootAction, RootAction, IState, Dependencies> = (
  action$,
  state$,
  dependencies
) => {
  //Fire resize events while drawer is toggling
  return action$.pipe(
    filter(isActionOf([appActions.setFilteredZone, appActions.resize])),
    switchMap(action => {
      interval(16)
        .pipe(
          tap(() => {
            typeof window !== "undefined" &&
              window.dispatchEvent(new Event("resize"))
          }),
          takeUntil(timer(200))
        )
        .subscribe()
      return new Observable<RootAction>()
    })
  )
}

//TODO: potentially go back and check performance impacts on constant interval
export const playEpic: Epic<RootAction, RootAction, IState, Dependencies> = (
  action$,
  state$,
  dependencies
) => {
  //Change the interval that the min selector plays at
  return action$.pipe(
    filter(
      isActionOf([
        tenantActionsAsync.init.success, //To trigger interval at start up
        appActions.setSpeed,
        appActions.togglePlay,
      ])
    ),
    switchMap(action => {
      const state = state$.value as IState
      const { speed, play } = state.app
      // return interval(15000 / speed).pipe(
      return interval(1000).pipe(
        switchMap(i => {
          return new Observable<RootAction>(observer => {
            if (!play) return
            observer.next(appActions.incrementTimestamp())
          })
        })
      )
    })
  )
}
