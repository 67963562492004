import { Epic } from "redux-observable"
import { Observable, interval } from "rxjs"
import { switchMap, filter, mergeMap } from "rxjs/operators"
import { DEFAULT_MAX_CUTOFFS } from "../../constants"
import { isActionOf } from "typesafe-actions"
import { tenantActionsAsync } from "../reducers/tenantReducer"
import { Dependencies } from "./../ReduxProvider"
import { generateSinceTimestamps } from "../reducers/dataReducerV2/functions/generateSinceTimestamps"
import { getCalibrationsByZoneId } from "../reducers/dataReducerV2/functions/getCalibrationsByZoneId"
import { getCalibrationAreas } from "../reducers/dataReducerV2/functions/getCalibrationAreas"
import { getCalibrationConfigs } from "../reducers/dataReducerV2/functions/getCalibrationConfigs"
import { patchPointerDates } from "../reducers/dataReducerV2/functions/patchPointerDates"
import { IState, RootAction } from "../reducers"
import { dataV2Actions, dataV2ActionsAsync } from "../reducers/dataReducerV2"
import JobQueue, { Job } from "./JobQueue"
import moment from "moment-timezone";
import {
  notificationActions,
  notificationActionsAsync
} from "../reducers/notifications"
import { selectedTimestampToMin } from "../functions/selectedTimestampToMin"
import { graphEndTimeSelector } from "../selectors/riskSelector/graphEndTimeSelector"
import { appActions } from "../reducers/appReducer"

//CONFIG
const ROUND_INTERVAL = 5000
const RANGE = 1000 * 60 * 60
// const CHUNK_DIVIDER = 12 // Divide RANGE into smaller chunks; 60 / 12 = 5 mins
const CHUNK_DIVIDER = 4 // Divide RANGE into smaller chunks; 60 / 4 = 15 mins
const RETRIES = 1
const CONCURRENCY = 2
const HOUR_MS = 60 * 60 * 1000

//Controller
//Queues are set to auto-run
export const liveJobQueue = JobQueue({ concurrency: CONCURRENCY });

export const dataV2InitEpicQueued: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(
      isActionOf([
        dataV2ActionsAsync.init.request,
      ])
    ),
    mergeMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState

        const zones = state.tenant.zones
        const zoneIds = Object.keys(zones)
        const deployments = state.tenant.deployments
        const calibrations = state.tenant.calibrations
        const compounds = state.tenant.compounds
        const selectedProjectId = state.tenant.selectedProjectId
        const selectedProjectTimezone = state.tenant.projects[selectedProjectId]?.timezone;
        
        // const end = roundDate(ROUND_INTERVAL)(new Date())
        const end = new Date()
        const start = new Date(end.getTime() - RANGE)
        // Get data from start of day for cumulative counting
        const ccStart = moment.tz(end.toISOString(), selectedProjectTimezone).startOf('day').toDate();


        async function initiateData() {
          const client = state.auth0.auth0Client
          const { apiGatewayUrl, notificationsUrl } = state.constants
          const token = client ? await client.getTokenSilently() : ""

          //Get the calendar info
          const setCounts = async () => {
            try {
              const countsByDay = await dependencies.dataAPI.getCountsByDay({
                token,
                apiGatewayUrl,
                selectedProjectId,
              })
              observer.next(dataV2Actions.setCountsByDay(countsByDay))
            } catch (error) {
              console.error(error); // Errors with calendar shouldn't block other functionality
            }
          }
          setCounts(); // Don't need to wait for calendar info

          //Get the recent notifications
          const getNotifications = async () => {
            try {
              const to = new Date()
              const from = new Date(+to - HOUR_MS)
              const recentNotifications = await dependencies.notificationsAPI.fetchNotificationsByTime(
                {
                  notificationsUrl,
                  from,
                  to,
                  projectId: selectedProjectId,
                  token,
                }
              )
              observer.next(
                notificationActions.setVariable(
                  "recentNotifications",
                  recentNotifications
                )
              )
            } catch (error) {
              console.error(error); // Errors with notifications shouldn't block other functionality
            }
          }
          getNotifications(); // Don't need to wait for notifications

          // Divide RANGE (end - start) into smaller chunks
          //  cumulative counting range always starts from beginning of day; has it's own chunk size
          const chunkSize = (end.getTime() - start.getTime()) / CHUNK_DIVIDER
          for (let i = 1; i <= CHUNK_DIVIDER; i++) {
            // Since timestamp will always be 1 chunk size behind end timestamp
            const sinceTimestamp = new Date(end.getTime() - (chunkSize * i))
            const toTimestamp = new Date(end.getTime() - (chunkSize * (i - 1)))
            // Cumulative counting needs to load a different volume of data
            //  load entire day in first fetch
            const sinceTimestampCC = i === 1 ? start : sinceTimestamp

            //Get Data
            Object.keys(zones).map(zoneId => {
              //Filter calibrations to grab
              const filteredCalibrations = getCalibrationsByZoneId(
                deployments,
                calibrations,
                zoneId
              )
              const compoundIds = Object.values(compounds)
                .filter(c => c.zone_id === zoneId)
                .map(c => c.calc_id)
              
              const zone = zones[zoneId]
              const projectId = zone.projectId

              //Pull out complianceCutoff to use from the zone config
              const complianceCutoff = zone.config?.complianceCutoff || 1.5
              const cutoffs = zone.config?.cutoffs || DEFAULT_MAX_CUTOFFS
              const geoAdjustment = zone.config?.geoAdjustment
              const groupLimit = zone.config?.groupLimit
              const groupThreshhold = zone.config?.groupThreshhold

              //CONSTRUCT JOB
              const job = async () => {
                const measurements = await dependencies.dataV2API.getMeasurements(
                  {
                    apiGatewayUrl,
                    token,
                    projectId,
                    zoneId,
                    aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                    calibrationConfigs: getCalibrationConfigs(
                      deployments,
                      filteredCalibrations
                    ),
                    calibrationAreas: getCalibrationAreas(filteredCalibrations),
                    complianceLimit: complianceCutoff,
                    cutoffs,
                    geoAdjustment,
                    hashKey: "test-key",
                    sinceTimestamps: generateSinceTimestamps(
                      filteredCalibrations,
                      sinceTimestamp,
                      sinceTimestampCC,
                      compoundIds
                    ),
                    toTimestamp: toTimestamp,
                    groupLimit,
                    groupThreshhold,
                  }
                )

                //RETURN DATA HERE
                observer.next(
                  dataV2ActionsAsync.init.success({
                    zoneIds,
                    zoneId,
                    measurements,
                  })
                )
              }
              let retries = 0;
              const onError = async (error: any) => {
                console.log(error)
                // Need to fail/succeed request otherwise fetching state could remain in limbo
                if (retries < RETRIES) {
                  retries++;
                  console.log("retrying", `${zoneId}-${i}`);
                  liveJobQueue.addJob({
                    id: `${zoneId}-${i}`,
                    fn: job,
                    errFn: onError
                  }) // zoneId-chunknumber
                } else {
                  observer.next(dataV2ActionsAsync.init.failure({ error }))
                }
              }

              //PERFORM JOB ON QUEUE
              liveJobQueue.addJob({
                id: `${zoneId}-${i}`,
                fn: job,
                errFn: onError
              })
            })
          }
        }
        initiateData().catch(error =>
          observer.next(dataV2ActionsAsync.init.failure({ error }))
        )
      })
    })
  )
}

//most recent ->
export const dataV2RefreshEpicQueued: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  const state = state$.value as IState
  return action$.pipe(
    filter(isActionOf([
      dataV2ActionsAsync.init.success,
      dataV2ActionsAsync.initV2.success
    ])),
    switchMap(action => {
      return interval(ROUND_INTERVAL).pipe(
        mergeMap(() => {
          return new Observable<RootAction>(observer => {
            const state = state$.value as IState
            const { tabActive, dashActive, refreshEnabled } = state.app
            if (!tabActive || !dashActive || !refreshEnabled) return

            async function refreshData() {
              const end = new Date()
              const start = new Date(end.getTime() - RANGE)
              const client = state.auth0.auth0Client
              const token = client ? await client.getTokenSilently() : ""
              const { apiGatewayUrl } = state.constants

              observer.next(dataV2ActionsAsync.refresh.request({}))

              const zones = state.tenant.zones
              const deployments = state.tenant.deployments
              const calibrations = state.tenant.calibrations
              const compounds = state.tenant.compounds

              // Get Notifications
              observer.next(notificationActionsAsync.fetchNotificationsByTime.request({
                to: end,
                from: start
              }))
              // Get Trigger Periods
              observer.next(notificationActionsAsync.fetchTriggerPeriods.request({
                start,
                end
              }))

              Object.keys(zones).map(zoneId => {
                  //Filter calibrations to grab
                  const filteredCalibrations = getCalibrationsByZoneId(
                    deployments,
                    calibrations,
                    zoneId
                  )
                  const compoundIds = Object.values(compounds)
                    .filter(c => c.zone_id === zoneId)
                    .map(c => c.calc_id)

                  const zone = zones[zoneId]
                  const projectId = zone.projectId

                  //Pull out complianceCutoff to use from the zone config
                  const complianceCutoff = zone.config?.complianceCutoff || 1.5
                  const cutoffs = zone.config?.cutoffs || DEFAULT_MAX_CUTOFFS
                  const geoAdjustment = zone.config?.geoAdjustment
                  const groupLimit = zone.config?.groupLimit
                  const groupThreshhold = zone.config?.groupThreshhold

                  const existingTimestamps = state.dataV2.pointerDates[zoneId] //TODO: this isnt grabbing anything
                  const sinceTimestamps = patchPointerDates(
                    existingTimestamps,
                    filteredCalibrations,
                    start,
                    compoundIds
                  )

                //CONSTRUCT JOB
                const job = async () => {
                  const measurements = await dependencies.dataV2API.getMeasurements(
                    {
                      apiGatewayUrl,
                      token,
                      projectId,
                      zoneId,
                      aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                      calibrationConfigs: getCalibrationConfigs(
                        deployments,
                        filteredCalibrations
                      ),
                      calibrationAreas: getCalibrationAreas(
                        filteredCalibrations
                      ),
                      complianceLimit: complianceCutoff,
                      cutoffs,
                      geoAdjustment,
                      hashKey: "test-key",
                      sinceTimestamps,
                      toTimestamp: end,
                      groupLimit,
                      groupThreshhold,
                    }
                  )

                  //Determine isLive after data is retrieved from server in case users
                  //  move away from latest data during data retrieval
                  //To make data stick to the live timestamp
                  //Check if it is currently live
                  const graphEndTime = graphEndTimeSelector(state$.value)
                  const min = selectedTimestampToMin(
                    state$.value.app.selectedTimestamp,
                    graphEndTime
                  )
                  const isLive = !state$.value.app.pastDataMode && min >= 60

                  observer.next(
                    dataV2ActionsAsync.refresh.success({
                      currentDate: end,
                      zoneId,
                      measurements,
                    })
                  )

                  //If it was live before,
                  //shift timestamp to the new end timestamp
                  if (isLive) {
                    const graphEndTime = graphEndTimeSelector(state$.value) //TODO: here isnt working
                    observer.next(appActions.setSelectedTimestamp(graphEndTime))
                  }
                }
                const onError = async (error: any) => {
                  console.log(error)
                  // Need to fail/succeed request otherwise fetching state could remain in limbo
                  observer.next(dataV2ActionsAsync.refresh.failure({ error }))
                }
                //PERFORM JOB ON QUEUE
                liveJobQueue.addJob({
                  id: zoneId,
                  fn: job,
                  errFn: onError
                })
              })
              liveJobQueue.startQueue()

              //Shift the graph slider so that it appears to stay in the same spot
            }
            refreshData().catch(error =>
              observer.next(dataV2ActionsAsync.refresh.failure({ error }))
            )
          })
        })
      )
    })
  )
}

/**
 * Loads one record for all zones to quickly populate dashboard with initial data
 */
export const dataV2InitV2EpicQueued: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(
      isActionOf([
        dataV2ActionsAsync.initV2.request,
      ])
    ),
    mergeMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState

        const zones = state.tenant.zones
        const zoneIds = Object.keys(zones)
        const deployments = state.tenant.deployments
        const calibrations = state.tenant.calibrations
        const compounds = state.tenant.compounds
        const selectedProjectId = state.tenant.selectedProjectId
        const selectedProjectTimezone = state.tenant.projects[selectedProjectId]?.timezone;
        const isCombinedDash = state.tenant.projects[selectedProjectId]?.default_dashboard_id === 1
        
        // const end = roundDate(ROUND_INTERVAL)(new Date())
        const end = new Date()
        const start = new Date(end.getTime() - RANGE)
        // Get data from start of day for cumulative counting
        const ccStart = moment.tz(end.toISOString(), selectedProjectTimezone).startOf('day').toDate();


        async function initiateData() {
          const client = state.auth0.auth0Client
          const { apiGatewayUrl, notificationsUrl } = state.constants
          const token = client ? await client.getTokenSilently() : ""

          //Get the calendar info
          const setCounts = async () => {
            try {
              const countsByDay = await dependencies.dataAPI.getCountsByDay({
                token,
                apiGatewayUrl,
                selectedProjectId,
              })
              observer.next(dataV2Actions.setCountsByDay(countsByDay))
            } catch (error) {
              console.error(error); // Errors with calendar shouldn't block other functionality
            }
          }
          setCounts(); // Don't need to wait for calendar info

          //Get the recent notifications
          const getNotifications = async () => {
            try {
              const to = new Date()
              const from = new Date(+to - HOUR_MS)
              const recentNotifications = await dependencies.notificationsAPI.fetchNotificationsByTime(
                {
                  notificationsUrl,
                  from,
                  to,
                  projectId: selectedProjectId,
                  token,
                }
              )
              observer.next(
                notificationActions.setVariable(
                  "recentNotifications",
                  recentNotifications
                )
              )
            } catch (error) {
              console.error(error); // Errors with notifications shouldn't block other functionality
            }
          }
          getNotifications(); // Don't need to wait for notifications

          let latestJobQueue = JobQueue({
            concurrency: CONCURRENCY,
            // Execute end when all jobs complete
            end: () => {
              observer.next(dataV2ActionsAsync.initV2.success({ zoneIds }))
            }
          });

          //Get Data
          Object.keys(zones).map(zoneId => {
            //Filter calibrations to grab
            const filteredCalibrations = getCalibrationsByZoneId(
              deployments,
              calibrations,
              zoneId
            )
            const compoundIds = Object.values(compounds)
              .filter(c => c.zone_id === zoneId)
              .map(c => c.calc_id)
            
            const zone = zones[zoneId]
            const projectId = zone.projectId

            //Pull out complianceCutoff to use from the zone config
            const complianceCutoff = zone.config?.complianceCutoff || 1.5
            const cutoffs = zone.config?.cutoffs || DEFAULT_MAX_CUTOFFS
            const geoAdjustment = zone.config?.geoAdjustment
            const groupLimit = zone.config?.groupLimit
            const groupThreshhold = zone.config?.groupThreshhold

            //CONSTRUCT JOB
            const job = async () => {
              const measurements = await dependencies.dataV2API.getLatestMeasurements(
                {
                  apiGatewayUrl,
                  token,
                  projectId,
                  zoneId,
                  aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                  calibrationConfigs: getCalibrationConfigs(
                    deployments,
                    filteredCalibrations
                  ),
                  calibrationAreas: getCalibrationAreas(filteredCalibrations),
                  complianceLimit: complianceCutoff,
                  cutoffs,
                  geoAdjustment,
                  hashKey: "test-key",
                  sinceTimestamps: generateSinceTimestamps(
                    filteredCalibrations,
                    start,
                    ccStart,
                    compoundIds,
                  ),
                  toTimestamp: end,
                  groupLimit,
                  groupThreshhold,
                }
              )

              //RETURN DATA HERE
              observer.next(
                dataV2Actions.initV2Merge(
                  zoneId,
                  measurements,
                )
              )
            }
            let retries = 0;
            const onError = async (error: any) => {
              console.log(error)
              // Need to fail/succeed request otherwise fetching state could remain in limbo
              if (retries < RETRIES) {
                retries++;
                console.log("retrying", `${zoneId}`);
                latestJobQueue.addJob({
                  id: `${zoneId}`,
                  fn: job,
                  errFn: onError
                }) // zoneId-chunknumber
              } else {
                observer.next(dataV2Actions.initV2Fail(error, zoneId))
              }
            }

            //PERFORM JOB ON QUEUE
            latestJobQueue.addJob({
              id: `${zoneId}`,
              fn: job,
              errFn: onError
            })
          })
          latestJobQueue.startQueue()
        }
        initiateData().catch(error => {
          console.log(error)
          observer.next(dataV2ActionsAsync.initV2.failure({ error }))
        })
        observer.next(dataV2ActionsAsync.initLoad.request({}))
      })
    })
  )
}

/**
 * Loads one record for all zones to quickly populate dashboard with initial data
 */
export const dataV2InitLoadEpicQueued: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(
      isActionOf([
        dataV2ActionsAsync.initLoad.request,
      ])
    ),
    mergeMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState

        const zones = state.tenant.zones
        const zoneIds = Object.keys(zones)
        const deployments = state.tenant.deployments
        const calibrations = state.tenant.calibrations
        const compounds = state.tenant.compounds
        const selectedProjectId = state.tenant.selectedProjectId
        const selectedProjectTimezone = state.tenant.projects[selectedProjectId]?.timezone
        const filteredZone = state.app.filteredZone
        
        // const end = roundDate(ROUND_INTERVAL)(new Date())
        const end = new Date()
        const start = new Date(end.getTime() - RANGE)
        // Get data from start of day for cumulative counting
        const ccStart = moment.tz(end.toISOString(), selectedProjectTimezone).startOf('day').toDate()


        async function initiateData() {
          const client = state.auth0.auth0Client
          const { apiGatewayUrl, notificationsUrl } = state.constants
          const token = client ? await client.getTokenSilently() : ""

          let fetchValueJobs: Job[] = []
          let fetchGroupingJobs: Job[] = []
          // Divide RANGE (end - start) into smaller chunks
          //  cumulative counting range always starts from beginning of day; has it's own chunk size
          const chunkSize = (end.getTime() - start.getTime()) / CHUNK_DIVIDER
          for (let i = 1; i <= CHUNK_DIVIDER; i++) {
            // Since timestamp will always be 1 chunk size behind end timestamp
            const sinceTimestamp = new Date(end.getTime() - (chunkSize * i))
            const toTimestamp = new Date(end.getTime() - (chunkSize * (i - 1)))

            //Get Data
            Object.keys(zones).map(zoneId => {
              //Filter calibrations to grab
              const filteredCalibrations = getCalibrationsByZoneId(
                deployments,
                calibrations,
                zoneId
              )
              const compoundIds = Object.values(compounds)
                .filter(c => c.zone_id === zoneId)
                .map(c => c.calc_id)
              
              const zone = zones[zoneId]
              const projectId = zone.projectId

              //Pull out complianceCutoff to use from the zone config
              const complianceCutoff = zone.config?.complianceCutoff || 1.5
              const cutoffs = zone.config?.cutoffs || DEFAULT_MAX_CUTOFFS
              const geoAdjustment = zone.config?.geoAdjustment
              const groupLimit = zone.config?.groupLimit
              const groupThreshhold = zone.config?.groupThreshhold

              //CONSTRUCT JOB
              const valueJob = async () => {
                const measurements = await dependencies.dataV2API.getMeasurementValues(
                  {
                    apiGatewayUrl,
                    token,
                    projectId,
                    zoneId,
                    aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                    calibrationConfigs: getCalibrationConfigs(
                      deployments,
                      filteredCalibrations
                    ),
                    calibrationAreas: getCalibrationAreas(filteredCalibrations),
                    complianceLimit: complianceCutoff,
                    cutoffs,
                    geoAdjustment,
                    hashKey: "test-key",
                    sinceTimestamps: generateSinceTimestamps(
                      filteredCalibrations,
                      sinceTimestamp,
                      sinceTimestamp,
                      compoundIds,
                    ),
                    toTimestamp: toTimestamp,
                    groupLimit,
                    groupThreshhold,
                  }
                )

                //RETURN DATA HERE
                observer.next(
                  dataV2ActionsAsync.initLoad.success({
                    zoneId,
                    measurements,
                    zoneIds
                  })
                )
              }
              const valueJobId = `v-${zoneId}-${i}`;
              let retries = 0;
              const onErrorValues = async (error: any) => {
                console.log(error)
                // Need to fail/succeed request otherwise fetching state could remain in limbo
                observer.next(dataV2ActionsAsync.initLoad.failure({ error }))
                if (retries < RETRIES) {
                  retries++;
                  console.log("retrying", valueJobId);
                  liveJobQueue.addJob({
                    id: valueJobId,
                    fn: valueJob,
                    errFn: onErrorValues
                  }) // zoneId-chunknumber
                }
              }

              //PERFORM JOB ON QUEUE
              const fetchValueJob = {
                id: valueJobId,
                fn: valueJob,
                errFn: onErrorValues
              }
              fetchValueJobs.push(fetchValueJob) // zoneId-chunknumber

              const groupingJob = async () => {
                const measurements = await dependencies.dataV2API.getMeasurementGroupings(
                  {
                    apiGatewayUrl,
                    token,
                    projectId,
                    zoneId,
                    aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                    calibrationConfigs: getCalibrationConfigs(
                      deployments,
                      filteredCalibrations
                    ),
                    calibrationAreas: getCalibrationAreas(filteredCalibrations),
                    complianceLimit: complianceCutoff,
                    cutoffs,
                    geoAdjustment,
                    hashKey: "test-key",
                    sinceTimestamps: generateSinceTimestamps(
                      filteredCalibrations,
                      sinceTimestamp,
                      sinceTimestamp,
                      compoundIds,
                    ),
                    toTimestamp: toTimestamp,
                    groupLimit,
                    groupThreshhold,
                  }
                )

                //RETURN DATA HERE
                observer.next(
                  dataV2ActionsAsync.initLoad.success({
                    zoneId,
                    measurements,
                    zoneIds
                  })
                )
              }
              const groupingJobId = `g-${zoneId}-${i}`;
              let groupingRetries = 0;
              const onErrorGroupings = async (error: any) => {
                console.log(error)
                // Need to fail/succeed request otherwise fetching state could remain in limbo
                observer.next(dataV2ActionsAsync.initLoad.failure({ error }))
                if (groupingRetries < RETRIES) {
                  groupingRetries++;
                  console.log("retrying", groupingJobId);
                  liveJobQueue.addJob({
                    id: groupingJobId,
                    fn: groupingJob,
                    errFn: onErrorGroupings
                  }) // zoneId-chunknumber
                }
              }

              const fetchGroupingJob = {
                id: groupingJobId,
                fn: groupingJob,
                errFn: onErrorGroupings
              };
              fetchGroupingJobs.push(fetchGroupingJob) // zoneId-chunknumber
            })
          }
          fetchValueJobs.forEach(valueJob => liveJobQueue.addJob(valueJob,
            // If job id has filtered zone, prioritise the job
            filteredZone && valueJob.id.includes(filteredZone) ? true: false
          ));
          fetchGroupingJobs.forEach(groupingJob => liveJobQueue.addJob(groupingJob,
            // If job id has filtered zone, prioritise the job
            filteredZone && groupingJob.id.includes(filteredZone) ? true: false
          ));
          liveJobQueue.startQueue();
        }
        initiateData().catch(error =>
          observer.next(dataV2ActionsAsync.initLoad.failure({ error }))
        )
      })
    })
  )
}