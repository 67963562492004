import axios from "axios"
import { isArray } from "lodash"
import qs from "qs"
import {
  Notifications,
  Triggers,
  TriggerPeriods,
  Topics,
  Subscribers
} from "../reducers/notifications"
import {
  NotificationsAttribute,
  TriggersAttribute,
  TriggerPeriodsAttribute,
  TopicsAttribute,
  SubscribersAttribute
} from "../reducers/notifications/@types"

export const notificationsAPI = {
  fetchNotifications: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    offset?: number
    limit: number //Max is 10
    alerts?: boolean
  }) => {
    const { notificationsUrl, token, projectId, offset, alerts } = input
    const limit = Math.min(10, input.limit)
    const res = await axios.get(
      `${notificationsUrl}/notifications/${projectId}?${qs.stringify({
        offset,
        limit,
        alerts,
      })}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const notificationsObj: Notifications = {}
    if (!isArray(res.data))
      throw new Error("notifications response body is not an array")
    res.data.forEach((r: NotificationsAttribute) => {
      notificationsObj[r.notificationId] = r
    })

    return notificationsObj as Notifications
  },
  fetchNotificationsByTime: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    from: Date
    to: Date
    alerts?: boolean
  }) => {
    const { notificationsUrl, token, projectId, from, to, alerts } = input
    const res = await axios.get(
      `${notificationsUrl}/notifications/${projectId}?${qs.stringify({
        from,
        to,
        alerts
      })}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const notificationsObj: Notifications = {}
    if (!isArray(res.data))
      throw new Error("notifications response body is not an array")
    res.data.forEach((r: NotificationsAttribute) => {
      notificationsObj[r.notificationId] = r
    })

    return notificationsObj as Notifications
  },
  createNotification: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    notification: NotificationsAttribute
  }) => {
    const { notificationsUrl, token, projectId, notification } = input
    const res = await axios.post(
      `${notificationsUrl}/notifications/${projectId}`,
      notification,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return res.data as NotificationsAttribute
  },
  updateNotifications: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    notificationIds: string[]
    body: {
      read?: boolean
      readBy?: string
      dismissed?: boolean
      dismissedBy?: string
      showToast?: boolean
    }
  }) => {
    const { notificationsUrl, token, projectId, notificationIds, body } = input
    const res = await axios.put(
      `${notificationsUrl}/notifications/${projectId}?${qs.stringify(
        { notificationIds },
        { arrayFormat: "comma" }
      )}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const notificationsObj: Notifications = {}
    if (!isArray(res.data))
      throw new Error("notifications response body is not an array")
    res.data.forEach((r: NotificationsAttribute) => {
      notificationsObj[r.notificationId] = r
    })

    return notificationsObj as Notifications
  },
  fetchTriggers: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
  }) => {
    const { notificationsUrl, token, projectId } = input
    const res = await axios.get(`${notificationsUrl}/triggers/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const triggersObj: Triggers = {}
    if (!isArray(res.data))
      throw new Error("notifications response body is not an array")
    res.data.forEach((r: TriggersAttribute) => {
      triggersObj[r.triggerId] = r
    })

    return triggersObj
  },
  createTrigger: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    trigger: TriggersAttribute
  }) => {
    const { notificationsUrl, token, projectId, trigger } = input
    const res = await axios.post(
      `${notificationsUrl}/triggers/${projectId}`,
      trigger,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return res.data as TriggersAttribute
  },
  updateTrigger: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    triggerId: string
    trigger: Partial<TriggersAttribute>
  }) => {
    const { notificationsUrl, token, projectId, trigger, triggerId } = input
    const res = await axios.put(
      `${notificationsUrl}/triggers/${projectId}/${triggerId}`,
      trigger,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return res.data as TriggersAttribute
  },
  deleteTrigger: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    triggerId: string
  }) => {
    const { notificationsUrl, token, projectId, triggerId } = input
    const res = await axios.delete(
      `${notificationsUrl}/triggers/${projectId}/${triggerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return res.data as TriggersAttribute
  },
  fetchTriggerPeriods: async (input: {
    notificationsUrl: string
    token: string
    projectId: string
    start: Date
    end: Date
  }) => {
    const { notificationsUrl, token, projectId, start, end } = input
    const res = await axios.get(
      `${notificationsUrl}/triggerPeriods/${projectId}`,
      {
        params: { start, end },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return res.data
  },
  fetchTopics: async (input: {
    apiGatewayUrl: string
    token: string
    projectId: string
  }) => {
    const { apiGatewayUrl, token, projectId } = input
    const res = await axios.get(`${apiGatewayUrl}/sms/topics/${projectId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const topicsObj: Topics = {}
    if (!isArray(res.data))
      throw new Error("Topics response body is not an array")
    res.data.forEach((r: TopicsAttribute) => {
      topicsObj[r.topicId] = r
    })

    return topicsObj
  },
  updateTopic: async (input: {
    apiGatewayUrl: string
    token: string
    topicId: string
    topic: Partial<TopicsAttribute>
  }) => {
    const { apiGatewayUrl, token, topicId, topic } = input
    const res = await axios.put(`${apiGatewayUrl}/sms/topic/${topicId}`,
    topic,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data as TopicsAttribute
  },
  fetchSubscribers: async (input: {
    apiGatewayUrl: string
    token: string
    topicId: string
  }) => {
    const { apiGatewayUrl, token, topicId } = input
    const res = await axios.get(`${apiGatewayUrl}/sms/subscriptions/${topicId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const subscribersObj: Subscribers = {}
    if (!isArray(res.data))
      throw new Error("Subscribers response body is not an array")
    res.data.forEach((r: SubscribersAttribute) => {
      subscribersObj[r.subscriberId] = r
    })

    return subscribersObj
  },
  createTopic: async (input: {
    apiGatewayUrl: string
    token: string
    name: string
    triggerId: string
  }) => {
    const { apiGatewayUrl, name, triggerId, token } = input
    const res = await axios.post(`${apiGatewayUrl}/sms/topic`,
    { name, triggerId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res.data)
      throw new Error("No topic")

    return res.data && res.data[0];
  },
  deleteTopic: async (input: {
    apiGatewayUrl: string
    token: string
    topicId: string
  }) => {
    const { apiGatewayUrl, topicId, token } = input
    const res = await axios.delete(`${apiGatewayUrl}/sms/topic/${topicId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res.data)
      throw new Error("No topic")

    return res.data;
  },
  unsubscribe: async (input: {
    apiGatewayUrl: string
    token: string
    subscriberId: string
  }) => {
    const { apiGatewayUrl, subscriberId, token } = input
    const res = await axios.post(`${apiGatewayUrl}/sms/unsubscribe`,
    { subscriberId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res.data)
      throw new Error("Failed unsubscribe")

    return res.data;
  },
  subscribe: async (input: {
    apiGatewayUrl: string
    token: string
    topicId: string
    protocol: string
    endpoint: string
    name: string
  }) => {
    const { apiGatewayUrl, token, topicId, protocol, endpoint, name } = input
    const res = await axios.post(`${apiGatewayUrl}/sms/subscribe`,
    { topicId, protocol, endpoint, name },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res.data)
      throw new Error("Failed subscribe")

    return res.data;
  },
  confirmSubscription: async (input: {
    apiGatewayUrl: string
    token: string
    topicId: string
    verificationCode: string
    name: string
    endpoint: string
  }) => {
    const { apiGatewayUrl, token, topicId, verificationCode, name, endpoint } = input
    const res = await axios.post(`${apiGatewayUrl}/sms/confirmSubscription`,
    { topicId, token: verificationCode, name, endpoint },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res.data)
      throw new Error("Failed subscription confirm")

    return res.data;
  },
}
