import { IState, RootAction } from "../reducers"
import axios from "axios"
import { Epic } from "redux-observable"
import { switchMap, filter } from "rxjs/operators"
import { Observable } from "rxjs"
import { isActionOf, PayloadActionCreator } from "typesafe-actions"
import { Dependencies } from "../ReduxProvider"

export type ModelType =
  | "sites"
  | "zones"
  | "deployments"
  | "devices"
  | "calibrations"
  | "projects"
export const deleteModel = (apiGatewayUrl: string) => (
  modelType: ModelType
) => async (state: IState, action: any) => {
  const model = state.tenant[modelType]
  const client = state.auth0.auth0Client
  const token = client ? await client.getTokenSilently() : ""

  //Extract from action
  const id = action.payload.id

  //Fetch API
  const res = await axios.delete(`${apiGatewayUrl}/${modelType}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })

  //Handle return
  // if (res && res.data > 0) {
  //   //Check if any rows deleted
  //   return id
  // } else {
  //   throw new Error(`${modelType} could not delete`)
  // }
  if (res?.data) {
    //Check if any rows deleted
    return id
  } else {
    throw new Error(`${modelType} could not delete`)
  }
}

export const createDeleteEpic = (
  asyncAction: {
    request: PayloadActionCreator<any, { id: string }>
    success: PayloadActionCreator<any, any>
    failure: PayloadActionCreator<any, any>
  },
  model: ModelType
): Epic<RootAction, RootAction, IState, Dependencies> => (
  action$,
  state$,
  dependencies
) => {
  return action$.pipe(
    filter(isActionOf(asyncAction.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const apiGatewayUrl = state.constants.apiGatewayUrl
        deleteModel(apiGatewayUrl)(model)(state, action)
          .then(id =>
            observer.next(
              asyncAction.success({
                id,
              })
            )
          )
          .catch(error => observer.next(asyncAction.failure({ error })))
      })
    })
  )
}
