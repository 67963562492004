/**
 * Given an interval (in ms), and a date, rounds the date
 *
 * e.g. 11:43:43 rounded by a 15s interval becomes 11:43:30
 *
 * 06:30:57 -> 06:30:45
 *
 * 06:31:36 -> 06:31:30
 */
export function roundDate(ms: number) {
  return (date: Date) => new Date(~~(date.getTime() / ms) * ms)
}
