import { ICalibration } from "../../../../@types/ICalibration"
import { MetricValues, PointerDates } from "../@types"

export const generateMetricDates = (date: Date) => ({
  d: date,
  m: date,
  f: date,
  s: date,
  c: date,
  h: date,
  n: date,
})

/**
 * Handles these cases:
 * - If there are null values (replace with start date)
 * - If there are calibrations that got added
 * - If there are calibrations that got deleted
 */
export const patchPointerDates = (
  pointerDates: PointerDates,
  filteredCalibrations: ICalibration[],
  startDate: Date,
  filteredCompoundIds?: string[],
) => {
  const ids = filteredCalibrations.map(c => `${c.calibrationId}`)
  const newPointerDates: PointerDates = {
    data: {},
    heatmaps: {},
    images: {},
    compound: {},
  }
  //For data
  const dataCalibrationIds = Object.keys(pointerDates?.data || {})
  dataCalibrationIds.forEach(calibrationId => {
    if (!ids.includes(calibrationId)) return
    const metricValues = pointerDates.data[calibrationId]
    newPointerDates.data[calibrationId] = generateMetricDates(startDate)
    Object.keys(metricValues).forEach(m => {
      const metric = m as keyof MetricValues<any>
      const date = metricValues[metric]
      if (date) newPointerDates.data[calibrationId][metric] = date
    })
  })
  //Generate pointers for new calibrations that are added
  ids.forEach(newCalibrationId => {
    if (dataCalibrationIds.includes(newCalibrationId)) return
    newPointerDates.data[newCalibrationId] = generateMetricDates(startDate)
  })
  const compoundIds = Object.keys(pointerDates?.compound || {})
  compoundIds.forEach(compoundId => {
    if (!filteredCompoundIds.includes(compoundId)) return
    const date = pointerDates.compound[compoundId]
    newPointerDates.compound[compoundId] = date ? date : startDate
  })

  //For heatmaps
  const heatmapCalibrationIds = Object.keys(pointerDates?.heatmaps || {})
  heatmapCalibrationIds.forEach(calibrationId => {
    if (!ids.includes(calibrationId)) return
    const metricValues = pointerDates.heatmaps[calibrationId]
    newPointerDates.heatmaps[calibrationId] = generateMetricDates(startDate)
    Object.keys(metricValues).forEach(m => {
      const metric = m as keyof MetricValues<any>
      const date = metricValues[metric]
      if (date) newPointerDates.heatmaps[calibrationId][metric] = date
    })
  })
  //Generate pointers for new calibrations that are added
  ids.forEach(newCalibrationId => {
    if (heatmapCalibrationIds.includes(newCalibrationId)) return
    newPointerDates.heatmaps[newCalibrationId] = generateMetricDates(startDate)
  })

  //For images
  const imageCalibrationIds = Object.keys(pointerDates?.images || {})
  imageCalibrationIds.forEach(calibrationId => {
    if (!ids.includes(calibrationId)) return
    const date = pointerDates.images[calibrationId]
    newPointerDates.images[calibrationId] = date ? date : startDate
  })
  //Generate pointers for new calibrations that are added
  ids.forEach(newCalibrationId => {
    if (imageCalibrationIds.includes(newCalibrationId)) return
    newPointerDates.images[newCalibrationId] = startDate
  })

  return newPointerDates
}
