import { TriggerPeriodsAttribute } from "../../notifications/@types";
import { TriggerPeriods } from "../../notifications";
import { MetricValues } from "../../dataReducerV2/@types";


export const formatTriggerPeriods = (triggerPeriods: TriggerPeriodsAttribute[]) => {
    let formatted: MetricValues<TriggerPeriods> = {
        d: {},
        m: {},
        h: {},
        s: {},
        c: {},
        f: {},
    };
    triggerPeriods.forEach((triggerPeriod) => {
        const { triggerId, start, metric } = triggerPeriod;
        if (!formatted[metric][triggerId]) formatted[metric][triggerId] = {};
        formatted[metric][triggerId][start] = triggerPeriod; 
    });
    return formatted;
}