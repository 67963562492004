import React from "react";

export const createCustomToast = (
    id: string,
    title: string,
    color: string,
    text: string,
): {
    id: string,
    title: React.ReactNode
    color: any
    iconType?: any,
    toastLifeTimeMs?: number,
    text: React.ReactChild
} | null => {
    return {
        id,
        title,
        color,
        text: <p>{text}</p>
    }
}