import axios from "axios"
import { UserSettings } from "../../@types/IUserProfileV2"

export const auth0API = {
  changePassword: async (input: {
    oauthIssuer: string
    oauthClientId: string
    email: string
    token: string
  }) => {
    const { oauthIssuer, oauthClientId, email, token } = input
    const res = await axios.post(
      `https://${oauthIssuer}/dbconnections/change_password`,
      {
        client_id: oauthClientId,
        email,
        connection: `Username-Password-Authentication`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return res
  },
  editProfile: async (input: {
    apiGatewayUrl: string
    firstName: string
    lastName: string
    phone: string
    userSettings?: UserSettings
    token: string
  }) => {
    const { apiGatewayUrl, firstName, lastName, phone, userSettings, token } = input
    return await axios.put(
      `${apiGatewayUrl}/userProfileV2/`,
      {
        firstName,
        lastName,
        phone,
        userSettings,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  },
  domains: async (input: {
    apiGatewayUrl: string
    userId: string
    token: string
  }) => {
    const { apiGatewayUrl, userId, token } = input
    return await axios.post(
      `${apiGatewayUrl}/domains/`,
      {
        userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  },
}
