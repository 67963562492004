import { Epic } from "redux-observable"
import { Observable } from "rxjs"
import { switchMap, mergeMap, filter } from "rxjs/operators"
import { DEFAULT_MAX_CUTOFFS } from "../../constants"
import { isActionOf } from "typesafe-actions"
import { Dependencies } from "./../ReduxProvider"
import { generateSinceTimestamps } from "../reducers/dataReducerV2/functions/generateSinceTimestamps"
import { getCalibrationsByZoneId } from "../reducers/dataReducerV2/functions/getCalibrationsByZoneId"
import { getCalibrationAreas } from "../reducers/dataReducerV2/functions/getCalibrationAreas"
import { getCalibrationConfigs } from "../reducers/dataReducerV2/functions/getCalibrationConfigs"
import { IState, RootAction } from "../reducers"
import { dataV2Actions, dataV2ActionsAsync } from "../reducers/dataReducerV2"
import JobQueue, { Job } from "./JobQueue"
import moment from "moment-timezone";
import { notificationActions, notificationActionsAsync } from "../reducers/notifications"

//CONFIG
const ROUND_INTERVAL = 5000
const RANGE = 1000 * 60 * 60 // 60 minutes
const CHUNK_DIVIDER = 4 // Divide RANGE into smaller chunks; 60 / 4 = 15 mins
// const CHUNK_DIVIDER = 12 // Divide RANGE into smaller chunks; 60 / 12 = 5 mins
const RETRIES = 1
const CONCURRENCY = 2

//Controller
//Queue is set to auto-run
export const pastJobQueue = JobQueue({ concurrency: CONCURRENCY });

export const pastDataV2FetchEpicQueued: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(dataV2ActionsAsync.fetchPast.request)),
    mergeMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const selectedDate = action.payload.selectedDate

        const zones = state.tenant.zones
        const deployments = state.tenant.deployments
        const calibrations = state.tenant.calibrations
        const compounds = state.tenant.compounds
        const selectedProjectId = state.tenant.selectedProjectId
        const selectedProjectTimezone = state.tenant.projects[selectedProjectId]?.timezone;

        // const end = roundDate(ROUND_INTERVAL)(new Date(selectedDate))
        const end = new Date(selectedDate)
        const start = new Date(end.getTime() - RANGE)
        // Get data from start of day for cumulative counting
        const ccStart = moment.tz(end.toISOString(), selectedProjectTimezone).startOf('day').toDate();

        async function fetchData() {
          const client = state.auth0.auth0Client
          const { apiGatewayUrl, notificationsUrl } = state.constants
          const token = client ? await client.getTokenSilently() : ""

          //Get the recent notifications
          const getPastNotifications = async () => {
            try {
              const pastNotifications = await dependencies.notificationsAPI.fetchNotificationsByTime(
                {
                  notificationsUrl,
                  from: start,
                  to: end,
                  projectId: selectedProjectId,
                  token,
                }
              )
              observer.next(
                notificationActions.setVariable(
                  "pastNotifications",
                  pastNotifications
                )
              )
            } catch (error) {
              console.error(error);
            }
          } 
          getPastNotifications();

          //If there are no zones, return a success anyway to reset fetching status
          if (Object.keys(zones).length === 0) {
            observer.next(
              dataV2ActionsAsync.fetchPast.success({
                zoneId: "", //Blank zoneId will cancel saving measurements
                measurements: undefined,
                cutoff: new Date()
              })
            )
          }

          // Divide RANGE (end - start) into smaller chunks
          //  cumulative counting range always starts from beginning of day; has it's own chunk size
          const chunkSize = (end.getTime() - start.getTime()) / CHUNK_DIVIDER
          for (let i = 1; i <= CHUNK_DIVIDER; i++) {
            // Since timestamp will always be 1 chunk size behind end timestamp
            const sinceTimestamp = new Date(end.getTime() - (chunkSize * i))
            const toTimestamp = new Date(end.getTime() - (chunkSize * (i - 1)))
            // Cumulative counting needs to load a different volume of data
            //  load entire day in first fetch
            const sinceTimestampCC = i === 1 ? start : sinceTimestamp

            Object.keys(zones).map(zoneId => {
                //Filter calibrations to grab
                const filteredCalibrations = getCalibrationsByZoneId(
                  deployments,
                  calibrations,
                  zoneId
                )
                const compoundIds = Object.values(compounds)
                  .filter(c => c.zone_id === zoneId)
                  .map(c => c.calc_id)
                
                const zone = zones[zoneId]
                const projectId = zone.projectId

                //Pull out complianceCutoff to use from the zone config
                const complianceCutoff = zone.config?.complianceCutoff || 1.5
                const cutoffs = zone.config?.cutoffs || DEFAULT_MAX_CUTOFFS
                const groupLimit = zone.config?.groupLimit
                const groupThreshhold = zone.config?.groupThreshhold
                const geoAdjustment = zone.config?.geoAdjustment
              //CONSTRUCT JOB
              const job = async () => {
                const measurements = await dependencies.dataV2API.getMeasurements(
                  {
                    apiGatewayUrl,
                    token,
                    projectId,
                    zoneId,
                    aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                    calibrationConfigs: getCalibrationConfigs(
                      deployments,
                      filteredCalibrations
                    ),
                    calibrationAreas: getCalibrationAreas(filteredCalibrations),
                    complianceLimit: complianceCutoff,
                    cutoffs,
                    geoAdjustment,
                    hashKey: "test-key",
                    sinceTimestamps: generateSinceTimestamps(
                      filteredCalibrations,
                      sinceTimestamp,
                      sinceTimestampCC,
                      compoundIds,
                    ),
                    toTimestamp: toTimestamp,
                    groupLimit,
                    groupThreshhold,
                  }
                )

                //RETURN DATA HERE
                observer.next(
                  dataV2ActionsAsync.fetchPast.success({
                    zoneId,
                    measurements,
                    cutoff: start
                  })
                )
              }
              let retries = 0;
              const onError = async (error: any) => {
                console.log(error)
                // Need to fail/succeed request otherwise fetching state could remain in limbo
                observer.next(dataV2ActionsAsync.fetchPast.failure({ error }))
                if (retries < RETRIES) {
                  retries++;
                  console.log("retrying", `${zoneId}-${i}`);
                  pastJobQueue.addJob({
                    id: `${zoneId}-${i}`,
                    fn: job,
                    errFn: onError
                  })
                }
              }

              //PERFORM JOB ON QUEUE
              pastJobQueue.addJob({
                id: `${zoneId}-${i}`,
                fn: job,
                errFn: onError
              })
            })
          }
        }
        fetchData().catch(error =>
          observer.next(dataV2ActionsAsync.fetchPast.failure({ error }))
        )
      })
    })
  )
}

/**
 * Loads one record for all zones to quickly populate dashboard with initial data
 */
export const pastDataV2InitFetchEpicQueued: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(dataV2ActionsAsync.fetchPastInit.request)),
    mergeMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const selectedDate = action.payload.selectedDate

        const zones = state.tenant.zones
        const deployments = state.tenant.deployments
        const calibrations = state.tenant.calibrations
        const compounds = state.tenant.compounds
        const selectedProjectId = state.tenant.selectedProjectId
        const selectedProjectTimezone = state.tenant.projects[selectedProjectId]?.timezone;

        // const end = roundDate(ROUND_INTERVAL)(new Date(selectedDate))
        const end = new Date(selectedDate)
        const start = new Date(end.getTime() - RANGE)
        // Get data from start of day for cumulative counting
        const ccStart = moment.tz(end.toISOString(), selectedProjectTimezone).startOf('day').toDate();

        async function fetchData() {
          const client = state.auth0.auth0Client
          const { apiGatewayUrl, notificationsUrl } = state.constants
          const token = client ? await client.getTokenSilently() : ""

          //Get the recent notifications
          const getPastNotifications = async () => {
            try {
              const pastNotifications = await dependencies.notificationsAPI.fetchNotificationsByTime(
                {
                  notificationsUrl,
                  from: start,
                  to: end,
                  projectId: selectedProjectId,
                  token,
                }
              )
              observer.next(
                notificationActions.setVariable(
                  "pastNotifications",
                  pastNotifications
                )
              )
            } catch (error) {
              console.error(error);
            }
          } 
          getPastNotifications();

          // Get trigger periods
          observer.next(notificationActionsAsync.fetchTriggerPeriods.request({
            start, end, past: true
          }))

          //If there are no zones, return a success anyway to reset fetching status
          if (Object.keys(zones).length === 0) {
            observer.next(
              dataV2ActionsAsync.fetchPastInit.success({
                zoneId: "", //Blank zoneId will cancel saving measurements
                measurements: undefined,
                selectedDate
              })
            )
          }

          let latestJobQueue = JobQueue({
            concurrency: CONCURRENCY,
            // Execute end when all jobs complete
            end: () => {
              observer.next(dataV2ActionsAsync.fetchPastInit.success({}))
            }
          });

          Object.keys(zones).map(zoneId => {
              //Filter calibrations to grab
              const filteredCalibrations = getCalibrationsByZoneId(
                deployments,
                calibrations,
                zoneId
              )
              const compoundIds = Object.values(compounds)
                .filter(c => c.zone_id === zoneId)
                .map(c => c.calc_id)
              
              const zone = zones[zoneId]
              const projectId = zone.projectId

              //Pull out complianceCutoff to use from the zone config
              const complianceCutoff = zone.config?.complianceCutoff || 1.5
              const cutoffs = zone.config?.cutoffs || DEFAULT_MAX_CUTOFFS
              const groupLimit = zone.config?.groupLimit
              const groupThreshhold = zone.config?.groupThreshhold
              const geoAdjustment = zone.config?.geoAdjustment
            //CONSTRUCT JOB
            const job = async () => {
              const measurements = await dependencies.dataV2API.getLatestMeasurements(
                {
                  apiGatewayUrl,
                  token,
                  projectId,
                  zoneId,
                  aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                  calibrationConfigs: getCalibrationConfigs(
                    deployments,
                    filteredCalibrations
                  ),
                  calibrationAreas: getCalibrationAreas(filteredCalibrations),
                  complianceLimit: complianceCutoff,
                  cutoffs,
                  geoAdjustment,
                  hashKey: "test-key",
                  sinceTimestamps: generateSinceTimestamps(
                    filteredCalibrations,
                    start,
                    ccStart,
                    compoundIds,
                  ),
                  toTimestamp: end,
                  groupLimit,
                  groupThreshhold,
                }
              )

              //RETURN DATA HERE
              observer.next(
                dataV2Actions.pastInitMerge(
                  zoneId,
                  measurements,
                  start
                )
              )
            }
            // May be better to move retry logic into reducer
            let retries = 0;
            const onError = async (error: any) => {
              console.log(error)
              // Need to fail/succeed request otherwise fetching state could remain in limbo
              if (retries < RETRIES) {
                retries++;
                console.log("retrying", `${zoneId}`);
                latestJobQueue.addJob({
                  id: `${zoneId}`,
                  fn: job,
                  errFn: onError
                })
              } else {
                observer.next(dataV2Actions.pastInitFail(error, zoneId))
              }
            }

            //PERFORM JOB ON QUEUE
            latestJobQueue.addJob({
              id: `${zoneId}`,
              fn: job,
              errFn: onError
            })
          })
          latestJobQueue.startQueue()
        }
        fetchData().catch(error =>
          observer.next(dataV2ActionsAsync.fetchPastInit.failure({ error }))
        )
        observer.next(dataV2ActionsAsync.fetchPastLoad.request({ selectedDate }))
      })
    })
  )
}

/**
 * Loads all measurement data for all zones in chunks
 */
export const pastDataV2LoadFetchEpicQueued: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(dataV2ActionsAsync.fetchPastLoad.request)),
    // filter(isActionOf(dataV2ActionsAsync.fetchPastInit.success)),
    mergeMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const selectedDate = action.payload.selectedDate

        const zones = state.tenant.zones
        const deployments = state.tenant.deployments
        const calibrations = state.tenant.calibrations
        const compounds = state.tenant.compounds
        const selectedProjectId = state.tenant.selectedProjectId
        const selectedProjectTimezone = state.tenant.projects[selectedProjectId]?.timezone
        const filteredZone = state.app.filteredZone

        // const end = roundDate(ROUND_INTERVAL)(new Date(selectedDate))
        const end = new Date(selectedDate)
        const start = new Date(end.getTime() - RANGE)
        // Get data from start of day for cumulative counting
        const ccStart = moment.tz(end.toISOString(), selectedProjectTimezone).startOf('day').toDate()

        async function fetchData() {
          const client = state.auth0.auth0Client
          const { apiGatewayUrl } = state.constants
          const token = client ? await client.getTokenSilently() : ""

          //If there are no zones, return a success anyway to reset fetching status
          if (Object.keys(zones).length === 0) {
            observer.next(
              dataV2ActionsAsync.fetchPastLoad.success({
                zoneId: "", //Blank zoneId will cancel saving measurements
                measurements: undefined,
                cutoff: new Date()
              })
            )
          }

          let fetchValueJobs: Job[] = []
          let fetchGroupingJobs: Job[] = []
          // Divide RANGE (end - start) into smaller chunks
          //  cumulative counting range always starts from beginning of day; has it's own chunk size
          const chunkSize = (end.getTime() - start.getTime()) / CHUNK_DIVIDER
          for (let i = 1; i <= CHUNK_DIVIDER; i++) {
            // Since timestamp will always be 1 chunk size behind end timestamp
            const sinceTimestamp = new Date(end.getTime() - (chunkSize * i))
            const toTimestamp = new Date(end.getTime() - (chunkSize * (i - 1)))

            Object.keys(zones).map(zoneId => {
              //Filter calibrations to grab
              const filteredCalibrations = getCalibrationsByZoneId(
                deployments,
                calibrations,
                zoneId
              )
              const compoundIds = Object.values(compounds)
                .filter(c => c.zone_id === zoneId)
                .map(c => c.calc_id)
              
              const zone = zones[zoneId]
              const projectId = zone.projectId

              //Pull out complianceCutoff to use from the zone config
              const complianceCutoff = zone.config?.complianceCutoff || 1.5
              const cutoffs = zone.config?.cutoffs || DEFAULT_MAX_CUTOFFS
              const groupLimit = zone.config?.groupLimit
              const groupThreshhold = zone.config?.groupThreshhold
              const geoAdjustment = zone.config?.geoAdjustment
              //CONSTRUCT JOB
              const valueJob = async () => {
                const measurements = await dependencies.dataV2API.getMeasurementValues(
                  {
                    apiGatewayUrl,
                    token,
                    projectId,
                    zoneId,
                    aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                    calibrationConfigs: getCalibrationConfigs(
                      deployments,
                      filteredCalibrations
                    ),
                    calibrationAreas: getCalibrationAreas(filteredCalibrations),
                    complianceLimit: complianceCutoff,
                    cutoffs,
                    geoAdjustment,
                    hashKey: "test-key",
                    sinceTimestamps: generateSinceTimestamps(
                      filteredCalibrations,
                      sinceTimestamp,
                      sinceTimestamp,
                      compoundIds,
                    ),
                    toTimestamp: toTimestamp,
                    groupLimit,
                    groupThreshhold,
                  }
                )

                //RETURN DATA HERE
                observer.next(
                  dataV2ActionsAsync.fetchPastLoad.success({
                    zoneId,
                    measurements,
                    cutoff: start
                  })
                )
              }
              const valueJobId = `v-${zoneId}-${i}`
              let retries = 0
              const onErrorValues = async (error: any) => {
                console.log(error)
                // Need to fail/succeed request otherwise fetching state could remain in limbo
                observer.next(dataV2ActionsAsync.fetchPastLoad.failure({ error }))
                if (retries < RETRIES) {
                  retries++;
                  console.log("retrying", valueJobId);
                  pastJobQueue.addJob({
                    id: valueJobId,
                    fn: valueJob,
                    errFn: onErrorValues
                  }) // zoneId-chunknumber
                }
              }

              //PERFORM JOB ON QUEUE
              const fetchValueJob = {
                id: valueJobId,
                fn: valueJob,
                errFn: onErrorValues
              };
              fetchValueJobs.push(fetchValueJob) // zoneId-chunknumber

              const groupingJob = async () => {
                const measurements = await dependencies.dataV2API.getMeasurementGroupings(
                  {
                    apiGatewayUrl,
                    token,
                    projectId,
                    zoneId,
                    aggregationWindow: ROUND_INTERVAL / 1000, // Its in seconds
                    calibrationConfigs: getCalibrationConfigs(
                      deployments,
                      filteredCalibrations
                    ),
                    calibrationAreas: getCalibrationAreas(filteredCalibrations),
                    complianceLimit: complianceCutoff,
                    cutoffs,
                    geoAdjustment,
                    hashKey: "test-key",
                    sinceTimestamps: generateSinceTimestamps(
                      filteredCalibrations,
                      sinceTimestamp,
                      sinceTimestamp,
                      compoundIds,
                    ),
                    toTimestamp: toTimestamp,
                    groupLimit,
                    groupThreshhold,
                  }
                )

                //RETURN DATA HERE
                observer.next(
                  dataV2ActionsAsync.fetchPastLoad.success({
                    zoneId,
                    measurements,
                    cutoff: start
                  })
                )
              }
              const groupingJobId = `g-${zoneId}-${i}`
              // May be better to move retry logic into reducer
              let groupingRetries = 0
              const onErrorGroupings = async (error: any) => {
                console.log(error)
                // Need to fail/succeed request otherwise fetching state could remain in limbo
                if (groupingRetries < RETRIES) {
                  groupingRetries++;
                  console.log("retrying", groupingJobId);
                  pastJobQueue.addJob({
                    id: groupingJobId,
                    fn: groupingJob,
                    errFn: onErrorGroupings
                  })
                } else {
                  observer.next(dataV2ActionsAsync.fetchPastLoad.failure({ error }))
                }
              }

              const fetchGroupingJob = {
                id: groupingJobId,
                fn: groupingJob,
                errFn: onErrorGroupings
              };
              fetchGroupingJobs.push(fetchGroupingJob) // zoneId-chunknumber
            })
          }
          // fetchValueJobs.forEach(valueJob => addJob(valueJob.id, valueJob.fn, valueJob.errFn));
          // fetchGroupingJobs.forEach(groupingJob => addJob(groupingJob.id, groupingJob.fn, groupingJob.errFn));
          fetchValueJobs.forEach(valueJob => pastJobQueue.addJob(valueJob,
            // If job id has filtered zone, prioritise the job
            filteredZone && valueJob.id.includes(filteredZone) ? true: false
          ))
          fetchGroupingJobs.forEach(groupingJob => pastJobQueue.addJob(groupingJob,
            // If job id has filtered zone, prioritise the job
            filteredZone && groupingJob.id.includes(filteredZone) ? true: false
          ))
          pastJobQueue.startQueue()
        }
        fetchData().catch(error =>
          observer.next(dataV2ActionsAsync.fetchPastLoad.failure({ error }))
        )
      })
    })
  )
}
