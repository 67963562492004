import { produce, Draft } from "immer"
import { Epic } from "redux-observable"
import { Observable } from "rxjs"
import { switchMap, filter } from "rxjs/operators"
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import { IState, RootAction } from "."
import { Dependencies } from "../ReduxProvider"
import { IProject } from "../../@types/IProject"
import { IDeployment } from "../../@types/IDeployment"
import { userOrganisationSelector } from "../selectors/tenantSelectors"
import { saveAs } from "file-saver"

export const DEFAULT_HOST_NAME = "ubuntu"
export const DEFAULT_CIDR = "192.168.1.0/24"
export const DEFAULT_REGION = process.env.AWS_REGION || "ap-southeast-2"
export const DEFAULT_IP = "192.168.1.0"
export const DEFAULT_DEVICE_COUNT = 5

export const REGIONS = [
  { label: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
  { label: "Europe (Paris)", value: "eu-west-3" },
  { label: "US West (Oregon)", value: "us-west-2" },
]

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export type EnvState =
  | ""
  | "PROVISION_PENDING"
  | "PROVISION_PROCESSING"
  | "PROVISION_COMPLETED"
  | "PROVISION_FAILED"
  | "DESTROY_PENDING"
  | "DESTROY_PROCESSING"
  | "DESTROY_COMPLETED"
  | "DESTROY_FAILED"

export interface NetworkSetupState {
  deleteOpen: boolean
  clientVpnProfile: string
  cidr: string
  region: string
  envState: EnvState
  profileState: EnvState
  gatewayIp: string
  deviceIp: string
  dcmIp: string
  gatewayIpPingStatus: "" | "success" | "fail"
  deviceIpPingStatus: "" | "success" | "fail"
  dcmIpPingStatus: "" | "success" | "fail"
  //Network status for selected project
  generateEnabled: boolean
  projectIdSelected: string
  projectEnvState: EnvState
  projectProfileState: EnvState
  networkDeleteOpen: boolean
}

export const initialNetworkSetupState: NetworkSetupState = {
  deleteOpen: false,
  clientVpnProfile: "",
  cidr: DEFAULT_CIDR,
  region: DEFAULT_REGION,
  envState: "",
  profileState: "",
  gatewayIp: "",
  deviceIp: "",
  dcmIp: "",
  gatewayIpPingStatus: "",
  deviceIpPingStatus: "",
  dcmIpPingStatus: "",
  //Network status for selected project
  generateEnabled: true,
  projectIdSelected: "",
  projectEnvState: "",
  projectProfileState: "",
  networkDeleteOpen: false,
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const networkSetupActions = {
  //Sync actions
  clear: createAction("@networkSetup/clear")(),
  toggleDelete: createAction(
    "@networkSetup/toggleDelete",
    (deleteOpen: boolean) => deleteOpen
  )(),
  handleChange: createAction(
    "@networkSetup/handleChange",
    (field: keyof NetworkSetupState, value: ValueOf<NetworkSetupState>) => ({
      field,
      value,
    })
  )(),
  toggleNeworkDelete: createAction(
    "@networkSetup/toggleNetworkDelete",
    (networkDeleteOpen: boolean) => networkDeleteOpen
  )(),
  setGenerateEnabled: createAction(
    "@networkSetup/setGenerateEnabled",
    (generateEnabled: boolean) => generateEnabled
  )(),
}
export const networkSetupActionsAsync = {
  //Async actions
  getProfile: createAsyncAction(
    "@networkSetup/getProfile/req",
    "@networkSetup/getProfile/res",
    "@networkSetup/getProfile/err"
  )<{ projectId: string }, { clientVpnProfile: string }, { error: Error }>(),
  getIp: createAsyncAction(
    "@networkSetup/getIp/req",
    "@networkSetup/getIp/res",
    "@networkSetup/getIp/err"
  )<
    { projectId: string },
    {
      dcmIp: string
      project: IProject
      deployments: IDeployment[]
    },
    { error: Error }
  >(),
  generate: createAsyncAction(
    "@networkSetup/generate/req",
    "@networkSetup/generate/res",
    "@networkSetup/generate/err"
  )<{ projectId: string }, {}, { error: Error }>(),
  destroy: createAsyncAction(
    "@networkSetup/destroy/req",
    "@networkSetup/destroy/res",
    "@networkSetup/destroy/err"
  )<{ projectId: string }, {}, { error: Error }>(),
  verifyGateway: createAsyncAction(
    "@networkSetup/verifyGateway/req",
    "@networkSetup/verifyGateway/res",
    "@networkSetup/verifyGateway/err"
  )<{ projectId: string }, string, { error: Error }>(),
  verifyDevice: createAsyncAction(
    "@networkSetup/verifyDevice/req",
    "@networkSetup/verifyDevice/res",
    "@networkSetup/verifyDevice/err"
  )<{ projectId: string }, string, { error: Error }>(),
  pollStatus: createAsyncAction(
    "@networkSetup/pollStatus/req",
    "@networkSetup/pollStatus/res",
    "@networkSetup/pollStatus/err"
  )<
    { projectId: string },
    { profileState: EnvState; envState: EnvState; projectId: string },
    { error: Error }
  >(),
}
type ValueOf<T> = T[keyof T]
export type NetworkSetupAction =
  | ReturnType<ValueOf<typeof networkSetupActions>>
  | ReturnType<ValueOf<ValueOf<typeof networkSetupActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */

export const networkSetupReducerV2 = produce(
  (draft: Draft<NetworkSetupState>, action: NetworkSetupAction) => {
    switch (action.type) {
      case getType(networkSetupActions.clear):
        //Reset everything
        draft.clientVpnProfile = ""
        draft.cidr = DEFAULT_CIDR
        draft.region = DEFAULT_REGION
        draft.envState = ""
        draft.profileState = ""
        draft.gatewayIp = ""
        draft.deviceIp = ""
        draft.dcmIp = ""
        draft.gatewayIpPingStatus = ""
        draft.deviceIpPingStatus = ""
        draft.dcmIpPingStatus = ""
        //
        draft.generateEnabled = true
        draft.projectIdSelected = "" // not used so far
        draft.projectEnvState = ""
        draft.projectProfileState = ""
        draft.networkDeleteOpen = false
        return
      case getType(networkSetupActions.toggleDelete):
        draft.deleteOpen = action.payload
        return
      case getType(networkSetupActions.handleChange):
        {
          const { field, value } = action.payload
          draft[field as string] = value as any
        }
        return
      case getType(networkSetupActions.toggleNeworkDelete):
        draft.networkDeleteOpen = action.payload
        return
      case getType(networkSetupActions.setGenerateEnabled):
        draft.generateEnabled = action.payload
        return
      case getType(networkSetupActionsAsync.getProfile.success):
        draft.clientVpnProfile = action.payload.clientVpnProfile
        return
      case getType(networkSetupActionsAsync.getIp.success):
        draft.dcmIp = action.payload.dcmIp
        return
      case getType(networkSetupActionsAsync.destroy.success):
        draft.networkDeleteOpen = false
        return
      case getType(networkSetupActionsAsync.verifyGateway.success):
        draft.gatewayIpPingStatus = action.payload ? "success" : "fail"
        return
      case getType(networkSetupActionsAsync.verifyDevice.success):
        draft.deviceIpPingStatus = action.payload ? "success" : "fail"
        return
      case getType(networkSetupActionsAsync.pollStatus.request):
        {
          const projectId = action.payload.projectId
          //On projectId switch, clear stored states
          if (projectId !== draft.projectIdSelected) {
            draft.projectEnvState = ""
            draft.projectProfileState = ""
          }
        }
        return
      case getType(networkSetupActionsAsync.pollStatus.success):
        draft.projectIdSelected = action.payload.projectId
        draft.projectEnvState = action.payload.envState
        draft.projectProfileState = action.payload.profileState
        return
    }
  },
  initialNetworkSetupState
)

/**
 * ==============================================================
 * EPICS - move this elsewhere
 * ==============================================================
 */
export const networkPollStatusEpicV2: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(networkSetupActionsAsync.pollStatus.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function pollStatus() {
          //1.Get info from state and action
          const projectId = action.payload.projectId
          const client = state.auth0.auth0Client
          if (!client) return
          const token = await client.getTokenSilently()

          const { apiGatewayUrl, mgmtEnv, vpnSetupPath } = state.constants

          //2.Call status API
          const res = await dependencies.networkAPIV2.getStatus({
            client_name: projectId,
            environment: mgmtEnv,
            token,
            mgmtUrl: apiGatewayUrl,
            vpnSetupPath,
          })
          const profileState = (res.client_profile_status || "") as EnvState
          const envState = (res.vpn_setup_status || "") as EnvState

          //3.Pass new values to reducer
          observer.next(
            networkSetupActionsAsync.pollStatus.success({
              profileState,
              envState,
              projectId,
            })
          )
        }
        pollStatus().catch(error =>
          observer.next(networkSetupActionsAsync.pollStatus.failure({ error }))
        )
      })
    })
  )
}

export const networkGetVpnProfileEpicV2: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(networkSetupActionsAsync.getProfile.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function getProfile() {
          //1.Get info from state and action
          const projectId = action.payload.projectId
          const client = state.auth0.auth0Client
          if (!client) return
          const token = await client.getTokenSilently()

          const { apiGatewayUrl, mgmtEnv, vpnSetupPath } = state.constants

          //2.Call generate API
          const res = await dependencies.networkAPIV2.getProfile({
            client_name: projectId,
            environment: mgmtEnv,
            token,
            mgmtUrl: apiGatewayUrl,
            vpnSetupPath,
          })

          //3.Download file
          const filename = "client_profile.ovpn"
          const blob = new Blob([res], {
            type: "text/plain;charset=utf-8",
          })
          saveAs(blob, filename)

          //4.Pass new values to reducer
          observer.next(
            networkSetupActionsAsync.getProfile.success({
              clientVpnProfile: res,
            })
          )
        }
        getProfile().catch(error =>
          observer.next(networkSetupActionsAsync.getProfile.failure({ error }))
        )
      })
    })
  )
}

export const networkVpnIpEpicV2: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(networkSetupActionsAsync.getIp.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function getIp() {
          //1.Get info from state and action
          const projectId = action.payload.projectId
          const client = state.auth0.auth0Client
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          const { apiGatewayUrl, mgmtEnv, vpnSetupPath } = state.constants

          if (!client) return
          const token = await client.getTokenSilently()

          //2.Call generate API
          const res = await dependencies.networkAPIV2.getIpAddress({
            client_name: projectId,
            environment: mgmtEnv,
            token,
            mgmtUrl: apiGatewayUrl,
            vpnSetupPath,
          })
          const ip = (res && res.ip) || ""

          //3.Edit projects and deployments
          const {
            project,
            deployments,
          } = await dependencies.networkAPIV2.storeRemoteHost({
            token,
            apiGatewayUrl,
            organisationId,
            projectId,
            remoteHostIp: ip,
            remoteHostName: DEFAULT_HOST_NAME,
          })

          //4.Pass new values to reducer
          observer.next(
            networkSetupActionsAsync.getIp.success({
              dcmIp: ip,
              project,
              deployments,
            })
          )
        }
        getIp().catch(error =>
          observer.next(networkSetupActionsAsync.getIp.failure({ error }))
        )
      })
    })
  )
}

export const networkSetupGenerateEpicV2: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(networkSetupActionsAsync.generate.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function generate() {
          //1.Get info from state
          const projectId = action.payload.projectId
          const project = state.tenant.projects[projectId]
          const deviceCount = project
            ? `${project.deviceCount || DEFAULT_DEVICE_COUNT}`
            : `${DEFAULT_DEVICE_COUNT}`
          const cidr = state.network.cidr
          const region = state.network.region || state.constants.region
          const client = state.auth0.auth0Client
          if (!client) return
          const token = await client.getTokenSilently()
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          const { apiGatewayUrl, mgmtEnv } = state.constants

          //#0f0 Temp fix for europe region:
          const environment = region === "eu-west-3" ? "prod" : mgmtEnv

          //2.Call generate API
          const res = await dependencies.networkAPIV2.generateServer({
            client_name: projectId,
            environment,
            client_cidr: cidr,
            aws_region: region,
            no_of_cameras: deviceCount,
            apiGatewayUrl,
            organisationId,
            token,
          })

          //3.Pass new values to reducer
          observer.next(networkSetupActionsAsync.generate.success({}))
        }
        generate().catch(error =>
          observer.next(networkSetupActionsAsync.generate.failure({ error }))
        )
      })
    })
  )
}

export const networkVerifyGatewayEpicV2: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(networkSetupActionsAsync.verifyGateway.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function ping() {
          //1.Get info from state or action
          const projectId = action.payload.projectId || ""
          const gatewayIp = state.network.gatewayIp
          const client = state.auth0.auth0Client
          if (!client) return
          const token = await client.getTokenSilently()

          const { apiGatewayUrl, mgmtEnv, vpnSetupPath } = state.constants

          //2.Call ping API
          const g = await dependencies.networkAPIV2.pingClient({
            client_name: projectId,
            environment: mgmtEnv,
            client_ip_address: gatewayIp,
            token,
            mgmtUrl: apiGatewayUrl,
            vpnSetupPath,
          })

          //3.Pass new values to reducer
          observer.next(networkSetupActionsAsync.verifyGateway.success(g))
        }
        ping().catch(error =>
          observer.next(
            networkSetupActionsAsync.verifyGateway.failure({ error })
          )
        )
      })
    })
  )
}

export const networkVerifyDeviceEpicV2: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(networkSetupActionsAsync.verifyDevice.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function ping() {
          //1.Get info from state or action
          const projectId = action.payload.projectId
          const deviceIp = state.network.deviceIp
          const client = state.auth0.auth0Client
          if (!client) return
          const token = await client.getTokenSilently()

          const { apiGatewayUrl, mgmtEnv, vpnSetupPath } = state.constants

          //2.Call ping API
          const d = await dependencies.networkAPIV2.pingClient({
            client_name: projectId,
            environment: mgmtEnv,
            client_ip_address: deviceIp,
            token,
            mgmtUrl: apiGatewayUrl,
            vpnSetupPath,
          })

          //3.Pass new values to reducer
          observer.next(networkSetupActionsAsync.verifyDevice.success(d))
        }
        ping().catch(error =>
          observer.next(
            networkSetupActionsAsync.verifyDevice.failure({ error })
          )
        )
      })
    })
  )
}

export const networkSetupDestroyEpicV2: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(networkSetupActionsAsync.destroy.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function destroy() {
          //1.Get info from state and action
          const projectId = action.payload.projectId
          const client = state.auth0.auth0Client
          if (!client) return
          const token = await client.getTokenSilently()
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          const { apiGatewayUrl, mgmtEnv } = state.constants

          //2.Call generate API
          const res = await dependencies.networkAPIV2.destroyServer({
            client_name: projectId,
            environment: mgmtEnv,
            token,
            apiGatewayUrl,
            organisationId,
          })

          //3.Pass new values to reducer
          observer.next(networkSetupActionsAsync.destroy.success({}))
        }
        destroy().catch(error =>
          observer.next(networkSetupActionsAsync.destroy.failure({ error }))
        )
      })
    })
  )
}

/**
 * ==============================================================
 * SELECTORS - maybe move this elsewhere too
 * ==============================================================
 */
