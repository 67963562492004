import { rootReducer } from "../reducers";
import {
    PreloadedState,
    Middleware,
    configureStore
} from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import { createLogger } from "redux-logger";
import log from "loglevel";

import { DEBUG } from "../../constants";
import { dependencies } from "../apis";
import { rootEpic } from "../reducers";

// Middlewares
const epicMiddleware = createEpicMiddleware({ dependencies });
const logger = createLogger({
    logger: log,
});
const middlewares = [
    epicMiddleware,
    DEBUG && logger
].filter(Boolean) as Middleware[];

// Function to setup store with middlewares
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: middlewares,
        ...(preloadedState ? { preloadedState } : {})
    });

    epicMiddleware.run(rootEpic);

    return store;
} 

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;