import { ICalibration } from "../../../../@types/ICalibration"
import { IDeployment } from "../../../../@types/IDeployment"
import { CalibrationValues } from "../@types"

export const getCalibrationsByZoneId = (
  deployments: { [id: string]: IDeployment },
  calibrations: CalibrationValues<ICalibration>,
  zoneId: string
) => {
  //1. Grab deployment ids
  const ids = Object.values(deployments)
    .filter(d => d.zoneId === zoneId)
    .map(d => d.deploymentId)
  //2. Get calibrations by deployment
  return Object.values(calibrations).filter(calibration =>
    ids.includes(calibration.deploymentId)
  )
}
